<template>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :title="$t('suggestionsActions.addToSuggestion.title')"
    :modal-props="{centered: false, hideFooter: true, lazy: false, size: 'w-full max-w-[60vw] flex', contentClass: 'max-h-full w-full', contentId: 'suggestionModal'}"
    :drawer-props="{lazy: false, fullHeight: true, contentClass: 'max-h-full', hideFooter: true}"
  >
    <suggestions-management v-if="isInitialLoad" />
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import SuggestionsManagement
  from '~/modules/building/components/actions/suggestions-management/SuggestionsManagement.vue'
import { watchOnce } from '@vueuse/core'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const isInitialLoad = ref(false)

const isModalVisible = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  },
})

watchOnce(isModalVisible, () => {
  if (isModalVisible.value) {
    isInitialLoad.value = true
  }
})

</script>

<template>
  <div>
    <slot
      name="market-entities-filter"
    />
    <div
      v-if="width < 768"
      class="mt-7 "
    >
      <slot name="search-count" />
      <div class="mt-7 flex justify-between max-h-[38px]">
        <slot name="select-entity" />
        <slot name="show-map-button" />
        <slot name="market-entities-sort" />
      </div>
    </div>
    <div
      v-if="width >= 768 && width < 1280"
      class="mt-7 "
    >
      <slot name="search-count" />
      <div class="mt-7 flex justify-between max-h-[38px]">
        <slot name="select-entity" />
        <div class="flex gap-x-3">
          <slot name="show-map-button" />
          <slot name="market-entities-sort" />
        </div>
      </div>
    </div>
    <div
      v-if="width >= 1280"
      class="mt-7 flex justify-between max-h-[38px]"
    >
      <slot name="search-count" />
      <slot name="select-entity" />
      <div class="flex gap-x-3">
        <slot name="show-map-button" />
        <slot name="market-entities-sort" />
      </div>
    </div>
    <div
      v-if="!isMd"
      class="mt-1"
    >
      <slot
        v-if="isPaginationShow"
        name="market-entities-pagination"
      />
    </div>
    <div class="mt-7 max-w-[calc(100vw-2rem)]">
      <slot name="entities" />
    </div>
    <slot
      v-if="isPaginationShow"
      name="market-entities-pagination"
    />
  </div>
</template>
<script setup lang="ts">
import { useAppStateStore } from '~/store/app'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { useWindowSize } from '@vueuse/core'

const store = useMarketEntitiesActionsStore()
const appStore = useAppStateStore()

const isPaginationShow = computed(() => (store.entitiesCount && store.entitiesCount > store.pagination.perPage) || (store.pagination.total && store.pagination.total > store.pagination.perPage))

const isMd = computed(() => appStore.breakpoints.isMd)

const { width } = useWindowSize()
</script>

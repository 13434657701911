<template>
  <div
    class="relative"
    v-bind="{...$attrs}"
  >
    <custom-carousel
      v-model="imageIndex"
      :images="imagesForCarousel"
      :carousel-class="carouselClass"
      :max-height="maxHeight || '255px'"
      :min-height="minHeight || '255px'"
      :default-image="defaultImage"
      :bottom-rounded="bottomRounded"
      :first-show-image-index="firstShowImageIndex"
      :preferred-video-index="preferredVideoIndex"
      :size="size"
      :controls="controls"
      :is-full-screen="isFullScreen"
      :is-open-modal-on-click="isOpenModalOnClick"
      @on-click-by-slide="$emit('onClickBySlide', $event)"
    >
      <template
        v-if="viewActionsVariant === 'inModal' && imagesForCarousel.length"
        #modalInner
      >
        <building-carousel-view-actions
          v-model="imageIndex"
          :images="imagesForCarousel"
          :first-show-image-index="firstShowImageIndex"
          :preferred-video-index="preferredVideoIndex"
        />
      </template>
    </custom-carousel>
    <div
      v-if="!(videoYoutubeId && imageIndex === 1)"
    >
      <building-carousel-actions-buttons
        :building="building"
        :is-building-has-decorations="isBuildingHasDecorations"
        :video-url="videoUrl"
        :general-plan-index="generalPlanIndex"
        :is-display-on-map-show="isDisplayOnMapShow"
        :images-length="imagesForCarousel.length"
        :image-index="imageIndex"
        :is-grid-item="isGridItem"
        @set-index="setIndex"
      />
      <promotions-labels
        :promotions="building.promotions"
        container-class="gap-[6px] pl-[3%] bottom-[50px]"
        :count-to-group="2"
        :building-id="building.id"
      />
    </div>
  </div>
  <building-carousel-view-actions
    v-if="viewActionsVariant === 'default' && imagesForCarousel.length"
    v-model="imageIndex"
    :images="imagesForCarousel"
    :first-show-image-index="firstShowImageIndex"
    :preferred-video-index="preferredVideoIndex"
  />
</template>

<script setup lang="ts">

import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import { BuildingActionsService } from '~/modules/building/services/BuildingActionsService'
import { BuildingImage } from '~/modules/building/types/Building.image'
import defaultImage from '~/assets/images/building/building-default-image.png'
import { Image } from '~/common/types/image/Image'
import { youtubeLinkParser } from '~/common/helpers/parser'
import BuildingCarouselActionsButtons from '~/modules/building/components/carousel/BuildingCarouselActionsButtons.vue'
import BuildingCarouselViewActions from '~/modules/building/components/carousel/BuildingCarouselViewActions.vue'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import { Size } from '~/ui/types/types'
import PromotionsLabels from '~/modules/widget/components/PromotionsLabels.vue'

const props = defineProps({
  images: {
    type: Array as PropType<Array<BuildingImage>>,
    default: () => ([] as Array<BuildingImage>),
  },
  videoUrl: {
    type: String,
    default: '',
  },
  building: {
    type: Object as PropType<BuildingExpanded>,
    required: true,
  },
  isBuildingHasDecorations: {
    type: Boolean,
    default: false,
  },
  bottomRounded: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String as PropType<Size>,
    default: 'sm',
  },
  minHeight: {
    type: String,
    default: null,
  },
  maxHeight: {
    type: String,
    default: null,
  },
  carouselClass: {
    type: [String, Object, Array],
    default: '',
  },
  viewActionsVariant: {
    type: String as PropType<'default' | 'inModal'>,
    default: 'default',
  },
  controls: {
    type: Boolean,
    default: true,
  },
  isDisplayOnMapShow: {
    type: Boolean,
    default: true,
  },
  isFullScreen: {
    type: Boolean,
    default: false,
  },
  isOpenModalOnClick: {
    type: Boolean,
    default: true,
  },
  isGridItem: {
    type: Boolean,
    default: true,
  },
})
const emits = defineEmits<({
  (e: 'onClickBySlide', index: number): void
})>()

const preferredVideoIndex = 1

export type CarouselIndex = 'generalPlan' | 'videoReview'

const imageIndex = ref(0)

const videoYoutubeId = computed(() => youtubeLinkParser(props.videoUrl))
const imagesForCarousel = computed(() => {
  const images: Image[] = props.images.map(image => ({
    link: image.link,
    alt: image.name,
    default: image.default,
  }))

  if (videoYoutubeId.value) {
    images.splice(1, 0, { link: `https://img.youtube.com/vi/${videoYoutubeId.value}/hqdefault.jpg`, alt: 'video', video: `https://www.youtube.com/embed/${videoYoutubeId.value}` })
  }

  return images
})
const firstShowImageIndex = computed(() => imagesForCarousel.value.findIndex(image => image.default) || 0)

const generalPlanIndex = computed(() => {
  const index = BuildingActionsService.getGeneralPlanImageIndex(props.images as Array<BuildingImage>, true)
  if (index > preferredVideoIndex - 1 && videoYoutubeId.value) {
    return index + 1
  }
  return index
})

const setIndex = (key: CarouselIndex) => {
  if (key === 'generalPlan') {
    imageIndex.value = generalPlanIndex.value
  }
  if (key === 'videoReview') {
    imageIndex.value = 1
  }
}
</script>

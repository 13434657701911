<template>
  <div
    class="absolute bottom-[10px] flex justify-start gap-[6px] w-full pl-[3%]"
    v-bind="{...$attrs}"
  >
    <building-carousel-btn-general-plan
      v-if="generalPlanIndex !== -1"
      @set-index="emits('set-index', 'generalPlan')"
    />
    <building-carousel-btn-decoration
      :building="isBuildingHasDecorations ? building : null"
      :building-id="building.id"
    />
    <building-carousel-btn-panorama
      :is-grid-item="isGridItem"
      :building="building"
    />
  </div>
  <div
    class="absolute top-5 pr-10 left-5 flex items-center justify-between w-full gap-x-3"
  >
    <div class="flex gap-x-3">
      <div
        v-if="imagesLength"
        class="px-3 h-[30px] rounded-full text-white font-semibold text-sm bg-black/30 flex items-center justify-center backdrop-blur-lg"
      >
        {{ imageIndex+1 }}/{{ imagesLength }}
      </div>
      <building-carousel-btn-mortgage
        v-if="building.mortgages?.length"
        :building="building"
      />
    </div>
    <div class="flex gap-x-3">
      <building-carousel-btn-display-on-map
        v-if="isDisplayOnMapShow"
        :building="building"
      />
      <building-carousel-btn-motivation
        v-if="isDisplayAgentMotivation"
        :building="building"
      />
      <building-carousel-btn-video-review
        v-if="isYoutubeVideoReview"
        @set-index="emits('set-index', 'videoReview')"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import BuildingCarouselBtnPanorama from '~/modules/building/components/carousel/BuildingCarouselBtnPanorama.vue'
import BuildingCarouselBtnDecoration from '~/modules/building/components/carousel/BuildingCarouselBtnDecoration.vue'
import BuildingCarouselBtnGeneralPlan from '~/modules/building/components/carousel/BuildingCarouselBtnGeneralPlan.vue'
import { CarouselIndex } from '~/modules/building/components/carousel/BuildingCarousel.vue'
import { youtubeLinkParser } from '~/common/helpers/parser'
import BuildingCarouselBtnMotivation from '~/modules/building/components/carousel/BuildingCarouselBtnMotivation.vue'
import { BuildingExpanded } from '~/modules/building/types/Building.expanded'
import BuildingCarouselBtnVideoReview from '~/modules/building/components/carousel/BuildingCarouselBtnVideoReview.vue'
import BuildingCarouselBtnDisplayOnMap from '~/modules/building/components/carousel/BuildingCarouselBtnDisplayOnMap.vue'
import BuildingCarouselBtnMortgage from '~/modules/building/components/carousel/BuildingCarouselBtnMortgage.vue'
import DISPLAYING_TYPE from '~/modules/building/constants/Building.displayingType'
import { ComputedRef } from 'vue'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  building: {
    type: Object as PropType<BuildingExpanded>,
    required: true,
  },
  isBuildingHasDecorations: {
    type: Boolean,
    default: false,
  },
  generalPlanIndex: {
    type: Number,
    required: true,
  },
  videoUrl: {
    type: String,
    default: '',
  },
  isDisplayOnMapShow: {
    type: Boolean,
    default: true,
  },
  imagesLength: {
    type: Number,
    default: 0,
  },
  imageIndex: {
    type: Number,
    default: 0,
  },
  isGridItem: {
    type: Boolean,
    default: true,
  },
})

const appStateStore = useAppStateStore()

const emits = defineEmits<{(e: 'set-index', value: CarouselIndex): void}>()

const displayingType: ComputedRef<DISPLAYING_TYPE> = inject('displayingType', DISPLAYING_TYPE.guest)
const isYoutubeVideoReview = computed(() => youtubeLinkParser(props.videoUrl))
const isDisplayAgentMotivation = computed(() => displayingType.value === DISPLAYING_TYPE.basic && !appStateStore.clientMode)
</script>

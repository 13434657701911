<template>
  <form
    class="flex flex-col"
    @submit="createSuggestion"
  >
    <div class="flex max-md:flex-col max-md:gap-y-4 gap-x-2 items-stretch">
      <custom-input
        v-model="suggestionName"
        input-class="h-full"
        :invalid-feedback="!isMd ? errorMessage : ''"
        :state="errorMessage ? false : null"
        :placeholder="$t('suggestionsActions.create.placeholder')"
        :disabled="appStore.isFetchingApp"
        class="grow"
      />
      <custom-button-with-spinner
        class="font-semibold flex items-center"
        type="submit"
        :disabled="appStore.isFetching"
        :display-spinner="appStore.isFetching"
      >
        <template #prefix>
          <nuxt-icon
            name="common/circle-plus-regular"
            class="flex"
          />
        </template>
        <span class="ml-2">
          {{ $t('suggestionsActions.create.title.full') }}
        </span>
      </custom-button-with-spinner>
    </div>
    <p
      v-if="isMd && errorMessage"
      class="mt-2 text-sm"
    >
      <span class="text-danger">{{ errorMessage }}</span>
    </p>
  </form>
</template>
<script setup lang="ts">
import { useField, useForm } from 'vee-validate'
import CustomInput from '~/ui/inputs/CustomInput.vue'
import CustomButtonWithSpinner from '~/ui/buttons/CustomButtonWithSpinner.vue'
import { type ManagingSuggestion, SuggestionsService } from '~/modules/suggestions/SuggestionsModule'
import { useUserStore } from '~/modules/user/UserModule'
import { useAppStateStore } from '~/store/app'

const emits = defineEmits<{(e: 'create', value: ManagingSuggestion): void}>()

const service = new SuggestionsService()
const userStore = useUserStore()
const appStore = useAppStateStore()
const { handleSubmit } = useForm()
const { t } = useI18n()

const isMd = computed(() => appStore.breakpoints.isMd)

const { value: suggestionName, errorMessage } = useField<string>('name', { required: true, min: 1 /* max: 255 */ }, {
  label: t('suggestionsActions.table.suggestionTitle'),
})

const createSuggestion = handleSubmit(() => {
  service.createSuggestion({ title: suggestionName.value, manager: { id: userStore.user.id } })
    .then(suggestion => {
      toast(t('suggestionsActions.create.toast.success'), { variant: 'success' })
      emits('create', suggestion)
    })
})

</script>

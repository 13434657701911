<template>
  <flats-layout
    :layouts="combinedLayouts"
    wrapper-class="md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
  >
    <template #actions="{openModal, building}">
      <flats-btns-basic
        :developer="building?.developer"
        :with-add-button-default-click="false"
        is-layout
        @add-to-suggestion="openModal"
      />
    </template>
  </flats-layout>
</template>

<script setup lang="ts">
import FlatsLayout from '~/components/flats/layout/FlatsLayout.vue'
import FlatsBtnsBasic from '~/components/flats/buttons/FlatsBtnsBasic.vue'
import { BuildingPageFlatsCombine } from '~/modules/building/services/BuildingPageFlatsCombine'
import { Layout } from '~/common/types/layout/Layout'
import { Flat } from '~/common/types/flat/Flat'
import { getEntityName } from '~/common/helpers/getEntityName'

const props = defineProps({
  layouts: {
    type: Array as PropType<Array<Layout>>,
    required: true,
  },
})

const buildingPageFlatsCombine = new BuildingPageFlatsCombine()

const combinedLayouts = computed(() => props.layouts.map(layout => {
  const onlyLayout = {
    code: layout.code,
    id: layout.id,
    images: layout.images,
    name: getEntityName({
      name: layout.name,
      internationalName: layout.internationalName,
      entityCountry: layout.building.address?.countryIsoCode,
    }),
  }

  const flats = layout.flats.map(flat => ({
    ...flat,
    layout: onlyLayout,
    building: layout.building,
    section: layout.house?.sections?.[0],
    address: layout.house?.construction?.address,
  }))
  return buildingPageFlatsCombine.flatsToLayouts(flats as Flat[], layout.house.decorations)
}).flat(1))
</script>

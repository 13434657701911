<template>
  <template v-if="loading">
    <suggestion-skeleton />
  </template>
  <template v-else>
    <div>
      <template v-if="suggestion?.buildings.length">
        <div class="flex justify-between max-md:gap-x-4 mb-6 bg-white max-md:px-4">
          <div class="flex gap-x-4">
            <flats-btns-display-table-grid-or-layouts
              v-model="flatsDisplayingEntity"
              :with-layouts="false"
            />
            <custom-button
              class=" max-md:w-full font-semibold"
              pill
              @click="currentBuilding = null"
            >
              {{ $t('common.showAll') }}
            </custom-button>
          </div>
          <div class="flex gap-x-2">
            <slot
              name="actions"
              :suggestion="suggestion"
            />
          </div>
        </div>
        <div class="max-md:bg-neutral-100 max-md:px-4 max-md:pt-4">
          <building-list
            v-model="currentBuilding"
            :buildings="suggestion.buildings"
            class="mb-3"
          >
            <template #actions="{building}">
              <custom-button-icon
                color="transparent"
                class="absolute right-[10px] top-[10px] z-[9]"
                icon="actions/trash"
                icon-class="text-[20px] text-white"
                @click="removeBuilding(building)"
              />
            </template>
          </building-list>
          <component
            :is="flatsListOrTable"
            :flats="flats"
            :display-type="DISPLAY_TYPE.suggestionManagement"
            :mini="mini"
            class="h-[60vh] bg-white rounded-t-lg"
            is-x-overflow
            wrapper-class="md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
            for-page="suggestion"
          >
            <template #actions="{flat}">
              <flats-btns-suggestion-management
                :flat="flat"
                :view="flatsDisplayingEntity"
                :button-class="flatsDisplayingEntity === FLATS_DISPLAYING_ENTITY.table ? 'bg-white' : ''"
                @remove="removeFlat(flat)"
              />
            </template>
            <template
              #building="{building}"
            >
              <flat-building
                :building="building"
              />
            </template>
            <template #emptyMessage>
              {{ currentBuilding ? $t('suggestions.content.buildingEmpty') : $t('suggestions.content.suggestionEmpty') }}
            </template>
          </component>
        </div>
      </template>
      <template v-else>
        {{ $t('suggestionsActions.view.empty') }}
      </template>
    </div>
  </template>
</template>
<script setup lang="ts">
import BuildingList from '~/modules/suggestions/components/page/buildings-list/BuildingList.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import FlatsList from '~/components/flats/FlatsList.vue'
import FlatBuilding from '~/components/flats/flat-list-card/FlatBuilding.vue'
import SuggestionSkeleton from '~/components/common/skeletons/SuggestionSkeleton.vue'
import { type ManagingSuggestion } from '~/modules/suggestions/SuggestionsModule'
import type { Flat } from '~/common/types/flat/Flat'
import { type ChessboardFlat } from '~/modules/chessboard/types/Chessboard.flat'
import { BuildingActionsService } from '~/modules/building/BuildingModule'
import { type SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { FLATS_DISPLAYING_ENTITY } from '~/modules/building/components/page/flats/filter/constants/FlatsDisplaying.entity'
import FlatsTableView from '~/components/flats/table/FlatsTableView.vue'
import { FlatsDisplayingEntity } from '~/modules/building/components/page/flats/filter/types/FlatsDisplaying.entity'
import FlatsBtnsDisplayTableGridOrLayouts from '~/components/flats/buttons/FlatsBtnsDisplayTableGridOrLayouts.vue'
import { useAppStateStore } from '~/store/app'
import { useSuggestionsStore } from '~/modules/suggestions/store'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import FlatsBtnsSuggestionManagement from '~/components/flats/buttons/FlatsBtnsSuggestionManagement.vue'

const props = defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion | null>,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'remove:building', value: SuggestionBuilding): void,
  (e: 'remove:flat', value: ChessboardFlat): void}>()

const store = useSuggestionsStore()

const flatsDisplayingEntity = ref<FlatsDisplayingEntity>(FLATS_DISPLAYING_ENTITY.table)
const currentBuilding = ref(props.suggestion?.buildings[0] ?? null)

const removeBuilding = (building: SuggestionBuilding) => {
  emits('remove:building', building)
}
const removeFlat = (flat: Flat) => {
  emits('remove:flat', flat)
}

const flats = computed<Array<Flat>>(() => {
  if (currentBuilding.value === null) {
    return props.suggestion?.buildings
      .reduce((flatList, building) => [...flatList, ...BuildingActionsService.getBuildingFlats(building).map(flat => ({ ...flat, building }))], [])
  }
  return BuildingActionsService.getBuildingFlats(currentBuilding.value as ManagingSuggestion['buildings'][number])
    .map(flat => ({ ...flat, building: currentBuilding.value }))
})
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const mini = computed(() => {
  if (isMd.value) {
    return null
  }
  return flatsDisplayingEntity.value === FLATS_DISPLAYING_ENTITY.table
})
const flatsListOrTable = computed(() => {
  if (isMd.value) {
    switch (flatsDisplayingEntity.value) {
      case FLATS_DISPLAYING_ENTITY.grid:
        return FlatsList
      case FLATS_DISPLAYING_ENTITY.table:
        return FlatsTableView
      default:
        return FlatsList
    }
  }
  return FlatsList
})

watchEffect(() => {
  if (!props.suggestion?.buildings.includes(currentBuilding.value as ManagingSuggestion['buildings'][number])) {
    currentBuilding.value = null
  }
})

watch(() => props.suggestion, () => {
  store.setSuggestionId(props.suggestion?.uniqueId || '')
})
</script>

<template>
  <custom-dropdown
    color="info"
    button-class="!rounded-[19px] h-full items-center"
  >
    <template #triggerContent>
      <client-only>
        <font-awesome-icon
          :icon="selectedSortOption.sortGroupIcon"
          class="mr-[6px]"
        />
        <font-awesome-icon
          :icon="selectedSortOption.orderIcon"
        />
      </client-only>
      <span class="font-semibold ml-[10px] hidden sm:inline">
        {{ selectedSortOption.sortGroupLabel }}
      </span>
    </template>
    <custom-list-group
      v-for="option in MARKET_ENTITIES_SORT_OPTIONS"
      :key="option.sortGroupLabel"
      without-borders
      class="w-full"
    >
      <h5 class="text-primary text-base font-medium">
        <client-only>
          <font-awesome-icon :icon="option.sortGroupIcon" />
        </client-only>
        {{ option.sortGroupLabel }}
      </h5>

      <custom-list-group-item
        v-for="order in option.options"
        :key="order"
        class="text-primary"
        :active="store.sort === order.value"
        without-borders
        @click="setSortOption(order.value)"
      >
        <template #prefix>
          <client-only>
            <font-awesome-icon :icon="order.icon" />
          </client-only>
        </template>
        <span class="text-base">
          {{ order.label }}
        </span>
      </custom-list-group-item>
    </custom-list-group>
  </custom-dropdown>
</template>

<script setup lang="ts">

import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'
import CustomListGroup from '~/ui/list-group/CustomListGroup.vue'
import CustomListGroupItem from '~/ui/list-group/CustomListGroupItem.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { MARKET_ENTITIES_SORT_OPTIONS } from '~/modules/market-entities-actions/constants/sort/Sort.options'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { MarketEntitiesSort } from '~/modules/market-entities-actions/types/sort/Sort'

type SelectedSortOption = {
  sortGroupIcon: string
  sortGroupLabel: string
  orderIcon: string
}

const emits = defineEmits(['onChangeSortOption'])

const store = useMarketEntitiesActionsStore()

const selectedSortOption: ComputedRef<SelectedSortOption> = computed(() => {
  const selectedOption: SelectedSortOption = {
    sortGroupIcon: '',
    sortGroupLabel: '',
    orderIcon: '',
  }

  MARKET_ENTITIES_SORT_OPTIONS.value.forEach(option => {
    option.options.forEach(order => {
      if (order.value === store.sort) {
        selectedOption.orderIcon = order.icon
        selectedOption.sortGroupLabel = option.sortGroupLabel
        selectedOption.sortGroupIcon = option.sortGroupIcon
      }
    })
  })

  return selectedOption
})
const setSortOption = (value: MarketEntitiesSort): void => {
  store.setSortOption(value)
  emits('onChangeSortOption')
}

</script>

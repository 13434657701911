<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('building.actions.linkToSuggestion.label') }}
    </template>
    <custom-button-icon
      :class="buttonClass"
      color="secondary"
      round
      icon="actions/link"
      icon-class="text-2xl"
      @click="copyLink"
    />
  </custom-tooltip>
</template>
<script setup lang="ts">
import { useClipboard } from '@vueuse/core'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { ManagingSuggestion } from '~/modules/suggestions/types/Suggestion'
import { COLOR } from '~/ui/constants/color'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'
import buildingDefaultImage from 'assets/images/building/building-default-image.png'

const props = defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})
const nuxtApp = useNuxtApp()

const { t } = useI18n()

const suggestionLink = ref(`${nuxtApp.$config.public.estateMarketAppUrl}/suggestions/${props.suggestion?.uniqueId}`)

const { copy } = useClipboard({ source: suggestionLink, legacy: true })

const imageLink = props.suggestion.buildings[0]?.images.find(image => image?.link)?.link || `${process.env?.ESTATE_MARKET_APP_URL}${buildingDefaultImage}`

const copyLink = () => {
  useServerSeoMeta({
    title: () => props.suggestion.title,
    ogTitle: () => props.suggestion.title,
    ogImage: () => imageLink,
    twitterTitle: () => props.suggestion.title,
    twitterImage: () => imageLink,
  })

  copy(suggestionLink.value)
    .then(() => {
      toast(t('suggestionsActions.copyLink.toast.success'), { variant: COLOR.success })
    })
}
</script>

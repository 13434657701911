<template>
  <component
    is="div"
    class="w-full flex flex-col"
  >
    <span
      v-if="label"
      :class="[
        {'form-field__required' : required },
        'block mb-2 inter',
        labelClass || 'text-lg font-medium text-neutral-900'
      ]"
    >
      {{ label }}
    </span>
    <vue-select
      v-model="value"
      :options="options"
      :reduce="(option: OptionType) => option.value === undefined ? option : option.value"
      :clearable="clearable"
      :multiple="multiple"
      :append-to-body="isAppendToBody"
      :get-option-key="option => optionKey ? option[optionKey] : JSON.stringify(option)"
      :calculate-position="withPopper"
      :get-option-label="getLabel"
      class="custom-autocomplete inter"
      :class="{'custom-autocomplete_error': state === false,
               'no-disable-custom-autocomplete': !disabled
      }"
      :disabled="disabled"
      :placeholder="placeholder"
      :filterable="filterable"
      @search="(searchString: string, toggleLoading) => emits('search', searchString, toggleLoading)"
      @search:focus="(searchString: string, toggleLoading) => emits('search:focus', searchString, toggleLoading)"
      @open="emits('open')"
      @close="emits('close')"
    >
      <template #footer>
        <div
          v-if="invalidFeedback"
          class="mt-2 text-sm disable-inter-class"
        >
          {{ invalidFeedback }}
        </div>
      </template>
      <template #no-options="{ search, loading }">
        <slot
          :loading="loading"
          :search="search"
          name="no-options"
        >
          <span v-show="!loading && (search as string).length">{{ $t('common.noOptions') }}</span>
        </slot>
      </template>
      <template #list-footer>
        <slot name="list-footer" />
      </template>
      <template
        v-if="$slots['selected-option-container']"
        #selected-option-container
      >
        <slot name="selected-option-container" />
      </template>
    </vue-select>
  </component>
</template>
<script setup lang="ts">
import VueSelect from 'vue-select'
import { createPopper, VirtualElement } from '@popperjs/core'
import { useAppStateStore } from '~/store/app'

type OptionType = {
  value: string | number | null | undefined
  [key: string]: unknown
}

const props = defineProps({
  modelValue: {
    type: [Array, Object, String, Number] as PropType<string | number | Record<string, unknown> | Array<unknown> | null>,
    default: null,
  },
  options: {
    type: Array as PropType<OptionType[]>,
    default: () => [] as OptionType[],
  },
  valueLabel: {
    type: String,
    default: null,
  },
  optionLabel: {
    type: [String, Function] as PropType<string | ((option: OptionType) => string)>,
    default: null,
  },
  invalidFeedback: {
    type: String,
    default: '',
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: [String, Object, Array] as PropType<string | Record<string, boolean> | string[]>,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  optionKey: {
    type: String,
    default: null,
  },
  filterable: {
    type: Boolean,
    default: true,
  },
})

type Emits = {
  (e: 'update:modelValue', value: unknown): void
  (e: 'search', searchString: string, toggleLoading: (value: boolean) => void): void
  (e: 'search:focus', searchString: string, toggleLoading: (value: boolean) => void): void
  (e: 'open'): void
  (e: 'close'): void
}

const emits = defineEmits<Emits>()

const appStateStore = useAppStateStore()

const value = computed({
  get: () => props.modelValue,
  set: selectedValue => { emits('update:modelValue', selectedValue) },
})

const getLabel = (option: OptionType): string => {
  let label = ''
  if (!props.optionLabel) {
    label = String(option)
  } else if (typeof props.optionLabel === 'function') {
    label = props.optionLabel(option)
  } else {
    label = option[props.optionLabel]
  }
  return label
}

const state = computed(() => (props.invalidFeedback ? false : null))

const baseZIndex = 999

const withPopper = (dropdownList: HTMLUListElement, component: InstanceType<typeof VueSelect>, { width }: { width: string }) => {
  dropdownList.style.width = width
  dropdownList.style.zIndex = `${baseZIndex + appStateStore.openElementsCount.modals * 2 + 2} `
  const popper = createPopper(component.$refs.toggle as Element | VirtualElement, dropdownList, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state: poperState }) {
          component.$el.classList.toggle(
            'drop-up',
            poperState.placement === 'top',
          )
        },
      },
    ],
  })

  return () => popper.destroy()
}

const isAppendToBody = computed(() => {
  const isLg = useBreakpoints().smallerOrEqual('lg').value
  return !useAppStateStore().openElementsCount.modals || !isLg
})
</script>
<style lang="scss">
.custom-autocomplete {
  &.vs--disabled {
    .vs__dropdown-toggle,
    .vs__clear,
    .vs__search,
    .vs__selected,
    .vs__open-indicator {
      @apply bg-white;
    }
    .vs__selected {
      @apply text-neutral-150;
    }
  }
  .vs__dropdown-toggle {
    @apply border-2 border-solid border-neutral-150 focus:border-neutral-950 rounded-lg p-2 #{!important};
  }
  &.vs--open {
    .vs__dropdown-toggle {
      @apply border-neutral-950 #{!important};
    }
  }
  .vs__selected {
    @apply font-medium text-base m-0 p-0 px-2 border-0;

    &-options{
      white-space: nowrap;
      overflow: hidden;
      margin-right: 20px;
    }
  }

  .vs__search {
    @apply font-medium text-base;
    &:focus {
      @apply mt-0 font-medium text-base;
    }
  }
  .vs__search::placeholder {
    @apply opacity-100 text-neutral-500 text-base font-medium;
  }

  .vs__dropdown-option {
    &.vs__dropdown-option--highlight {
      @apply bg-primary;

      &.vs__dropdown-option--selected {
        @apply bg-primary-light text-primary;
      }
    }
  }

  .vs__spinner, .vs__spinner::after {
    width: 20px;
    height: 20px;
  }
  &_error {
    @apply text-danger;
    .vs__dropdown-toggle {
      @apply text-danger border-danger focus:border-danger #{!important};
    }
  }
}

.vs__dropdown-menu {
  pointer-events: auto !important;
}

.no-disable-custom-autocomplete {
  .vs__dropdown-toggle {
    @apply hover:border-neutral-500 #{!important};
  }
  &.custom-autocomplete_error {
    .vs__dropdown-toggle {
      @apply hover:border-danger #{!important};
    }
  }
}

.disable-inter-class{
  font-family: "Montserrat", Helvetica, Arial, serif;
}
</style>

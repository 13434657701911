import { HttpApiService } from '~/services/http/HttpApiService'
import { Service } from '~/services/http/Service'

export class PushNotificationsApiService extends Service {
  private endPoints = {
    firstPart: (userId: number) => `/v4/estate-market/users/${userId}/fcm-tokens`,
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  saveFirebaseToken(token: string, deviceId: string, userId: number) {
    return this.httpInstance.post(`${this.endPoints.firstPart(userId)}`, { token, deviceId })
  }

  removeFirebaseToken(deviceId: string, userId: number) {
    return this.httpInstance.delete(`${this.endPoints.firstPart(userId)}/${deviceId}`)
  }
}

<template>
  <div class="max-w-sm md:max-w-xl animate-pulse">
    <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" />
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] md:max-w-[560px] mb-2.5" />
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5" />
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] md:max-w-[530px] mb-2.5" />
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] md:max-w-[500px] mb-2.5" />
    <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] md:max-w-[560px]" />
    <span class="sr-only">Loading...</span>
  </div>
</template>

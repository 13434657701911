<template>
  <template v-if="suggestion.clients?.length">
    <div
      v-for="(client, id) in suggestion.clients"
      :key="id"
      class="flex items-center gap-x-2"
    >
      <custom-button
        size="none"
        color="none"
        variant="withoutBorders"
        class="text-neutral-900 font-semibold text-left"
        @click="() => getClientAndOpenModal(client.id)"
      >
        {{ client.fullName }}
      </custom-button>
      <custom-button-icon
        size="none"
        variant="withoutBorders"
        class="text-neutral-900 !w-[17px] !h-[17px]"
        color="none"
        icon="actions/close"
        icon-class="text-[17px]"
        @click="removeClient(client.id)"
      />
    </div>
    <modal-or-bottom-sheet
      v-model="isModalOpen"
      :modal-props="{hideFooter: true, hideHeader: true}"
    >
      <client-add-form
        :client="editedClient"
        @update:edit="editClient"
      >
        <template #closeButton>
          <custom-button
            class="!text-neutral-700 text-2xl hidden md:block"
            variant="withoutBorders"
            size="none"
            type="button"
            @click="isModalOpen = false"
          >
            <nuxt-icon
              name="actions/close"
            />
          </custom-button>
        </template>
      </client-add-form>
    </modal-or-bottom-sheet>
  </template>
  <span
    v-else
    class="font-semibold text-secondary md:hidden"
  >
    {{ $t('suggestionsActions.client.noClients') }}
  </span>
</template>

<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { type ManagingSuggestion, SuggestionsService } from '~/modules/suggestions/SuggestionsModule'
import type { Client } from '~/modules/clients/types/Client'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import ClientAddForm from '~/modules/clients/components/forms/ClientAddForm.vue'
import { ClientsService } from '~/modules/clients/services/ClientsService'

const props = defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'changeClients', suggestionId: number, clients: Client[]): void, (e: 'changeClientInAllSuggestions', client: Client): void}>()

const clientService = new ClientsService()
const service = new SuggestionsService()
const { t } = useI18n()

const editedClient: Ref<Client | null> = ref(null)
const isModalOpen = ref(false)

const getClientAndOpenModal = (clientId: number) => {
  clientService.getClient(clientId)
    .then(data => {
      editedClient.value = data
      isModalOpen.value = true
    })
}

const editClient = (client: Client) => {
  emits('changeClientInAllSuggestions', client)
  isModalOpen.value = false
}

const removeClient = (clientId: number) => {
  service.removeClientFromSuggestion(props.suggestion.id, clientId)
    .then(newSuggestion => {
      emits('changeClients', props.suggestion.id, newSuggestion.clients)
      toast(t('suggestionsActions.client.remove.toast.success'), { variant: 'success' })
    })
}
</script>

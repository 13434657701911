export enum Cursor {
  /** Курсор-стрелка. */
  arrow = 'arrow',
  /** Курсор в виде перекрестия */
  crosshair = 'crosshair',
  /** Курсор в виде руки */
  grab = 'grab',
  /** Курсор в виде руки со сжатыми пальцами */
  grabbing = 'grabbing',
  /** Курсор в виде стрелки с прикрепленным справа знаком вопроса. */
  help = 'help',
  /** Унаследованный курсор */
  inherit = 'inherit',
  /** Курсор в виде четырех стрелок */
  move = 'move',
  /** Курсор в виде указательного пальца */
  pointer = 'pointer',
  /** Курсор в виде лупы */
  zoom = 'zoom'
}

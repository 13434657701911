<template>
  <auto-suggest
    v-bind="$attrs"
    v-model:query="queryString"
    v-model:selected="selectedOptions"
    :suggest-class="suggestClass"
    :is-change-mobile-input-class="isChangeMobileInputClass"
    :disabled="addressStore.country === null"
    :suggestions="suggestionOptions"
    :section-configs="{
      buildings: {label: $t('building.entityName')},
      developers: {label: $t('developer.entityName')},
    }"
    :is-fetching="isFetching"
    :input-props="{placeholder: addressStore.country ? $t('entityActions.filter.entitiesFilter.placeholder') : $t('entityActions.filter.entitiesFilter.noCountryPlaceholder')}"
    @select="selectOption"
    @deselect="deselectOption"
  >
    <template #option="{ option, group}">
      <div
        v-if="group === 'buildings'"
        class="flex grow"
      >
        <client-only>
          <font-awesome-icon
            icon="fa-regular fa-building"
            class="mr-4"
          />
        </client-only>
        <span>{{ option.entity.name }}</span>
        <nuxt-link
          :to="`buildings/${option.entity.id}`"
          class="inline-block ml-auto mr-4"
        >
          <custom-button
            variant="withoutBorders"
            size="xs"
          >
            перейти
          </custom-button>
        </nuxt-link>
      </div>
      <span v-if="group === 'developers'">
        <client-only>
          <font-awesome-icon
            icon="fa-solid fa-briefcase"
            class="mr-2"
          />
        </client-only>
        {{ option.entity.name }}
      </span>

      <span
        v-if="!option.excluded"
        class="cursor-pointer ml-auto"
        @click.stop="toggleOptionExcluded(option, group)"
      >
        <nuxt-icon
          name="common/circle-minus-regular"
          class="text-danger"
        />
      </span>
      <span
        v-else
        class="cursor-pointer ml-auto"
        @click="toggleOptionExcluded(option, group)"
      >
        <nuxt-icon
          name="common/circle-minus-solid"
          class="text-danger"
        />
      </span>
    </template>
    <template
      v-if="otherExcludedOptions.length || otherIncludedOptions.length || selectedOptions[ENTITIES.buildings].length"
      #selected-option
    >
      <div :class="suggestSelectedOptionClass">
        <custom-button
          class="p-2 pt-0 md:p-0 md:-mb-1 flex items-center"
          color="none"
          variant="withoutBorders"
          size="none"
          @click="isModalOpen = true"
        >
          <custom-badge
            v-if="otherExcludedOptions.length"
            color="danger"
            class="cursor-pointer"
          >
            <span class="font-semibold text-danger">
              {{ otherExcludedOptions.length }}
            </span>
          </custom-badge>
          <custom-badge
            v-if="otherIncludedOptions.length"
            color="success"
            class="cursor-pointer"
          >
            <span class="font-semibold text-success">
              {{ otherIncludedOptions.length }}
            </span>
          </custom-badge>
          <span
            v-if="selectedOptions[ENTITIES.buildings].length"
            class="inline-flex text-primary font-semibold"
          >
            {{ selectedOptions[ENTITIES.buildings].length }}
            <nuxt-icon
              name="entities/building"
              class="ml-1 text-lg"
            />
          </span>
        </custom-button>
      </div>
    </template>
  </auto-suggest>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    :drawer-props="{fullHeight: true}"
  >
    <h4 v-if="filter.buildingFilter.included.length || filter.buildingFilter.excluded.length">
      {{ $t('building.entityName') }}
    </h4>
    <template v-if="filter.buildingFilter.included.length">
      <custom-badge
        v-for="(building, i) of filter.buildingFilter.included"
        :key="i"
        color="success"
      >
        {{
          getEntityName({
            name: building.name,
            internationalName: building.internationalName,
            entityCountry: building.address?.countryIsoCode
          })
        }}
        <nuxt-icon
          name="common/circle-close-regular"
          class="cursor-pointer ml-2"
          @click="deselectEntity(building, filter.buildingFilter)"
        />
      </custom-badge>
    </template>
    <template v-if="filter.buildingFilter.excluded.length">
      <custom-badge
        v-for="(building, i) of filter.buildingFilter.excluded"
        :key="i"
        color="danger"
      >
        {{
          getEntityName({
            name: building.name,
            internationalName: building.internationalName,
            entityCountry: building.address?.countryIsoCode
          })
        }}
        <nuxt-icon
          name="common/circle-close-regular"
          class="cursor-pointer ml-2"
          @click="deselectEntity(building, filter.buildingFilter)"
        />
      </custom-badge>
    </template>
    <h4 v-if="filter.developerFilter.included.length || filter.developerFilter.excluded.length">
      {{ $t('developer.entityName') }}
    </h4>
    <template v-if="filter.developerFilter.included.length">
      <custom-badge
        v-for="(developer, i) of filter.developerFilter.included"
        :key="i"
        color="success"
      >
        {{
          getEntityName({
            name: developer.name,
            internationalName: developer.internationalName,
            entityCountry: developer.address?.countryIsoCode
          })
        }}
        <nuxt-icon
          name="common/circle-close-regular"
          class="cursor-pointer ml-2"
          @click="deselectEntity(developer, filter.developerFilter)"
        />
      </custom-badge>
    </template>
    <template v-if="filter.developerFilter.excluded.length">
      <custom-badge
        v-for="(developer, i) of filter.developerFilter.excluded"
        :key="i"
        color="danger"
      >
        {{
          getEntityName({
            name: developer.name,
            internationalName: developer.internationalName,
            entityCountry: developer.address?.countryIsoCode
          })
        }}
        <nuxt-icon
          name="common/circle-close-regular"
          class="cursor-pointer ml-2"
          @click="deselectEntity(developer, filter.developerFilter)"
        />
      </custom-badge>
    </template>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import AutoSuggest from '~/components/common/AutoSuggest/AutoSuggest.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomBadge from '~/ui/badges/CustomBadge.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import { useAddressStore } from '~/modules/address/store'
import type { Building } from '~/modules/building/types/Building'
import type { Developer } from '~/common/types/developer/Developer'
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { ArrayHelper } from '~/common/helpers/arrays'
import { FilterService } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import { FilterGuestService } from '~/modules/market-entities-actions/services/FilterGuestService'
import { getEntityName } from '~/common/helpers/getEntityName'

type Suggestion = {
  buildings: Building[]
  developers: Developer[]
}
type Entity = Building | Developer
type FilterOption<T extends Entity = Entity> = {entity: T, excluded: boolean}

const ENTITIES = {
  buildings: 'buildings',
  developers: 'developers',
} as const

const props = defineProps({
  modelValue: {
    type: Object as PropType<MarketEntitiesFilter>,
    required: true,
  },
  filterService: {
    type: Object as PropType<FilterService | FilterGuestService>,
    default: null,
  },
  suggestSelectedOptionClass: {
    type: [String, Object, Array],
    default: null,
  },
  suggestClass: {
    type: [String, Object, Array],
    default: null,
  },
  isChangeMobileInputClass: {
    type: Boolean,
    default: true,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: MarketEntitiesFilter): void}>()

const addressStore = useAddressStore()

const filterService = props.filterService || new FilterService()
const filter = computed({
  get: () => props.modelValue,
  set: value => { emits('update:modelValue', value) },
})
const queryString = ref('')
const querySuggestions = ref<Record<keyof typeof ENTITIES, FilterOption[]> | null>(null)

const isFetching = ref(false)
const isModalOpen = ref(false)

const suggestionsLimitPerEntity = 10

const suggestionOptions = computed(() => {
  const suggestions = querySuggestions.value
  if (suggestions === null) {
    return [] as Array<Record<string, any>>
  }
  return Object.entries(suggestions).map(([key, value]) => ({ [key]: value }))
})
const getSuggestions = () => {
  if (queryString.value === '') {
    querySuggestions.value = null
    return
  }
  if (!addressStore.country) {
    return
  }
  isFetching.value = true
  filterService.suggest({ id: addressStore.country.id }, queryString.value, suggestionsLimitPerEntity).then(response => {
    const suggestions = response as Suggestion
    querySuggestions.value = {
      buildings: suggestions.buildings.map(entity => ({ entity, excluded: false })),
      developers: suggestions.developers.map(entity => ({ entity, excluded: false })),
    }
    isFetching.value = false
  })
}

const selectedOptions = ref<Record<string, FilterOption[]>>({
  [ENTITIES.buildings]: [],
  [ENTITIES.developers]: [],
})
const otherIncludedOptions = computed(() => {
  const entitiesFilter = filter.value as MarketEntitiesFilter
  return [...entitiesFilter.developerFilter.included]
})
const otherExcludedOptions = computed(() => {
  const entitiesFilter = filter.value as MarketEntitiesFilter
  return [...entitiesFilter.developerFilter.excluded]
})
const selectedOptionsLength = computed(() => selectedOptions.value[ENTITIES.buildings].length + selectedOptions.value[ENTITIES.developers].length)

function getFilterCategory(group: keyof Suggestion): Extract<keyof MarketEntitiesFilter, 'buildingFilter' | 'developerFilter'> {
  switch (group) {
    case 'buildings':
      return 'buildingFilter'
    case 'developers':
      return 'developerFilter'
    default:
      return 'buildingFilter'
  }
}

const selectOption = (option: FilterOption, optionsGroup: string) => {
  const filterCategory = filter.value[getFilterCategory(optionsGroup as keyof Suggestion)]
  if (!option.excluded) {
    (filterCategory.included as Array<Entity>).push(option.entity)
  }
  queryString.value = ''
}
const deselectEntity = (entity: Entity, filterGroup: MarketEntitiesFilter['buildingFilter' | 'developerFilter']) => {
  ArrayHelper.delete<Entity>(filterGroup.excluded, filterOption => filterOption.id === entity.id)
  ArrayHelper.delete<Entity>(filterGroup.included, filterOption => filterOption.id === entity.id)
}
const deselectOption = (option: FilterOption, optionsGroup: string) => {
  const filterCategory = filter.value[getFilterCategory(optionsGroup as keyof Suggestion)]
  deselectEntity(option.entity, filterCategory)
  option.excluded = false
}

const toggleOptionExcluded = (option: FilterOption, optionsGroup: string) => {
  const filterCategory = filter.value[getFilterCategory(optionsGroup as keyof Suggestion)]
  if (!option.excluded) {
    (filterCategory.excluded as Array<Entity>).push(option.entity)
    ArrayHelper.delete<Entity>(filterCategory.included, filterOption => filterOption.id === option.entity.id)
    ArrayHelper.add(selectedOptions.value[optionsGroup], option)
  } else {
    ArrayHelper.delete<Entity>(filterCategory.excluded, filterOption => filterOption.id === option.entity.id)
  }
  option.excluded = !option.excluded
}

watch(() => queryString.value, getSuggestions)
watch(() => filter.value, (value: MarketEntitiesFilter) => {
  selectedOptions.value[ENTITIES.buildings].forEach((buildingOption: FilterOption<Building>) => {
    buildingOption.excluded = value.buildingFilter.excluded.includes(buildingOption.entity)
  })
  selectedOptions.value[ENTITIES.buildings] = selectedOptions.value[ENTITIES.buildings]
    .filter((buildingOption: FilterOption<Building>) => value.buildingFilter.excluded.includes(buildingOption.entity) || value.buildingFilter.included.includes(buildingOption.entity))

  selectedOptions.value[ENTITIES.developers].forEach((developerOption: FilterOption<Developer>) => {
    developerOption.excluded = value.developerFilter.excluded.includes(developerOption.entity)
  })
  selectedOptions.value[ENTITIES.developers] = selectedOptions.value[ENTITIES.developers]
    .filter((developerOption: FilterOption<Developer>) => value.developerFilter.excluded.includes(developerOption.entity) || value.developerFilter.included.includes(developerOption.entity))
}, { deep: true })

watch(selectedOptionsLength, length => {
  if (length === 0) {
    isModalOpen.value = false
  }
})

onMounted(() => {
  const buildings = filter.value.buildingFilter
  const includedBuildings = buildings.included.map(building => ({
    entity: building,
    excluded: false,
  }))
  const excludedBuildings = buildings.excluded.map(building => ({
    entity: building,
    excluded: true,
  }))
  const developers = filter.value.developerFilter
  const includedDevelopers = developers.included.map(developer => ({
    entity: developer,
    excluded: false,
  }))
  const excludedDevelopers = developers.excluded.map(developer => ({
    entity: developer,
    excluded: true,
  }))

  selectedOptions.value = {
    [ENTITIES.buildings]: [...includedBuildings, ...excludedBuildings],
    [ENTITIES.developers]: [...includedDevelopers, ...excludedDevelopers],
  }
})
</script>

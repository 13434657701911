<template>
  <div>
    <custom-scrollbar
      class="h-[132px] gap-[20px]"
      hide-controls
    >
      <building-list-card
        v-for="(building, index) of buildings"
        :key="index"
        :building="building"
        class="cursor-pointer w-[242px] shrink-0"
        :is-selected="index === currentBuildingIndex"
        @click="currentBuildingIndex = index"
      >
        <template #actions>
          <slot
            name="actions"
            :building="building"
          />
        </template>
        <template #under-title>
          <slot
            name="under-title"
            :building="building"
          />
        </template>
      </building-list-card>
    </custom-scrollbar>
  </div>
</template>

<script setup lang="ts">
import BuildingListCard from '~/modules/suggestions/components/page/buildings-list/BuildingListCard.vue'
import CustomScrollbar from '~/ui/scrollbars/CustomScrollbar.vue'
import { type SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'

const props = defineProps({
  buildings: {
    type: Array as PropType<Array<SuggestionBuilding>>,
    default: () => [],
  },
  modelValue: {
    type: Object as PropType<SuggestionBuilding | null>,
    default: () => null,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: SuggestionBuilding): void}>()

const currentBuildingIndex = computed<number | null>({
  get() {
    if (props.modelValue === null) {
      return null
    }
    return props.buildings?.indexOf(props.modelValue)
  },
  set(value: number) {
    emits('update:modelValue', props.buildings?.[value])
  },
})

</script>

<template>
  <custom-drawer
    v-model="isModalOpen"
    full-height
    with-backdrop
  >
    <div
      v-if="buildings?.length"
      class="flex flex-col overflow-y-auto scrollbar-thin justify-between min-h-full"
    >
      <div class="flex flex-col gap-y-4">
        <building-grid-item
          v-for="(building, index) in buildings"
          :key="`${building.id}-${index}`"
          :building="building"
          :is-display-on-map-show="false"
        />
      </div>
      <slot name="pagination" />
    </div>
  </custom-drawer>
</template>

<script setup lang="ts">
import { Building, BuildingGridItem } from '~/modules/building/BuildingModule'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  buildings: {
    type: Array as PropType<Building[]>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => { emits('update:modelValue', value) },
})
</script>

<template>
  <div
    v-for="item in items"
    :key="item.title"
  >
    <component
      :is="navMenuItemsLinkOrGroup(item)"
      :item="item"
    />
  </div>
  <custom-button
    v-if="width < 1024"
    variant="outline"
    class="border-none h-full max-h-[38px] w-full hover:text-primary rounded-none active:!text-primary"
    @click="showAuthModalOrCallback(fix)"
  >
    <template
      #prefix
    >
      <nuxt-icon
        name="entities/fixations"
        class="inline text-[24px]"
      />
    </template>
    <div
      class="ml-2 text-[18px]"
    >
      {{ t('clients.fixation.actions.add') }}
    </div>
  </custom-button>
  <clients-form-fixation-or-booking-modal
    v-model="isModalFixationVisible"
    action="fixation"
  />
</template>
<script setup lang="ts">

import NavMenuItemGroup from '~/modules/nav-menu/components/nav-menu-items/NavMenuItemGroup.vue'
import NavMenuItemLink from '~/modules/nav-menu/components/nav-menu-items/NavMenuItemLink.vue'
import { NavGroupItem } from '~/modules/nav-menu/types/NavGroupItem.type'
import { NavLinkItem } from '~/modules/nav-menu/types/NavLinkItem.type'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { useWindowSize } from '@vueuse/core'
import ClientsFormFixationOrBookingModal
  from '~/modules/clients/components/forms/ClientsFormActionModal.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'

defineProps({
  items: {
    type: Array<NavGroupItem | NavLinkItem>,
    required: true,
  },
})

const { t } = useI18n()

const { width } = useWindowSize()

const isModalFixationVisible = ref(false)

const fix = () => {
  isModalFixationVisible.value = true
}

const navMenuItemsLinkOrGroup = (item: NavGroupItem | NavLinkItem) => {
  if ((item as NavGroupItem).children) {
    return NavMenuItemGroup
  }
  return NavMenuItemLink
}
</script>

<template>
  <custom-modal
    v-model="isModalOpen"
    hide-footer
    hide-header
    size="fullscreen"
  >
    <template #body>
      <div
        class="relative grow max-h-full bg-blue-50"
      >
        <div
          v-show="isMapLoading"
          class="w-full h-full flex items-center justify-center"
        >
          <custom-cube-spinner />
        </div>
        <div
          v-show="!isMapLoading"
          class="h-full w-full"
          :class="wrapperClass"
        >
          <yandex-custom-map
            :service="mapService"
            @on-map-loaded="isMapLoading = false"
          />
        </div>
        <div
          v-if="!isMapLoading"
          class="hidden md:block absolute top-6 left-6 md:w-[40%] md:max-w-[410px] xl:w-[512px] xl:max-w-none overflow-hidden h-[calc(100%_-_3rem)]"
        >
          <div
            class="p-6 rounded-lg bg-white overflow-y-auto scrollbar-thin"
            :class="buildings?.length || marketStore.isMapBuildingFetching ? 'h-full' : 'max-h-full'"
          >
            <div
              v-if="marketStore.isMapBuildingFetching"
              class="w-full h-full flex items-center justify-center"
            >
              <custom-cube-spinner />
            </div>
            <template
              v-else-if="buildings?.length"
            >
              <div class="flex flex-col gap-y-4">
                <building-grid-item
                  v-for="(building, index) in buildings"
                  :key="`${building.id}-${index}`"
                  :building="building"
                  :is-display-on-map-show="false"
                />
              </div>
              <custom-pagination
                v-if="marketStore.mapPagination?.lastPage > 1"
                :pagination="marketStore.mapPagination"
                class="justify-center my-3"
                :is-fetching="marketStore.isMapBuildingFetching"
                @on-page-change="onPageChange"
              />
            </template>
            <h3 v-else class="font-semibold text-xl">
              {{ $t('map.building.notFound') }}
            </h3>
          </div>
        </div>
        <div
          v-if="activeBuildingId && !isMd"
          class="absolute bottom-[70px] left-1/2 -translate-x-1/2 w-full px-4"
        >
          <building-mini-card
            ref="miniCardRef"
            class="w-full cursor-pointer"
            :building="chosenBuilding"
            @click="isShowBuildingDrawerOpen = true"
          />
        </div>
        <filter-modal
          v-if="isMd"
          v-model="isFilterModalOpen"
          @search="mapService.sendAllRequests(); isFilterModalOpen = false"
        />
        <template
          v-else
        >
          <show-buildings-drawer
            v-model="isShowBuildingDrawerOpen"
            :buildings="chosenBuilding ? [chosenBuilding] : buildings"
            :count="marketStore.mapBuildingsCount.buildingsCount ?? 0"
          >
            <template #pagination>
              <custom-pagination
                v-if="marketStore.mapPagination?.lastPage > 1"
                :pagination="marketStore.mapPagination"
                class="justify-center my-3"
                :is-fetching="marketStore.isMapBuildingFetching"
                @on-page-change="onPageChange"
              />
            </template>
          </show-buildings-drawer>
          <market-entities-filter
            v-model="isFilterModalOpen"
            only-drawer
            @search="mapService.sendAllRequests()"
          />
        </template>
      </div>
    </template>
  </custom-modal>
</template>

<script setup lang="ts">
import YandexCustomMap from '~/components/map/YandexCustomMap.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { BuildingSearchMapService } from '~/modules/market-entities-actions/services/BuildingSearchMapService'
import { useAppStateStore } from '~/store/app'
import { Building, BuildingGridItem } from '~/modules/building/BuildingModule'
import CustomModal from '~/ui/modals/CustomModal.vue'
import FilterModal from '~/modules/market-entities-actions/components/filter/FilterModal.vue'
import {
  MarketEntitiesFilter,
  useMarketEntitiesActionsStore
} from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import CustomPagination from '~/ui/pagination/CustomPagination.vue'
import ShowBuildingsDrawer from '~/modules/market-entities-actions/components/filter/ShowBuildingsDrawer.vue'
import BuildingMiniCard from '~/modules/building/components/grid-item/BuildingMiniCard.vue'
import { YANDEX_MAP_DEFAULT_CONFIG } from '~/common/constants/map/yandex/Map.defaultConfig'
import { onClickOutside } from '@vueuse/core'
import { PaintOnMap } from '~/common/modules/map/PaintOnMap'
import { CalculateArea } from '~/common/modules/map/CalculateArea'
import { Polylabel } from '~/common/modules/map/Polylabel'
import { useAddressStore } from '~/modules/address/store'
import useOrganisationStore from '~/modules/organisation/store'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'search'): void}>()

const addressStore = useAddressStore()
const organisationStore = useOrganisationStore()
const marketStore = useMarketEntitiesActionsStore()

const isMapLoading = ref(true)
const buildings = computed(() => marketStore.mapBuildings)
const isFilterModalOpen = ref(false)
const isShowBuildingDrawerOpen = ref(false)
const activeBuildingId = ref<number | null>(null)
const chosenBuilding = ref<Building | null>(null)
const miniCardRef = ref<HTMLDivElement | null>(null)
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const paintOnMap = new PaintOnMap()
const calculateArea = new CalculateArea()
const polylabel = new Polylabel()

const getMapState = () => {
  const { country, region, city } = addressStore
  const organisationAddress = organisationStore.organisation?.address

  let { center } = YANDEX_MAP_DEFAULT_CONFIG.state

  if (city && city.geoLat && city.geoLon) {
    center = [Number(city.geoLat), Number(city.geoLon)]
  } else if (region && region.region && region.region.geoLat && region.region.geoLon) {
    center = [Number(region.region.geoLat), Number(region.region.geoLon)]
  } else if (country && country.geoLat && country.geoLon) {
    center = [Number(country.geoLat), Number(country.geoLon)]
  } else if (organisationAddress && organisationAddress.geoLat && organisationAddress.geoLon) {
    center = [Number(organisationAddress.geoLat), Number(organisationAddress.geoLon)]
  }

  return { center }
}

const mapService = new BuildingSearchMapService(
  {
    onCloseClick: () => {
      isModalOpen.value = false
      emits('search')
    },
    onFilterClick: () => {
      isFilterModalOpen.value = true
    },
    onPointClick: (id: number) => {
      activeBuildingId.value = id
    },
    onMobileShowBuildingClick: () => {
      isShowBuildingDrawerOpen.value = true
    },
  },
  { state: getMapState(), options: {} },
  {
    paintOnMap,
    calculateArea,
    polylabel,
  },
  !!addressStore.country,
)

onClickOutside(miniCardRef, () => {
  if (!isShowBuildingDrawerOpen.value) {
    activeBuildingId.value = null
  }
})

const onPageChange = (page = 1): void => {
  mapService.setBuildings(page)
}

watch(isShowBuildingDrawerOpen, value => {
  if (!value) {
    activeBuildingId.value = null
  }
})
watch(activeBuildingId, value => {
  chosenBuilding.value = (value && buildings.value.find(building => building.id === value)) || null
})
watch(() => marketStore.filter, (value, oldValue) => {
  if (!value.buildingFilter.geometriesCoordinates.length && oldValue.buildingFilter.geometriesCoordinates.length) {
    mapService.deleteAllPaintedAreas()
  }
})
</script>

<style lang="scss">
.ymap-container {
  @apply h-full;
}
.button-shadow {
  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.15), 0 2px 5px -3px rgba(0,0,0,0.15);
}
.ymaps-2-1-79-controls__toolbar_left {
  @apply md:ml-[min(40%+34px,410px+34px)] xl:ml-[calc(518px+34px)];
}
</style>

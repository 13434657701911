<template>
  <selected-filters :selected-filters="selectedFilters" />
</template>
<script setup lang="ts">
import SelectedFilters from '~/modules/market-entities-actions/components/filter/SelectedFilters.vue'
import type { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { QuarterUtils } from '~/common/helpers/date/QuarterUtils'
import { FilterService } from '~/modules/market-entities-actions/services/FilterService'
import { QUARTER } from '~/common/constants/date/Quarter'

const props = defineProps({
  filter: {
    type: Object as PropType<MarketEntitiesFilter>,
    required: true,
  },
})

const { t, n } = useI18n()
const filterService = new FilterService()

function getLabelByPath(path: string, value: any): {path: string, value?: any, label: string} {
  const filterI18nPrefix = 'entityActions.filter'
  const deadLineYearPaths = [
    'houseFilter.deadLine.from',
    'houseFilter.deadLine.to',
  ]
  const deadLineGettingKeysYearPaths = [
    'houseFilter.keysGetting.from',
    'houseFilter.keysGetting.to',
  ]
  const floorNumbersPaths = ['flatFilter.floorNumber.from', 'flatFilter.floorNumber.to']

  const flatRoomsCountPath = 'flatFilter.flatRoomsCount'

  const materialsPaths = 'houseFilter.materialTypes'

  const flatDecorationTypePath = 'flatFilter.decorationTypes'

  const windowViewPath = 'flatFilter.windowsViewTypes'

  const hasPromotionsPath = 'buildingFilter.hasPromotions'

  const notFirstFloorPath = 'flatFilter.notFirstFloor'

  const lastFloorPath = 'flatFilter.lastFloor'

  const hasPassengerLiftPath = 'houseFilter.lifts.hasPassenger'

  const hasCargoLiftPath = 'houseFilter.lifts.hasCargo'

  const buildingClassesPath = 'buildingFilter.classes'

  const buildingGeometriesPath = 'buildingFilter.geometriesCoordinates'

  const formattedValue = typeof value === 'number' ? n(value) : value
  if (deadLineYearPaths.includes(path)) {
    const preparedValue = {
      year: value.year,
      quarter: QUARTER[value.quarter],
    }
    const labelPath = path.replace('deadLine', 'deadLineYear')
    return { path, label: t(`${filterI18nPrefix}.${labelPath}`, preparedValue) }
  }
  if (deadLineGettingKeysYearPaths.includes(path)) {
    const preparedValue = {
      year: value.year,
      quarter: QUARTER[value.quarter],
    }
    return { path, label: t(`${filterI18nPrefix}.${path}`, preparedValue) }
  }
  if (materialsPaths.includes(path)) {
    return { path, label: t(`${filterI18nPrefix}.${path}.${value}.label`), value }
  }
  if (floorNumbersPaths.includes(path)) {
    return { path, label: t(`flat.${path.replace('flatFilter.', '')}`, { value }) }
  }

  if (path === flatRoomsCountPath) {
    return {
      path,
      value,
      label: t(`${filterI18nPrefix}.${path}.${value}`),
    }
  }
  if (path === hasPromotionsPath) {
    return {
      path,
      value,
      label: t(`${filterI18nPrefix}.promotions.hasPromotions`),
    }
  }
  if (path === flatDecorationTypePath) {
    return {
      path,
      value,
      label: t(`flat.decorationType.options.${value}.full`),
    }
  }
  if (path === windowViewPath) {
    return {
      path,
      value,
      label: t(`flat.windowViewType.options.${value}.label`),
    }
  }
  if (path === lastFloorPath) {
    return {
      path,
      value,
      label: t('flat.lastFloor.label'),
    }
  }
  if (path === notFirstFloorPath) {
    return {
      path,
      value,
      label: t('flat.notFirstFloor.label'),
    }
  }
  if (path === hasPassengerLiftPath || path === hasCargoLiftPath) {
    return {
      path,
      value,
      label: t(`${filterI18nPrefix}.${path}.label`),
    }
  }
  if (path === buildingClassesPath) {
    return {
      path,
      value,
      label: t(`building.${path.replace('buildingFilter.', '')}.${value}.label`),
    }
  }
  if (path === buildingGeometriesPath) {
    return {
      path,
      value,
      label: t('map.paintedGeometries', value),
    }
  }

  return { path, value, label: t(`${filterI18nPrefix}.${path}`, { value: formattedValue }) }
}

const selectedFilters = computed(() => filterService.getSelectedFilters(props.filter)
  .map(({ path, value }) => getLabelByPath(path, value)))

</script>

<template>
  <div class="flex flex-wrap gap-3">
    <custom-button
      v-for="classValue of options"
      :key="classValue"
      pill
      size="xs"
      variant="outline"
      class="font-semibold !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': selectedOptions.includes(classValue)}"
      @click="toggleSelected(classValue)"
    >
      {{ $t(`building.classes.${classValue}.label`) }}
    </custom-button>
  </div>
</template>
<script setup lang="ts">
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default.ts'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { ArrayHelper } from '~/common/helpers/arrays'
import { BuildingClass } from '~/modules/building/types/Building.class'

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<BuildingClass>>,
    default: () => defaultFilter().buildingFilter.classes,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: Array<BuildingClass>): void }>()

const options: Array<BuildingClass> = ['business', 'comfort', 'economy', 'elite']
const selectedOptions = computed({
  get: () => props.modelValue,
  set: value => emits('update:modelValue', value),
})

const toggleSelected = (option: BuildingClass) => {
  const selectedOptionsCopy = [...selectedOptions.value]
  ArrayHelper.toggle(selectedOptionsCopy, option)
  selectedOptions.value = selectedOptionsCopy
}
</script>

<template>
  <div class="flex flex-wrap gap-3">
    <custom-button
      v-for="material of options"
      :key="material"
      pill
      size="xs"
      variant="outline"
      class="font-semibold !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': selectedOptions.includes(material)}"
      @click="toggleSelected(material)"
    >
      {{ $t(`entityActions.filter.houseFilter.materialTypes.${material}.label`) }}
    </custom-button>
  </div>
</template>
<script setup lang="ts">
import type { DecorationType } from '~/common/types/decoration/Decoration.type'
import { MATERIAL_TYPE } from '~/common/constants/material/Material.type'
import { MaterialType } from '~/common/types/material/Material.type'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { ArrayHelper } from '~/common/helpers/arrays'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Array as PropType<Array<MaterialType>>,
    required: true,
    default: () => defaultFilter().houseFilter.materialTypes,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: Array<DecorationType>): void }>()

const options = Object.values(MATERIAL_TYPE).map(materialType => (materialType))

const selectedOptions = computed({
  get() {
    return props.modelValue
  },
  set(materialTypes) {
    emits('update:modelValue', materialTypes)
  },
})

const toggleSelected = (option: MaterialType) => {
  const selectedOptionsCopy = [...selectedOptions.value]
  ArrayHelper.toggle(selectedOptionsCopy, option)
  selectedOptions.value = selectedOptionsCopy
}

</script>

<template>
  <div
    class="h-[200px] bg-gray-200 dark:bg-gray-700 max-w-[360px] md:max-w-[100%] animate-pulse"
    :class="{'rounded-full': rounded}"
    :style="style"
  />
</template>
<script setup lang="ts">
const props = defineProps({
  width: {
    type: [String, Number],
    default: '',
  },
  height: {
    type: [String, Number],
    default: '',
  },
  aspectRatio: {
    type: Number,
    default: 1,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
})

const style = ref({ width: props.width, height: props.height })
</script>

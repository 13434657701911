import { GeometriesCoordinates } from '~/common/types/map/ymaps.geometriesCoordinates'
import { Cursor } from '~/common/constants/cursor/Cursor'
import { ButtonService } from '~/common/services/map/yandex/ButtonService'
import cursorIcon from '~/assets/icons/common/cursor.svg'
import type { ComposerTranslation, VueMessageType } from '@nuxtjs/i18n/dist/runtime/composables'
import type { LocaleMessage, LocaleMessageValue, RemoveIndexSignature } from '@intlify/core-base'

type I18nTType = ComposerTranslation<{
  [x: string]: LocaleMessage<VueMessageType>;
}, string, RemoveIndexSignature<{
  [x: string]: LocaleMessageValue<VueMessageType>;
}>, never, string, string>

export class MapPaint {
  private map: ymaps.Map

  public paintButton: ymaps.control.Button

  private readonly strokeStyle: ymaps.IPolygonOptions = {
    strokeColor: '#6482E6',
    strokeOpacity: 1,
    strokeWidth: 2,
    strokeStyle: 'dash',
    fillColor: '#6482E6',
    fillOpacity: 0.1,
  }

  private readonly labelOptions = {
    labelLayout: this.deletePolygonButton,
    labelDotVisible: false,
    labelTextColor: '#6482E6',
    labelCursor: Cursor.pointer,
    labelForceVisible: { '0_23': 'none' },
  }

  private paintProcess: ymaps.PaintOnMapReturnValue | null

  private actions: { atTheEndOfDrawing: (coordinates: Array<GeometriesCoordinates>) => void, context: any }

  private cursor: ymaps.util.cursor.Accessor | undefined

  private collection: ymaps.GeoObjectCollection

  private paintMode = false

  private editingPolygon: ymaps.GeoObject | null = null

  private propertiesNames: Array<string> = []

  private t

  constructor(
    map: ymaps.Map,
    actions: { atTheEndOfDrawing: (geometries: Array<GeometriesCoordinates>) => void, context: any },
    t: I18nTType,
    geometries: Array<GeometriesCoordinates> = [],
    propertiesNames: Array<string> = [],
  ) {
    this.map = map
    this.t = t
    this.paintButton = this.createPaintButton()
    this.paintProcess = null
    this.actions = actions
    this.collection = new ymaps.GeoObjectCollection()
    if (geometries && geometries.length) {
      this.addPolygonsOnTheMap(geometries)
      this.propertiesNames = propertiesNames
    }

    this.map.geoObjects.add(this.collection)
    ymaps.polylabel(this.map, this.collection)
  }

  private createPaintButton(): ymaps.control.Button {
    const button = ButtonService.createButton({
      image: cursorIcon,
      content: this.t('map.actions.paintOn'),
      layout: '<button class="hidden md:flex items-center text-primary font-semibold bg-white px-4 rounded-full button-shadow" style="height: 38px">' +
              '<span class="block w-[14px] h-[14px] md:w-[16px] md:h-[16px] bg-contain" style="background-image: url(&quot;{{ data.image }}&quot;)"></span>' +
              '<span class="ml-2 hidden lg:inline">' +
                '{{ data.content }}' +
              '</span>' +
            '</button>',
      floatIndex: 40,
      selectOnClick: true,
      onClick: () => {
        if (button.isSelected()) {
          this.turnOffDrawingMode()
        } else {
          this.turnOnDrawingMode()
        }
      },
      onMouseEnter: () => {
        this.stopDrawing()
      },
    })

    return button
  }

  private turnOffDrawingMode(): void {
    this.paintButton.data.set('content', this.t('map.actions.paintOn'))
    if (this.cursor) {
      this.cursor.remove()
    }

    this.paintMode = false
  }

  private turnOnDrawingMode(): void {
    this.paintButton.data.set('content', this.t('map.actions.painting'))
    this.cursor = this.map.cursors.push(Cursor.arrow)
    this.paintMode = true
    this.stopPolygonEditing()
  }

  private stopDrawing(): void {
    if (this.paintProcess) {
      const coordinates = this.paintProcess.finishPaintingAt()
      this.paintProcess = null
      const polygon: ymaps.GeoObject = this.createPolygon([coordinates])

      this.collection.add(polygon)

      ymaps.polylabel(this.map, this.collection)

      this.paintButton.deselect()
      this.turnOffDrawingMode()

      this.emitEndOfDrawing()
    }
  }

  private emitEndOfDrawing(): void {
    this.actions.atTheEndOfDrawing.call(this.actions.context, this.getGeometriesWithProperties())
  }

  public getGeometriesWithProperties(): Array<GeometriesCoordinates> {
    const geometries: Array<GeometriesCoordinates> = []

    this.collection.each(polygon => {
      if (polygon.geometry) {
        const geometry: GeometriesCoordinates = {
          geometryCoordinates: {
            type: 'MultiPolygon',
            coordinates: [polygon.geometry.getCoordinates()],
          },
          properties: {},
        }

        this.propertiesNames.forEach(name => {
          if (geometry.properties) {
            geometry.properties[name] = polygon.properties.get(name, { name: '' })
          }
        })

        geometries.push(geometry)
      }
    })

    return geometries
  }

  private stopPolygonEditing(): void {
    if (this.editingPolygon) {
      this.editingPolygon.editor.stopEditing()
      this.displayDeletePolygonButton(this.editingPolygon, false)
      this.editingPolygon = null
    }
  }

  private displayDeletePolygonButton(polygon: ymaps.GeoObject, isDisplay: boolean): void {
    if (isDisplay) {
      polygon.options.set({
        labelForceVisible: { '0_23': 'label' },
      })
    } else {
      polygon.options.set({
        labelForceVisible: { '0_23': 'none' },
      })
    }
  }

  private addPolygonsOnTheMap(geometries: Array<GeometriesCoordinates>): void {
    geometries.forEach(geometry => {
      if (geometry) { this.collection.add(this.createPolygon(geometry.geometryCoordinates.coordinates.flat(1), geometry.properties ?? {})) }
    })
  }

  private createPolygon(coordinates: number[][][], properties?: Record<string, any>): ymaps.GeoObject {
    if (!properties) {
      const existedProperties: Record<string, any> = properties || {}
      this.propertiesNames.forEach(name => {
        existedProperties[name] = null
      })
    }
    const polygon = new ymaps.Polygon(coordinates, properties, {
      ...this.strokeStyle,
      ...this.labelOptions,
    })

    polygon.events.add('click', () => {
      polygon.editor.startEditing()
      this.displayDeletePolygonButton(polygon, true)
    })

    polygon.events.add('labelmouseup', ev => {
      this.editingPolygon = null
      this.collection.remove(polygon)
      this.emitEndOfDrawing()
    })

    polygon.events.add('click', () => {
      if (polygon !== this.editingPolygon) {
        this.stopPolygonEditing()
      }
      this.editingPolygon = polygon
      polygon.editor.startEditing()
    })

    polygon.events.add('geometrychange', event => {
      this.emitEndOfDrawing()
    })

    return polygon
  }

  private get deletePolygonButton(): string {
    return '<div class="flex items-center justify-center bg-white rounded-full" style="width: 40px; height: 40px">\n' +
      '      <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>\n' +
      '    </div>'
  }

  public deleteAllPolygons(): void {
    this.collection.removeAll()
  }

  public subscribeToClickOnMap(): void {
    this.map.events.add('mousedown', event => {
      if (this.paintMode) {
        this.paintProcess = ymaps.paintOnMap(this.map, event, { style: this.strokeStyle })
      }
    })

    this.map.events.add('mouseup', event => {
      this.stopDrawing()
    })

    this.map.container.getParentElement().addEventListener('mouseleave', event => {
      this.stopDrawing()
    })

    this.map.events.add('click', event => {
      this.stopPolygonEditing()
    })
  }
}

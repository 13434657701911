<template>
  <custom-modal
    v-model="isModalOpen"
    size="w-full xl:max-w-[60vw] max-w-[90vw]"
    :centered="false"
    with-out-body-overflow
    :title="$t('entityActions.filter.button')"
  >
    <market-entities-filter @search="emits('search')" />
  </custom-modal>
</template>

<script setup lang="ts">
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import CustomModal from '~/ui/modals/CustomModal.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void, (e: 'search'): void}>()

const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})
</script>

<template>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :drawer-props="{fullHeight: true}"
    :modal-props="{hideFooter: true, size: 'fullscreen', hideHeader: true, bodyClass: '!p-0', contentClass: '!bg-neutral-100'}"
    @close="selectedBuilding = null"
  >
    <div class="h-[66px] flex justify-between items-center  p-4 container">
      <div class="flex">
        <h2
          :class="{'text-success': selectedBuilding, 'font-medium text-lg' : !isMd}"
          class="text-black"
        >
          {{ t('clients.views.building') }}
        </h2>
        <h2
          :class="{'!text-black': (selectedBuilding && !selectedFlat),'text-success': selectedFlat, 'font-medium text-lg !ml-[10px]' : !isMd }"
          class="ml-[44px] text-neutral-500"
        >
          {{ t('clients.views.chessboard') }}
        </h2>
        <h2
          :class="{'!text-black': (selectedFlat && !selectedClient),'text-success': selectedClient, 'font-medium text-lg !ml-[10px]' : !isMd }"
          class="ml-[44px] text-neutral-500"
        >
          {{ t('clients.views.aboutClient') }}
        </h2>
      </div>
      <div>
        <custom-button
          class="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg self-start"
          size="none"
          color="none"
          @click="closeModal"
        >
          <nuxt-icon
            name="actions/close"
            class="text-[2rem]"
          />
        </custom-button>
      </div>
    </div>
    <div class="bg-neutral-100 !mt-0">
      <div v-if="!selectedBuilding">
        <index is-client-view-visible>
          <template #building-actions="{building}">
            <custom-button
              class="w-full font-semibold"
              pill
              @click="chooseBuilding(building)"
            >
              {{ t('clients.views.actions.selectBuilding') }}
            </custom-button>
          </template>
        </index>
      </div>
      <div v-if="selectedBuilding && !selectedFlat">
        <building-chessboard
          v-model:filter="filter"
          :building="selectedBuilding"
        />
      </div>

      <client-booking-form
        v-if="selectedFlat && selectedBuilding && action === 'booking'"
        :flat="selectedFlat"
        @submit="close"
      />

      <client-fixation-form
        v-if="selectedFlat && selectedBuilding && action === 'fixation'"
        :initial-developer="selectedBuilding.developer || selectedFlat?.building?.developer"
        @submit="close"
      />
      <client-view-form
        v-if="selectedFlat && selectedBuilding && action === 'view'"
        @update:views="((view: ClientView) => close(view))"
      />
    </div>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import { useClientsStore } from '~/modules/clients/store'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { useAppStateStore } from '~/store/app'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import Index from '~/pages/market/index.vue'
import BuildingChessboard from '~/modules/building/components/building-chessboard/BuildingChessboard.vue'
import { BuildingFlatFilter } from '~/modules/building/components/page/flats/filter/types/BuildingFlatFilter'
import { ObjectHelper } from '~/common/helpers/object'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import { Building } from '~/modules/building/types/Building'
import ClientBookingForm from '~/modules/clients/components/forms/clients-booking/ClientBookingForm.vue'
import ClientFixationForm from '~/modules/clients/components/forms/clients-fixation/ClientFixationForm.vue'
import { ClientBooking } from '~/modules/clients/types/ClientBooking'
import { ClientFixation } from '~/modules/clients/types/ClientFixation'
import { ClientView } from '~/modules/clients/types/ClientView'
import ClientViewForm from '~/modules/clients/components/forms/client-view/ClientViewForm.vue'

const props = defineProps({
  action: {
    type: String as PropType<'fixation' | 'booking' | 'view'>,
    required: true,
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const store = useClientsStore()

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void,
  (e: 'update:entityData', value: ClientBooking | ClientFixation | ClientView): void}>()

const isModalVisible = computed({
  get() {
    return props.modelValue
  },
  set(newValue: boolean) {
    return emits('update:modelValue', newValue)
  },

})

const close = (entityData: ClientBooking | ClientFixation | ClientView) => {
  emits('update:entityData', entityData)
  store.selectedViewFlat = null
  isModalVisible.value = false
}

const marketStore = useMarketEntitiesActionsStore()

const filter = computed<BuildingFlatFilter>({
  get: () => ({
    flatFilter: marketStore.filter.flatFilter,
    houseFilter: {
      deadLine: marketStore.filter.houseFilter.deadLine,
    },
  }),
  set: value => {
    const copiedFilter = ObjectHelper.copy(marketStore.filter)
    copiedFilter.flatFilter = value.flatFilter
    copiedFilter.houseFilter.deadLine = value.houseFilter.deadLine

    marketStore.setFilter(copiedFilter)
  },
})

const selectedBuilding = ref<Building | null>(null)

const selectedFlat = computed(() => store.selectedViewFlat)

const chooseBuilding = (building: Building) => {
  selectedBuilding.value = building
  store.selectedViewBuilding = building
}

const closeModal = () => {
  isModalVisible.value = false
  selectedBuilding.value = null
  store.selectedViewFlat = null
  store.selectedViewBuilding = null
}

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const selectedClient = computed(() => store.selectedClient)

onBeforeUnmount(() => {
  store.setFilter(null)
  store.selectedClient = null
})
</script>

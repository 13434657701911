export const MATERIAL_TYPE = {
  aerocreteBlock: 'aerocreteBlock',
  block: 'block',
  boards: 'boards',
  brick: 'brick',
  foamConcreteBlock: 'foamConcreteBlock',
  gasSilicateBlock: 'gasSilicateBlock',
  monolith: 'monolith',
  monolithBrick: 'monolithBrick',
  old: 'old',
  panel: 'panel',
  stalin: 'stalin',
  wireframe: 'wireframe',
  wood: 'wood',
  log: 'log',
  timber: 'timber',
}

import { PushNotifications, Token, PushNotificationSchema } from '@capacitor/push-notifications'
import SentryLogger from '~/services/sentry/SentryLogger'
import { FirebaseMessaging, NotificationReceivedEvent } from '@capacitor-firebase/messaging'
import { Device } from '@capacitor/device'
import { Capacitor } from '@capacitor/core'
import { PLATFORMS } from '~/common/constants/platform/Platforms'
import QdStorage from '~/common/storage/QdStorage'
import { MobilePlatformStorage } from '~/common/storage/MobilePlatformStorage'
import { QdStorage as IQdStorage } from '~/common/storage/interfaces/QdStorage'
import { useUserStore } from '~/modules/user/UserModule'
import { PushNotificationsApiService } from '~/mobile-platforms/api/PushNotificationsApiService'
import { LocalNotifications, ScheduleOptions } from '@capacitor/local-notifications'

export class PushNotificationService {
  private userId: number
  private deviceId: string | null = null
  private storage: IQdStorage

  httpService: PushNotificationsApiService
  constructor() {
    this.userId = useUserStore().user.id
    this.storage = Capacitor.getPlatform() === PLATFORMS.web ? new QdStorage() : new MobilePlatformStorage()
    this.httpService = new PushNotificationsApiService(useNuxtApp().$qdHttpApiInstance)
  }

  async init() {
    await this.getDeviceId()
      .catch(error => {
        SentryLogger.captureMessage('Ошибка при получении уникального идентификатора устройства', { error, deviceId: this.deviceId })
      })
    this.setupListeners()

    await this.requestPermissions()

    await this.createHighPriorityChannel()
  }

  async createHighPriorityChannel() {
    await LocalNotifications.createChannel({
      id: 'high_priority',
      name: 'High Priority Notifications',
      importance: 5,
      description: 'For showing notifications in foreground',
      sound: 'default',
      visibility: 1,
      vibration: true,
    })
  }

  public async removeToken(): Promise<void> {
    await this.getDeviceId()
      .catch(error => {
        SentryLogger.captureMessage('Ошибка при получении уникального идентификатора устройства', { error, deviceId: this.deviceId })
      })

    if (this.deviceId) {
      await this.httpService.removeFirebaseToken(this.deviceId, this.userId)
        .then(() => {
          this.storage.removeItem('fireBaseToken')
        })
        .catch(error => {
          SentryLogger.captureMessage('Ошибка при удалении токена с сервера', { error, deviceId: this.deviceId })
        })
    } else {
      SentryLogger.captureMessage('Не удалось получить уникальный идентификатор устройства для удаления токена', { deviceId: this.deviceId })
    }
  }

  private async getDeviceId() {
    const deviceInfo = await Device.getId()
    this.deviceId = deviceInfo.identifier
  }

  private async requestPermissions() {
    const permissionsStatus = await (await PushNotifications.checkPermissions()).receive

    switch (permissionsStatus) {
      case 'granted':
        await PushNotifications.register()
        break
      case 'denied':
        console.warn('Необходимые права на получение уведомлений не предоставлены.')
        break
      case 'prompt':
      case 'prompt-with-rationale':
        PushNotifications.requestPermissions()
          .then(answer => {
            if (answer.receive === 'granted') {
              PushNotifications.register()
            }
          })

        break
      default:
        SentryLogger.captureMessage('Неизвестный статус разрешений на получение уведомлений', { permissionsStatus })
    }
  }

  private saveToken(token: string, deviceId: string): void {
    this.httpService.saveFirebaseToken(token, deviceId, this.userId)
      .then(() => {
        this.storage.setItem('fireBaseToken', token)
      })
      .catch(error => {
        SentryLogger.captureMessage('Ошибка при сохранении токена на сервере', { error, deviceId: this.deviceId, firebaseTokenType: typeof token })
      })
  }

  private handleNotificationManually(notificationEvent: NotificationReceivedEvent) {
    const { title, body } = notificationEvent.notification
    if (title && body) {
      const notification: ScheduleOptions = {
        notifications: [{
          id: Math.floor(new Date().getTime() / 1000),
          title,
          body,
          ongoing: false,
          channelId: 'high_priority',
          smallIcon: 'ic_notification',
          iconColor: '#6482e6',
        }],
      }

      return LocalNotifications.schedule(notification)
        .catch(error => {
          SentryLogger.captureMessage('Ошибка при отображении уведомления в handleNotificationManually', { error, notification: notificationEvent.notification })
        })
    }
    return Promise.reject(new Error('Notification title or body is missing'))
  }

  private setupListeners() {
    PushNotifications.addListener('registration', async(token: Token) => {
      const savedToken = await this.storage.getItem<string>('fireBaseToken')

      if (savedToken !== token.value && this.deviceId) {
        this.saveToken(token.value, this.deviceId)
      }
    })

    PushNotifications.addListener('registrationError', (error: any) => {
      SentryLogger.captureMessage('Ошибка при регистрации пуш-уведомлений', { error })
    })

    FirebaseMessaging.addListener('notificationActionPerformed', notification => {
      // TODO произвести обработку push-уведомлений
    })

    FirebaseMessaging.addListener('notificationReceived', async(notification: NotificationReceivedEvent) => {
      if (Capacitor.getPlatform() === PLATFORMS.android) {
        await this.handleNotificationManually(notification)
      }
    })
  }
}

<template>
  <h2 class="text-neutral-900 font-medium md:font-bold text-xl md:text-3xl w-auto max-w-[500px]">
    {{ entitiesCountView }}
  </h2>
</template>
<script setup lang="ts">
import { EntitiesCount } from '~/common/types/entitiesCount/EntitiesCount'
import { isEmpty } from '~/common/helpers/checkers'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { DISPLAYING_ENTITY } from '~/modules/market-entities-actions/constants/displaying/Displaying.entity'

const props = defineProps({
  count: {
    type: Object as PropType<EntitiesCount>,
    required: true,
  },
})

const actionsStore = useMarketEntitiesActionsStore()

const isLayoutEntity = computed(() => actionsStore.displayingEntity === DISPLAYING_ENTITY.layouts)

const { t } = useI18n()

const entitiesCountView = computed(() => {
  const { count } = props
  let text = ''

  if (isLayoutEntity.value && count.buildingsCount && isEmpty(count.layoutsCount)) {
    return `${t('entityActions.showCount.flats', { count: 0 })} ${t('entityActions.showCount.buildings', { count: count?.buildingsCount })}`
  }

  if (isLayoutEntity.value && (isEmpty(count.buildingsCount) || isEmpty(count.layoutsCount))) {
    return t('entityActions.showCount.buildings', { count: 0 })
  }

  if (count.buildingsCount && isEmpty(count.flatsCount)) {
    return `${t('entityActions.showCount.flats', { count: 0 })} ${t('entityActions.showCount.buildings', { count: count?.buildingsCount })}`
  }

  if (isEmpty(count.buildingsCount) || isEmpty(count.flatsCount)) {
    return t('entityActions.showCount.buildings', { count: 0 })
  }

  text += t('entityActions.showCount.flats', { count: isLayoutEntity.value ? count.layoutsCount : count.flatsCount })

  if (count.buildingsCount) {
    text += ` ${t('entityActions.showCount.buildings', { count: count.buildingsCount })}`
  }
  return text
})

</script>

<template>
  <modal-or-bottom-sheet
    v-model="isModalOpen"
    :modal-props="{
      centered: false,
      hideHeader: true,
      hideFooter: true,
      size: 'w-full lg:max-w-[60vw] xl:max-w-[800px]',
      withOutBodyOverflow: true
    }"
    :drawer-props="{fullHeight: true}"
  >
    <div
      class="flex flex-col md:flex-row w-full h-[calc(100vh_-_7rem)] md:h-full"
    >
      <div
        v-if="!isMd"
        class="flex justify-center gap-x-1"
      >
        <custom-button
          class="text-primary font-semibold py-2 px-3 rounded-none border-b-2"
          :class="activeMenu === 'search'? 'border-primary' : 'border-primary/0'"
          variant="withoutBorders"
          size="none"
          @click="activeMenu = 'search'"
        >
          {{ $t('suggestionsActions.client.search.title') }}
        </custom-button>
        <custom-button
          class="text-primary font-semibold py-2 px-3 rounded-none border-b-2"
          :class="activeMenu === 'create'? 'border-primary' : 'border-primary/0'"
          variant="withoutBorders"
          size="none"
          @click="activeMenu = 'create'"
        >
          {{ $t('suggestionsActions.client.create.title') }}
        </custom-button>
      </div>
      <clients-search
        v-if="isMd || activeMenu === 'search'"
        class="md:pr-6 md:w-[55%] overflow-y-hidden max-h-full md:max-h-[450px]"
        :clients="clients"
        :title="$t('suggestionsActions.client.search.title')"
        title-class="hidden md:block"
        @get-clients="getClients"
      >
        <template #leftActions="{client}">
          <custom-button-icon
            v-if="!isClientAlreadyAdded(client.id)"
            class="shrink-0 bg-white"
            color="secondary"
            round
            icon="common/circle-plus-regular"
            @click="!isClientAlreadyAdded(client.id) ? addClients(client.id) : undefined"
          />
          <div
            v-else
            class="shrink-0 w-[38px] h-[38px] bg-primary-dark rounded-full flex items-center justify-center"
          >
            <nuxt-icon
              name="common/check"
              class="text-white text-[12px]"
            />
          </div>
        </template>
      </clients-search>
      <client-add-form
        v-if="isMd || activeMenu === 'create'"
        class="h-full"
        @update:add="((e: Client) => clients.unshift(e))"
      >
        <template #closeButton>
          <custom-button
            class="!text-neutral-700 text-2xl hidden md:block"
            variant="withoutBorders"
            size="none"
            type="button"
            @click="isModalOpen = false"
          >
            <nuxt-icon
              name="actions/close"
            />
          </custom-button>
        </template>
      </client-add-form>
    </div>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { SuggestionsService, type ManagingSuggestion } from '~/modules/suggestions/SuggestionsModule'
import type { Client } from '~/modules/clients/types/Client'
import ClientAddForm from '~/modules/clients/components/forms/ClientAddForm.vue'
import { useAppStateStore } from '~/store/app'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import { ClientsService } from '~/modules/clients/services/ClientsService'
import ClientsSearch from '~/modules/clients/components/tables/clients/ClientsSearch.vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void,
(e: 'addClients', suggestionId: number, clients: Client[]): void
}>()

const suggestionService = new SuggestionsService()
const clientService = new ClientsService()
const { t } = useI18n()

const clients = ref<Client[]>([])
const activeMenu = ref<'create' | 'search'>('search')
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const isModalOpen = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const addClients = (clientId: number) => {
  suggestionService.addClientToSuggestion(props.suggestion.id, clientId)
    .then(updatedSuggestion => {
      emits('addClients', props.suggestion.id, updatedSuggestion.clients)
      toast(t('suggestionsActions.client.add.toast.success'), { variant: 'success' })
    })
}

const isClientAlreadyAdded = (clientId: number) => props.suggestion.clients?.some(c => c.id === clientId)

const getClients = (query?: string) => {
  clientService.getClients(query)
    .then(data => {
      clients.value = data
    })
}

onMounted(() => {
  getClients()
})
</script>

type Size = {
  width: number,
  height: number
}

export class Polylabel {
  public defineModule(): void {
    ymaps.modules.define('polylabel.config', [], _provide => {
      _provide({
        MIN_ZOOM: 0,
        MAX_ZOOM: 23,
        DEFAULT_POLYGON_FILL_COLOR: '0066ff99',
        options: ['labelLayout', 'labelDotLayout', 'labelDotVisible', 'labelDefaults', 'labelCursor', 'labelDotCursor'],
        zoomRangeOptions: ['labelClassName', 'labelForceVisible', 'labelTextColor', 'labelTextSize', 'labelCenterCoords', 'labelOffset', 'labelPermissibleInaccuracyOfVisibility'],
        properties: [],
      })
    })
    // # sourceMappingURL=config.js.map

    ymaps.modules.define('polylabel', ['polylabel.polylabel.PolylabelCollection', 'polylabel.polylabel.PolylabelObjectManager', 'ObjectManager'], (_provide, PCollection, PObjectManager, ObjectManager: typeof ymaps.ObjectManager) => {
      _provide((map: ymaps.Map, data: ymaps.ObjectManager | ymaps.GeoObjectCollection) => {
        initStyles()
        return data instanceof ObjectManager ? new PObjectManager(map, data) : new PCollection(map, data)
      })

      function initStyles() {
        const style = document.createElement('style')
        style.innerText = '\n        .ymaps-polylabel-dot-default {\n            border: 1px solid rgba(255, 255, 255, 0.8);\n            height: 4px;\n            width: 4px;\n            border-radius: 3px;\n        }\n        .ymaps-polylabel-dot-default_hover {\n            border: 1px solid rgba(255, 255, 255, 1);\n        }\n        .ymaps-polylabel-label-default {\n            font-family: Arial, Helvetica, sans-serif;\n            text-align: center;\n        }\n        .ymaps-polylabel-dark-label {\n            color: #06264f;\n            -webkit-font-smoothing: subpixel-antialiased;\n            text-shadow:\n            0 -1px 0 rgba( 255, 255, 255, 0.4 ),\n            0 -1px 0 rgba( 255, 255, 255, 0.4 ),\n            0 1px 0 rgba( 255, 255, 255, 0.4 ),\n            0 1px 0 rgba( 255, 255, 255, 0.4 ),\n            -1px 0 0 rgba( 255, 255, 255, 0.4 ),\n            1px 0 0 rgba( 255, 255, 255, 0.4 ),\n            -1px 0 0 rgba( 255, 255, 255, 0.4 ),\n            1px 0 0 rgba( 255, 255, 255, 0.4 ),\n            -1px -1px 0 rgba( 255, 255, 255, 0.4 ),\n            1px -1px 0 rgba( 255, 255, 255, 0.4 ),\n            -1px 1px 0 rgba( 255, 255, 255, 0.4 ),\n            1px 1px 0 rgba( 255, 255, 255, 0.4 ),\n            -1px -1px 0 rgba( 255, 255, 255, 0.4 ),\n            1px -1px 0 rgba( 255, 255, 255, 0.4 ),\n            -1px 1px 0 rgba( 255, 255, 255, 0.4 ),\n            1px 1px 0 rgba( 255, 255, 255, 0.4 );\n            opacity: 0.9;\n        }\n        .ymaps-polylabel-light-label {\n            color: rgba(255, 255, 255, 1);\n            text-shadow: 0 0 2px rgba(0, 0, 0, 1), 0 0 2px rgba(0, 0, 0, 1);\n        }\n    '.replace(/\n+\s+/gi, '')
        document.head.appendChild(style)
      }
    })
    // # sourceMappingURL=create.js.map

    ymaps.modules.define('polylabel.label.GeoObjectCollection.Label', ['util.defineClass', 'vow', 'util.extend', 'Placemark', 'polylabel.label.util.LabelPlacemarkOverlay', 'polylabel.label.util.layoutTemplates.getBaseLayoutTemplates', 'polylabel.label.LabelBase'], (_provide, _utilDefineClass, _vow, _utilExtend, Placemark, LabelPlacemarkOverlay, getBaseLayoutTemplates, LabelBase) => {
      function _classCallCheck(instance: any, Constructor: any) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      function _possibleConstructorReturn(self: any, call: any) {
        if (!self) {
          throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
        }

        return call && (typeof call === 'object' || typeof call === 'function') ? call : self
      }

      const Label = (function(_LabelBase) {
        _utilDefineClass(Label, _LabelBase)

        function Label(map: ymaps.Map, polygon: ymaps.Polygon, parentCollection, polylabel) {
          _classCallCheck(this, Label)
          const _this = _possibleConstructorReturn(this, _LabelBase.call(this))

          _this._map = map
          _this._polylabel = polylabel
          _this._polygon = polygon
          _this._parentCollection = parentCollection
          _this._placemark = {
            label: null,
            dot: null,
          }
          _this._layout = {
            label: null,
            dot: null,
          }
          _this._init()
          return _this
        }

        Label.prototype.removeFromCollection = function removeFromCollection() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this2 = this

          if (!this._parentCollection) {
            return
          }
          ['label', 'dot'].forEach(type => {
            if (_this2._parentCollection?.indexOf(_this2._placemark[type]) === -1) {
              return
            }
            _this2._parentCollection.remove(_this2._placemark[type])
          })
          this._polygon = null
          this._parentCollection = null
          this._placemark = null
          this._layout = null
          this._data = null
        }

        Label.prototype.addToCollection = function addToCollection() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this3 = this

          if (!this._parentCollection) {
            return _vow.Promise.reject()
          }
          const layouts = ['label', 'dot'].map(type => {
            if (!_this3._placemark[type].getParent()) {
              _this3._parentCollection.add(_this3._placemark[type])
            }

            return _this3.getLabelLayout(type).then(layout => {
              _this3.setLayout(type, layout)
            })
          })
          return _vow.Promise.all(layouts)
        }

        Label.prototype.getLabelLayout = function getLabelLayout(type: string) {
          return this._placemark[type].getOverlay().then(overlay => overlay.getLayout())
        }

        Label.prototype._init = function _init() {
          this._baseLayoutTemplates = getBaseLayoutTemplates()
        }

        Label.prototype.createPlacemarks = function createPlacemarks() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this4 = this;

          ['label', 'dot'].forEach(type => {
            _this4._placemark[type] = Label._createPlacemark({
              properties: _utilExtend({}, {
                polygon: _this4._polygon,
              }, _this4._polygon.properties.getAll()),
              options: _this4.getFormedOptionsForPlacemark(type),
            }, _this4._baseLayoutTemplates[type], _this4._data.getCenterCoords(_this4._map.getZoom()))
          })
        }

        Label._createPlacemark = function _createPlacemark(params, layout, coords) {
          const options = _utilExtend({}, {
            iconLayout: layout,
            pointOverlay: LabelPlacemarkOverlay,
            iconLabelPosition: 'absolute',
          }, params.options)
          return new Placemark(coords, params.properties, options)
        }

        Label.prototype.setDataByZoom = function setDataByZoom(zoom: number, visibleState: string) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this5 = this;

          ['dot', 'label'].forEach(type => {
            if (type === 'label') {
              const styles = _this5._data.getStyles(zoom)
              _this5.setStyles({
                className: styles.className,
                textSize: styles.textSize,
                textColor: styles.textColor,
              })
            }
            _this5.setVisibilityForce('none')
            _this5._data.setVisible(zoom, type, _this5._layout[type])
          })

          const currentVisibleType = this.setVisibility(visibleState, this._data.getVisibility(zoom), this._data.getData('dotVisible'))

          const currentCenter = this._data.getCenterCoords(zoom)
          if (['label', 'dot'].includes(currentVisibleType) && this._data.getSize(zoom, currentVisibleType)) {
            this.setCoordinates(currentCenter)
            this.setOffsetAndIconShape(currentVisibleType, this._data.getSize(zoom, currentVisibleType), this._data.getOffset(zoom))
          }

          return {
            currentVisibleType,
            currentConfiguredVisibileType: this._data.getVisibility(zoom),
            currentCenter,
          }
        }

        Label.prototype.setLayoutTemplate = function setLayoutTemplate() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this6 = this

          this.createLayoutTemplates()
          Object.keys(this._layoutTemplates).forEach(type => {
            _this6._placemark[type].options.set(_this6._layoutTemplates[type])
          })
        }

        Label.prototype.setNewOptions = function setNewOptions(newOptions) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this7 = this;

          ['dot', 'label'].forEach(type => {
            _this7._placemark[type].options.set(newOptions)
          })
        }

        Label.prototype.setCoordinates = function setCoordinates(coords) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this8 = this

          if (coords.toString() !== this._placemark.label.geometry.getCoordinates().toString()) {
            ['dot', 'label'].forEach(type => {
              _this8._placemark[type].geometry.setCoordinates(coords)
            })
          }
        }

        Label.prototype.setVisibilityForce = function setVisibilityForce(visibleType: string) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this9 = this

          Object.keys(this._placemark).forEach(type => {
            const pane = _this9.analysePane(type, visibleType)
            if (_this9._placemark[type].options.get('pane') !== pane) {
              _this9._placemark[type].options.set({ pane })
            }
          })
        }

        Label.prototype.setStyles = function setStyles(data: any) {
          const styles = this.analyseStyles(data)
          if (styles.isChange) {
            this._placemark.label.options.set(styles.styles)
          }
        }

        Label.prototype.setOffsetAndIconShape = function setOffsetAndIconShape(type: string, size: Size, offset: Array<number>) {
          const offsetResult = this.analyseOffset(size, offset)
          this._placemark[type].options.set({
            iconShape: this.analyseShape(type, size, offset),
            iconLabelLeft: offsetResult.left,
            iconLabelTop: offsetResult.top,
          })
        }

        Label.prototype.destroy = function destroy() {
          this.removeFromCollection()
        }

        return Label
      }(LabelBase))

      _provide(Label)
    })
    // # sourceMappingURL=Label.js.map

    ymaps.modules.define('polylabel.label.LabelBase', ['vow', 'util.extend', 'polylabel.label.util.layoutTemplates.createLayoutTemplates', 'polylabel.label.util.LabelData', 'polylabel.label.util.classHelper', 'polylabel.label.util.getLayoutSize'], (_provide, _vow, _utilExtend, _createLayoutTemplates, LabelData, classHelper, getLayoutSize) => {
      function _classCallCheck(instance: any, Constructor: any) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      // eslint-disable-next-line func-names
      const LabelBase = (function() {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function LabelBase() {
          // @ts-ignore
          _classCallCheck(this, LabelBase)
          // @ts-ignore
          this._baseLayoutTemplates = null
          // @ts-ignore
          this._layoutTemplates = null
        }

        LabelBase.prototype.getPolylabelType = function getPolylabelType() {
          return this._polylabel.getPolylabelType()
        }

        LabelBase.prototype.getPlacemark = function getPlacemark(type: string) {
          return this._placemark[type]
        }

        LabelBase.prototype.getLayout = function getLayout(type: string) {
          return this._layout[type]
        }

        LabelBase.prototype.setLayout = function setLayout(type: string, layout: any) {
          this._layout[type] = layout
          this._initLayoutSizeChangeHandler(layout)
        }

        LabelBase.prototype.updateLayouts = function updateLayouts() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this = this

          this.getLabelLayout('label').then((layout: any) => {
            _this.setLayout('label', layout)
          })
          this.getLabelLayout('dot').then((layout: any) => {
            _this.setLayout('dot', layout)
          })
        }

        LabelBase.prototype._initLayoutSizeChangeHandler = function _initLayoutSizeChangeHandler(layout) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this2 = this

          const el = layout.getElement()
          if (!el) { return }
          const imgs = Array.prototype.slice.call(el.getElementsByTagName('img'))
          if (imgs.length > 0) {
            const imagesLoaded = _vow.Promise.all(imgs.map(img => {
              if (img.complete) {
                return _vow.Promise.resolve()
              }
              // eslint-disable-next-line no-return-assign
              return new _vow.Promise((resolve: () => void) => img.onload = resolve)
            }))
            imagesLoaded.then(() => {
              const size = getLayoutSize(layout)
              if (!size) { return }
              if (size.width > 0 && size.height > 0) {
                _this2._polylabel._setLabelData(_this2._polygon, _this2, undefined, ['dot', 'label'])
              }
            })
          }
        }

        LabelBase.prototype.getPolygonOptions = function getPolygonOptions() {
          if (this.getPolylabelType() === 'collection') {
            return this._polygon.options.getAll()
          }
          return this._polygon.options
        }

        LabelBase.prototype.getLabelOptions = function getLabelOptions() {
          const { label } = this._placemark
          return this.getPolylabelType() === 'collection' ? label.options.getAll() : label.options
        }

        LabelBase.prototype.getFormedOptionsForPlacemark = function getFormedOptionsForPlacemark(type: string) {
          const cursors = this._data.getLabelCursors()
          return _utilExtend({}, this.getPolygonOptions(), this._layoutTemplates[type], cursors[type])
        }

        LabelBase.prototype.createLayoutTemplates = function createLayoutTemplates() {
          const polygonOptions = this.getPolygonOptions()
          const label = polygonOptions.labelLayout
          const dot = polygonOptions.labelDotLayout

          if (this._prevLayout && this._prevLayout.dot === dot && this._prevLayout.label === label) {
            return
          }

          this._layoutTemplates = _createLayoutTemplates(label, dot)

          this._prevLayout = {
            dot,
            label,
          }
        }

        LabelBase.prototype.analyseVisibility = function analyseVisibility(visibleState: string, visible: string, dotVisible: string) {
          let currState = visibleState && visibleState !== 'auto' ? visibleState : visible
          if (currState === 'dot' && !dotVisible) { currState = 'none' }
          return currState
        }

        LabelBase.prototype.setVisibility = function setVisibility(visibleState: string, visible: string, dotVisible: string) {
          const currState: string = this.analyseVisibility(visibleState, visible, dotVisible)
          this.setVisibilityForce(currState)
          return currState
        }

        LabelBase.prototype.createLabelData = function createLabelData(options, zoomRangeOptions) {
          this._data = new LabelData(this._polygon, options, zoomRangeOptions, this._map, this)
          return this._data
        }

        LabelBase.prototype.getLabelData = function getLabelData() {
          return this._data
        }

        LabelBase.prototype.analysePane = function analysePane(type: string, visibleType: string) {
          return type === visibleType ? 'places' : 'phantom'
        }

        LabelBase.prototype.analyseOffset = function analyseOffset(size: Size, offset: Array<number>) {
          const h = size.height / 2
          const w = size.width / 2

          return {
            left: -w + offset[0],
            top: -h + offset[1],
          }
        }

        LabelBase.prototype.analyseStyles = function analyseStyles(data: any) {
          const labelOptions = this.getLabelOptions()
          let isChange = false
          Object.keys(data).forEach(key => {
            if (labelOptions[`iconLabel${key[0].toUpperCase()}${key.slice(1)}`] !== data[key]) {
              isChange = true
            }
          })
          return {
            isChange,
            styles: {
              iconLabelClassName: data.className,
              iconLabelTextSize: data.textSize,
              iconLabelTextColor: data.textColor,
            },
          }
        }

        LabelBase.prototype.analyseShape = function analyseShape(type: string, size: Size, offset: Array<number>) {
          const h = size.height / 2
          const w = size.width / 2
          const dotDefaultShape = type === 'dot' && this._data.isDotDefault ? 2 : 0

          return {
            type: 'Rectangle',
            coordinates: [[-w + offset[0] - dotDefaultShape, -h + offset[1] - dotDefaultShape], [w + offset[0] + dotDefaultShape, h + offset[1] + dotDefaultShape]],
          }
        }

        LabelBase.prototype.addDotClass = function addDotClass(className: string) {
          classHelper.add(this._layout.dot, 'ymaps-polylabel-dot-default', className)
        }

        LabelBase.prototype.removeDotClass = function removeDotClass(className: string) {
          classHelper.remove(this._layout.dot, 'ymaps-polylabel-dot-default', className)
        }

        return LabelBase
      }())

      _provide(LabelBase)
    })
    // # sourceMappingURL=LabelBase.js.map

    ymaps.modules.define('polylabel.label.ObjectManager.Label', ['util.defineClass', 'vow', 'util.extend', 'polylabel.label.util.LabelPlacemarkOverlay', 'polylabel.label.util.layoutTemplates.getBaseLayoutTemplates', 'polylabel.label.LabelBase'], (_provide, _utilDefineClass, _vow, _utilExtend, LabelPlacemarkOverlay, getBaseLayoutTemplates, LabelBase) => {
      function _classCallCheck(instance: any, Constructor: any) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      function _possibleConstructorReturn(self: any, call: any) {
        if (!self) {
          throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
        }

        return call && (typeof call === 'object' || typeof call === 'function') ? call : self
      }
      // eslint-disable-next-line func-names
      const Label = (function(_LabelBase) {
        // @ts-ignore

        _utilDefineClass(Label, _LabelBase)
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function Label(map: ymaps.Map, polygon, objectManager, polylabel) {
          // @ts-ignore
          _classCallCheck(this, Label)
          // @ts-ignore
          const _this = _possibleConstructorReturn(this, _LabelBase.call(this))

          _this._map = map
          _this._polylabel = polylabel
          _this._polygon = polygon
          _this._objectManager = objectManager
          _this._placemark = {
            label: null,
            dot: null,
          }
          _this._layout = {
            label: null,
            dot: null,
          }
          _this._init()
          return _this
        }

        Label.prototype.destroy = function destroy() {
          this.removeFromObjectManager()
        }

        Label.prototype.addToObjectManager = function addToObjectManager() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this2 = this

          this.setLayoutTemplate();
          ['label', 'dot'].forEach(type => {
            _this2._objectManager.add(_this2._placemark[type])
          })
        }

        Label.prototype.removeFromObjectManager = function removeFromObjectManager() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this3 = this;

          ['label', 'dot'].forEach(type => {
            _this3._objectManager.remove(_this3._placemark[type])
          })
          this._polygon = null
          this._objectManager = null
          this._placemark = null
          this._layout = null
          this._data = null
        }

        Label.prototype._init = function _init() {
          this._baseLayoutTemplates = getBaseLayoutTemplates()
        }

        Label.prototype.getLabelLayout = function getLabelLayout(type: string) {
          const overlay = this._objectManager.objects.overlays.getById(this._placemark[type].id)
          if (!overlay) { return _vow.Promise.reject() }
          return overlay.getLayout()
        }

        Label.prototype.createPlacemarks = function createPlacemarks() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this4 = this;

          ['label', 'dot'].forEach(type => {
            _this4._placemark[type] = Label._createPlacemark(`${type}#${_this4._polygon.id}`, {
              properties: _utilExtend({}, {
                polygon: _this4._polygon,
              }, _this4._polygon.properties),
              options: _this4.getFormedOptionsForPlacemark(type),
            }, _this4._baseLayoutTemplates[type], _this4._data.getCenterCoords(_this4._map.getZoom()))
          })
        }

        Label._createPlacemark = function _createPlacemark(id: string, params: any, layout: any, coordinates) {
          const options = _utilExtend({}, {
            iconLayout: layout,
            iconLabelPosition: 'absolute',
            overlay: LabelPlacemarkOverlay,
            pane: 'phantom',
          }, params.options)
          return {
            type: 'Feature',
            id,
            options,
            properties: params.properties,
            geometry: {
              type: 'Point',
              coordinates,
            },
          }
        }

        Label.prototype._updateOptions = function _updateOptions(id: string, options: any) {
          this._objectManager.objects.setObjectOptions(id, options)
        }

        Label.prototype.setLayoutTemplate = function setLayoutTemplate() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this5 = this

          Object.keys(this._layoutTemplates).forEach(type => {
            _this5._updateOptions(_this5._placemark[type].id, _this5._layoutTemplates[type])
          })
        }

        Label.prototype.updateOptions = function updateOptions() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this6 = this;

          ['dot', 'label'].forEach(type => {
            _utilExtend(_this6._placemark[type].options, _this6.getFormedOptionsForPlacemark(type))
            _this6._updateOptions(_this6._placemark[type].id, _this6._placemark[type].options)
          })
        }

        Label.prototype.setDataByZoom = function setDataByZoom(zoom: number, types: Array<string>, visibleState: any) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this7 = this

          types.forEach(type => {
            if (type === 'label') {
              const styles = _this7._data.getStyles(zoom)
              _this7.setStyles({
                className: styles.className,
                textSize: styles.textSize,
                textColor: styles.textColor,
              })
            }
            _this7.setVisibilityForce('none')
            _this7._data.setVisible(zoom, type, _this7._layout[type])
          })

          const currentVisibleType = this.setVisibility(visibleState, this._data.getVisibility(zoom), this._data.getData('dotVisible'))

          const currentCenter = this._data.getCenterCoords(zoom)
          if (['label', 'dot'].includes(currentVisibleType) && this._data.getSize(zoom, currentVisibleType)) {
            this.setCoordinates(currentCenter)
            this.setOffsetAndIconShape(currentVisibleType, this._data.getSize(zoom, currentVisibleType), this._data.getOffset(zoom))
          }

          return {
            currentVisibleType,
            currentConfiguredVisibileType: this._data.getVisibility(zoom),
            currentCenter,
          }
        }

        Label.prototype.setOffsetAndIconShape = function setOffsetAndIconShape(type: string, size: Size, offset: Array<number>) {
          const offsetResult = this.analyseOffset(size, offset)
          this._updateOptions(this._placemark[type].id, {
            iconShape: this.analyseShape(type, size, offset),
            iconLabelLeft: offsetResult.left,
            iconLabelTop: offsetResult.top,
          })
        }

        Label.prototype.setCoordinates = function setCoordinates(coords) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this8 = this

          if (coords.toString() !== this._placemark.label.geometry.coordinates.toString()) {
            ['dot', 'label'].forEach(type => {
              _this8._objectManager.remove(_this8._placemark[type])
              _this8._generateNewPlacemark(type)
              _this8._placemark[type].geometry.coordinates = coords
              _this8._objectManager.add(_this8._placemark[type])
            })
          }
        }

        Label.prototype.setStyles = function setStyles(data) {
          const styles = this.analyseStyles(data)
          if (styles.isChange) {
            this._updateOptions(this._placemark.label.id, styles.styles)
          }
        }

        Label.prototype.setVisibilityForce = function setVisibilityForce(visibleType: string) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this9 = this

          Object.keys(this._placemark).forEach(type => {
            const pane = _this9.analysePane(type, visibleType)
            const label = _this9._objectManager.objects.getById(_this9._placemark[type].id)
            if (label && label.options.pane !== pane) {
              _this9._updateOptions(_this9._placemark[type].id, { pane })
            }
          })
        }

        Label.prototype._generateNewPlacemark = function _generateNewPlacemark(type: string) {
          this._placemark[type] = _utilExtend({}, this._placemark[type])
          const { id } = this._placemark[type]
          this._placemark[type].id = id[0] === '_' ? id.slice(1) : `_${id}`
        }

        return Label
      }(LabelBase))

      _provide(Label)
    })
    // # sourceMappingURL=Label.js.map

    ymaps.modules.define('polylabel.label.util.classHelper', [], _provide => {
      /**
       * @param {*} layout
       * @param {*} className - class by which we find the element
       * @param {*} newClassName - new class
       */
      function add(layout, className, newClassName) {
        const el = getElemByClass(layout, className)
        if (!el) { return }

        el.classList.add(newClassName)
      }

      /**
       * @param {*} layout
       * @param {*} className - class by which we find the element
       * @param {*} removeClassName - class to remove
       */
      function remove(layout, className: string, removeClassName: string) {
        const el = getElemByClass(layout, className)
        if (!el) { return }

        el.classList.remove(removeClassName)
      }

      function getElemByClass(layout, className) {
        if (!layout) { return }
        const el = layout.getElement()
        if (!el) { return }

        // eslint-disable-next-line consistent-return
        return el.getElementsByClassName(className)[0]
      }

      _provide({
        add,
        remove,
      })
    })
    // # sourceMappingURL=classHelper.js.map

    ymaps.modules.define('polylabel.label.util.getLayoutSize', [], _provide => {
      _provide(layout => {
        let el: Element = layout && layout.getElement()
        if (!el) { return }

        // eslint-disable-next-line prefer-destructuring
        el = el.getElementsByClassName('ymaps-polylabel-view')[0]
        if (!el) { return }

        const _el$getBoundingClient = el.getBoundingClientRect()
        const { width } = _el$getBoundingClient
        const { height } = _el$getBoundingClient

        // eslint-disable-next-line consistent-return
        return { width, height }
      })
    })
    // # sourceMappingURL=getLayoutSize.js.map

    ymaps.modules.define('polylabel.label.util.LabelData', ['polylabel.config', 'polylabel.util.zoom.parseZoomData', 'polylabel.util.getPolesOfInaccessibility', 'polylabel.util.zoom.setZoomVisibility', 'polylabel.util.transformPolygonCoords', 'polylabel.util.getPolygonWithMaxArea', 'polylabel.util.transformHexToRGB'], (_provide, CONFIG, parseZoomData, getPolylabelCenter, setZoomVisibility, transformPolygonCoords, getPolygonWithMaxArea, transformHexToRGB) => {
      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      const { MIN_ZOOM } = CONFIG
      const { MAX_ZOOM } = CONFIG
      const { DEFAULT_POLYGON_FILL_COLOR } = CONFIG

      const LABEL_CLASS_DEFAULT = 'ymaps-polylabel-label-default'
      const LABEL_CLASS_LIGHT = 'ymaps-polylabel-light-label'
      const LABEL_CLASS_DARK = 'ymaps-polylabel-dark-label'
      const LABEL_SIZE_DEFAULT = parseZoomData({ '1_6': 12, '7_18': 14 })
      // eslint-disable-next-line func-names
      const LabelData = (function() {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function LabelData(polygon, options, zoomRangeOptions, map: ymaps.Map, label) {
          // @ts-ignore
          _classCallCheck(this, LabelData)

          // @ts-ignore
          this._map = map
          // @ts-ignore
          this._label = label
          // @ts-ignore
          this._polygon = polygon
          // @ts-ignore
          this._data = {
            zoomInfo: {}, // Object with information for each zoom
            autoCenter: [0, 0],
            dotVisible: typeof options.labelDotVisible !== 'boolean' ? true : options.labelDotVisible,
          }
          // @ts-ignore
          this.parsedOptions = LabelData._parseOptions(zoomRangeOptions)
          // @ts-ignore
          this._polygonCoordsWithMaxArea = transformPolygonCoords.polygon(getPolygonWithMaxArea(this.getPolygonCoords()))
          // @ts-ignore
          this.updateDotDefaultFlag()
          // @ts-ignore
          this._init()
        }

        LabelData.prototype.setData = function setData(key, val) {
          this._data[key] = val
        }

        LabelData.prototype.getData = function getData(key) {
          return this._data[key]
        }

        LabelData.prototype.setZoomInfo = function setZoomInfo(zoom: number, key, value) {
          zoom = LabelData.zoomRound(zoom)
          this._data.zoomInfo[zoom][key] = value
        }

        LabelData.prototype.getZoomInfo = function getZoomInfo(zoom: number) {
          zoom = LabelData.zoomRound(zoom)
          // eslint-disable-next-line no-mixed-operators
          if (zoom || typeof zoom === 'number' && zoom === 0) {
            return this._data.zoomInfo[zoom]
          }
          return this._data.zoomInfo
        }

        LabelData.prototype.getPolygonCoords = function getPolygonCoords() {
          return this._label.getPolylabelType() === 'collection' ? this._polygon.geometry.getCoordinates() : this._polygon.geometry.coordinates
        }

        LabelData.prototype.getLabelCursors = function getLabelCursors() {
          const DEFAULT = 'grab'
          const result = {
            dot: {},
            label: {},
          }
          if (this._label.getPolylabelType() === 'collection') {
            // @ts-ignore
            result.label.cursor = this._polygon.options.get('labelCursor') || DEFAULT
            // @ts-ignore
            result.dot.cursor = this._polygon.options.get('labelDotCursor') || DEFAULT
          } else {
            // @ts-ignore
            result.label.cursor = this._polygon.options.labelCursor || DEFAULT
            // @ts-ignore
            result.dot.cursor = this._polygon.options.labelDotCursor || DEFAULT
          }
          return result
        }

        LabelData.prototype.getLabelDefaults = function getLabelDefaults(zoom: number) {
          const defaults = this._label.getPolylabelType() === 'collection' ? this._polygon.options.get('labelDefaults') : this._polygon.options.labelDefaults
          if (!defaults) { return }
          // eslint-disable-next-line consistent-return
          return {
            className: defaults === 'dark' ? `${LABEL_CLASS_DEFAULT} ${LABEL_CLASS_DARK}` : `${LABEL_CLASS_DEFAULT} ${LABEL_CLASS_LIGHT}`,
            textSize: LABEL_SIZE_DEFAULT[zoom],
          }
        }

        LabelData.prototype.getCenterCoords = function getCenterCoords(zoom: number) {
          zoom = LabelData.zoomRound(zoom)
          // eslint-disable-next-line no-mixed-operators
          return this.parsedOptions.labelCenterCoords && this.parsedOptions.labelCenterCoords[zoom] || this._data.autoCenter
        }

        LabelData.prototype.getStyles = function getStyles(zoom: number) {
          zoom = LabelData.zoomRound(zoom)

          const _ref = this.getLabelDefaults(zoom) || {}
          const defaultClassName = _ref.className
          const defaultTextSize = _ref.textSize

          return {
            // eslint-disable-next-line no-mixed-operators
            className: this.parsedOptions.labelClassName && this.parsedOptions.labelClassName[zoom] || defaultClassName,
            // eslint-disable-next-line no-mixed-operators
            textSize: this.parsedOptions.labelTextSize && this.parsedOptions.labelTextSize[zoom] || defaultTextSize,
            textColor: this.parsedOptions.labelTextColor && this.parsedOptions.labelTextColor[zoom],
          }
        }

        LabelData.prototype.getPolygonFillColor = function getPolygonFillColor() {
          const color = this._label.getPolylabelType() === 'collection' ? this._polygon.options.get('fillColor') : this._polygon.options.fillColor
          return color || DEFAULT_POLYGON_FILL_COLOR
        }

        LabelData.prototype.getDotColorByPolygonColor = function getDotColorByPolygonColor() {
          let color = this.getPolygonFillColor()
          const checkColor = transformHexToRGB(color, 0.9)
          if (checkColor) { color = checkColor }
          return color
        }

        LabelData.prototype.getVisibility = function getVisibility(zoom: number) {
          zoom = LabelData.zoomRound(zoom)
          // eslint-disable-next-line no-mixed-operators
          return this.parsedOptions.labelForceVisible && this.parsedOptions.labelForceVisible[zoom] || this._data.zoomInfo[zoom].visible
        }

        LabelData.prototype.getOffset = function getOffset(zoom: number) {
          zoom = LabelData.zoomRound(zoom)
          // eslint-disable-next-line no-mixed-operators
          return this.parsedOptions.labelOffset && this.parsedOptions.labelOffset[zoom] || [0, 0]
        }

        LabelData.prototype.updateDotDefaultFlag = function updateDotDefaultFlag() {
          this.isDotDefault = !this._label.getPolygonOptions().labelDotLayout
        }

        LabelData.prototype.getPermissibleInaccuracyOfVisibility = function getPermissibleInaccuracyOfVisibility(zoom: number) {
          zoom = LabelData.zoomRound(zoom)
          // eslint-disable-next-line no-mixed-operators
          return this.parsedOptions.labelPermissibleInaccuracyOfVisibility && this.parsedOptions.labelPermissibleInaccuracyOfVisibility[zoom] || 0
        }

        LabelData.prototype.getSize = function getSize(zoom: number, type: string) {
          zoom = LabelData.zoomRound(zoom)
          return this._data.zoomInfo[zoom][`${type}Size`]
        }

        LabelData.prototype.setSize = function setSize(zoom: number, type: string, size: Size) {
          zoom = LabelData.zoomRound(zoom)
          if (size.height > 0 && size.width > 0) {
            this._data.zoomInfo[zoom][`${type}Size`] = size
          }
        }

        LabelData.zoomRound = function zoomRound(z) {
          return Math.round(z)
        }

        LabelData.prototype.setVisible = function setVisible(zoom: number, type: string, layout) {
          zoom = LabelData.zoomRound(zoom)
          if (this.getSize(zoom, type)) { return }
          const zoomData = setZoomVisibility(type, this._map, zoom, this._data.zoomInfo[zoom].visible, layout, transformPolygonCoords.point(this.getCenterCoords(zoom), this._polygonCoordsWithMaxArea.isPositivePart), this._polygonCoordsWithMaxArea.coords, this.getOffset(zoom), this.getPermissibleInaccuracyOfVisibility(zoom))
          if (!zoomData) { return }

          this._data.zoomInfo[zoom].visible = zoomData.visible
          this.setSize(zoom, type, zoomData.size)
        }

        LabelData.prototype._checkParams = function _checkParams() {
          if (!this._label.getPolygonOptions().labelLayout) {
            const errText = 'Не указан шаблон для подписи (labelLayout)'
            throw new Error(errText)
          }
        }

        LabelData.prototype._init = function _init() {
          this._checkParams()
          const autoCenter = getPolylabelCenter(this._polygonCoordsWithMaxArea.coords, 1.0)
          this._data.autoCenter = autoCenter

          for (let z = MIN_ZOOM; z <= MAX_ZOOM; z += 1) {
            // @ts-ignore
            this._data.zoomInfo[z] = LabelData._createDefaultZoomInfo(z)
          }
        }

        LabelData._parseOptions = function _parseOptions(options) {
          const result = {}
          Object.keys(options).forEach(key => {
            result[key] = parseZoomData(options[key])
          })
          return result
        }

        LabelData._createDefaultZoomInfo = function _createDefaultZoomInfo() {
          return {
            visible: 'none', // label | dot | none,
            dotSize: undefined,
            labelSize: undefined,
          }
        }

        return LabelData
      }())

      _provide(LabelData)
    })
    // # sourceMappingURL=LabelData.js.map

    ymaps.modules.define('polylabel.label.util.LabelPlacemarkOverlay', ['util.defineClass', 'overlay.Placemark', 'GeoObject'], (_provide, _utilDefineClass, overlayPlacemark, GeoObject) => {
      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      function _possibleConstructorReturn(self, call) {
        if (!self) {
          throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
        }

        return call && (typeof call === 'object' || typeof call === 'function') ? call : self
      }
      // eslint-disable-next-line func-names
      const LabelPlacemarkOverlay = (function(_overlayPlacemark) {
        _utilDefineClass(LabelPlacemarkOverlay, _overlayPlacemark)

        // eslint-disable-next-line @typescript-eslint/no-shadow
        function LabelPlacemarkOverlay(geometry, properties, options) {
          // @ts-ignore
          _classCallCheck(this, LabelPlacemarkOverlay)
          // @ts-ignore
          return _possibleConstructorReturn(this, _overlayPlacemark.call(this, geometry, properties, options))
        }

        LabelPlacemarkOverlay.prototype.getData = function getData() {
          const polygon = this._data.geoObject instanceof GeoObject ? this._data.geoObject.properties.get('polygon') : this._data.properties.polygon

          return {
            geoObject: polygon,
            properties: polygon.properties,
            // options: polygon.options, TODO невозможно переопределить опции, потому что https://github.yandex-team.ru/mapsapi/jsapi-v2/blob/master/src/overlay/view/abstract/baseWithLayout/overlay.view.BaseWithLayout.js#L99
            state: polygon.state,
          }
        }

        return LabelPlacemarkOverlay
      }(overlayPlacemark))

      _provide(LabelPlacemarkOverlay)
    })
    // # sourceMappingURL=LabelPlacemarkOverlay.js.map

    ymaps.modules.define('polylabel.label.util.layoutTemplates.createLayoutTemplates', ['templateLayoutFactory'], (_provide, templateLayoutFactory) => {
      _provide((labelLayout, labelDotLayout) => {
        const dotDefault = '<div {% style %}\n        background-color: {{geoObject.options.fillColor|dot-color}};{% endstyle %}\n        class="ymaps-polylabel-dot-default"></div>'
        return {
          label: {
            iconLabelTemplateLayout: templateLayoutFactory.createClass(labelLayout),
          },
          dot: {
            iconLabelDotTemplateLayout: templateLayoutFactory.createClass(labelDotLayout || dotDefault),
          },
        }
      })
    })
    // # sourceMappingURL=createLayoutTemplates.js.map

    ymaps.modules.define('polylabel.label.util.layoutTemplates.dotLayoutTemplate', ['templateLayoutFactory'], (_provide, templateLayoutFactory) => {
      const template = templateLayoutFactory.createClass('<div class="ymaps-polylabel-view" {% style %}position: {{options.labelPosition}};\n        top: {{options.labelTop}}px; left: {{options.labelLeft}}px; {% endstyle %}>\n            {% include options.labelDotTemplateLayout %}\n    </div>')

      _provide(template)
    })
    // # sourceMappingURL=dotLayoutTemplate.js.map

    ymaps.modules.define('polylabel.label.util.layoutTemplates.getBaseLayoutTemplates', ['polylabel.label.util.layoutTemplates.labelLayoutTemplate', 'polylabel.label.util.layoutTemplates.dotLayoutTemplate'], (_provide, labelLayoutTemplate, dotLayoutTemplate) => {
      _provide(() => ({
        label: labelLayoutTemplate,
        dot: dotLayoutTemplate,
      }))
    })
    // # sourceMappingURL=getBaseLayoutTemplates.js.map

    ymaps.modules.define('polylabel.label.util.layoutTemplates.labelLayoutTemplate', ['templateLayoutFactory'], (_provide, templateLayoutFactory) => {
      const template = templateLayoutFactory.createClass('\n    <div class="ymaps-polylabel-view" {% style %}position: {{options.labelPosition}};\n        top: {{options.labelTop}}px; left: {{options.labelLeft}}px; {% endstyle %}>\n        <div class="{{options.labelClassName}}"\n            {% style %}font-size: {{options.labelTextSize}}px;\n            color: {{options.labelTextColor}}; {% endstyle %}>\n                {% include options.labelTemplateLayout %}\n        </div>\n    </div>')

      _provide(template)
    })
    // # sourceMappingURL=labelLayoutTemplate.js.map

    ymaps.modules.define('polylabel.polylabel.PolylabelBase', ['template.filtersStorage', 'polylabel.config', 'polylabel.util.templateFilterStorage.dotColor'], (_provide, templateFiltersStorage, CONFIG, dotColorFilterStorage) => {
      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }
      // eslint-disable-next-line func-names
      const PolylabelBased = (function() {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function PolylabelBased(map) {
          // @ts-ignore
          _classCallCheck(this, PolylabelBased)
          // @ts-ignore
          this._map = map
          templateFiltersStorage.add('dot-color', dotColorFilterStorage)
        }

        PolylabelBased.prototype.getPolylabelType = function getPolylabelType() {
          return this._polylabelType
        }

        PolylabelBased.prototype.initMapListeners = function initMapListeners(callback) {
          this._mapCallback = callback
          this._map.events.add(['boundschange', 'actionbegin', 'actionend'], this._mapEventsHandler, this)
        }

        PolylabelBased.prototype.destroyMapListeners = function destroyMapListeners() {
          this._map.events.remove(['boundschange', 'actionbegin', 'actionend'], this._mapEventsHandler, this)
        }

        PolylabelBased.prototype._mapEventsHandler = function _mapEventsHandler(event) {
          switch (event.get('type')) {
            case 'actionbegin':
            {
              const { action } = event.originalEvent
              action.events.add('tick', this._actionTickHandler, this)
              break
            }
            case 'actionend':
            {
              const _action = event.originalEvent.action
              _action.events.remove('tick', this._actionTickHandler, this)

              if (this._zoomed) {
                this._mapCallback('actionendzoomchange')
                this._zoomed = false
              }
              break
            }
          }
        }

        PolylabelBased.prototype._actionTickHandler = function _actionTickHandler(event) {
          const tick = event.get('tick')
          if (tick.zoom !== this._map.getZoom()) {
            if (!this._zoomed) { this._mapCallback('actionbeginzoomchange') }
            this._zoomed = true
          }
        }

        PolylabelBased.prototype.getOptions = function getOptions(polygon) {
          if (this.getPolylabelType() === 'collection') {
            return polygon.options.getAll()
          }
          return polygon.options
        }

        PolylabelBased.prototype.getConfigOptions = function getConfigOptions(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this = this

          return CONFIG.options.reduce((result, key) => {
            result[key] = _this.getPolylabelType() === 'collection' ? polygon.options.get(key) : polygon.options[key]
            return result
          }, {})
        }

        PolylabelBased.prototype.getConfigZoomRangeOptions = function getConfigZoomRangeOptions(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this2 = this

          return CONFIG.zoomRangeOptions.reduce((result, key) => {
            result[key] = _this2.getPolylabelType() === 'collection' ? polygon.options.get(key) : polygon.options[key]
            return result
          }, {})
        }

        PolylabelBased.prototype.getConfigProperties = function getConfigProperties(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this3 = this

          return CONFIG.properties.reduce((result, key) => {
            result[key] = _this3.getPolylabelType() === 'collection' ? polygon.properties.get(key) : polygon.properties[key]
            return result
          }, {})
        }

        return PolylabelBased
      }())

      _provide(PolylabelBased)
    })
    // # sourceMappingURL=PolylabelBase.js.map

    ymaps.modules.define('polylabel.polylabel.PolylabelCollection', ['util.defineClass', 'util.WeakMap', 'vow', 'GeoObjectCollection', 'Monitor', 'system.nextTick', 'event.Manager', 'Event', 'polylabel.polylabel.PolylabelBase', 'polylabel.label.GeoObjectCollection.Label', 'polylabel.util.State'], (_provide, _utilDefineClass, _utilWeakMap, _vow, GeoObjectCollection, Monitor, nextTick, EventManager, Event, PBase, Label, State) => {
      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      function _possibleConstructorReturn(self, call) {
        if (!self) {
          throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
        }

        return call && (typeof call === 'object' || typeof call === 'function') ? call : self
      }
      // eslint-disable-next-line func-names
      const PolylabelCollection = (function(_PBase) {
        _utilDefineClass(PolylabelCollection, _PBase)

        // eslint-disable-next-line @typescript-eslint/no-shadow
        function PolylabelCollection(map: ymaps.Map, polygonsCollection) {
          // @ts-ignore
          _classCallCheck(this, PolylabelCollection)
          // @ts-ignore
          const _this = _possibleConstructorReturn(this, _PBase.call(this, map))

          _this._map = map
          _this._labelsCollection = new GeoObjectCollection()
          _this._labelsState = new State()
          _this._userState = new State() // everything that needs to be added to the user
          _this._polygonsCollection = polygonsCollection
          _this._isPolygonParentChange = new _utilWeakMap()
          _this._polylabelType = 'collection'
          _this._init()
          return _this
        }

        PolylabelCollection.prototype.destroy = function destroy() {
          this._deleteLabelStateListeners()
          this._deletePolygonsListeners()
          this._deletePolygonCollectionListeners()
          this._deleteLabelCollection()
          this._map.geoObjects.remove(this._labelsCollection)
        }

        PolylabelCollection.prototype.getLabelState = function getLabelState(polygon) {
          return this._userState.getState(polygon)
        }

        PolylabelCollection.prototype._init = function _init() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this2 = this

          this._map.geoObjects.add(this._labelsCollection)
          this._firstCalculatePolygons().then(() => {
            _this2._initMapListeners()
            _this2._initPolygonCollectionListeners()
            _this2._initLabelCollectionListeners()
          })
        }

        PolylabelCollection.prototype._firstCalculatePolygons = function _firstCalculatePolygons() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this3 = this

          this._clearLabelCollection()
          this._polygonsCollection.each(polygon => {
            _this3._calculateNewPolygon(polygon).then(label => {
              _this3._setLabelData(polygon, label)
            })
          })
          return _vow.Promise.resolve()
        }

        PolylabelCollection.prototype._calculatePolygons = function _calculatePolygons() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this4 = this

          const promises = []
          this._polygonsCollection.each(polygon => {
            if (polygon.geometry.getType() === 'Polygon') {
              const label = _this4._labelsState.get(polygon, 'label')
              // @ts-ignore
              if (label) { promises.push(_this4._setLabelData(polygon, label)) }
            }
          })
          return _vow.Promise.all(promises)
        }

        PolylabelCollection.prototype._clearLabelCollection = function _clearLabelCollection() {
          this._labelsCollection.removeAll()
          this._labelsCollection.options.set({
            pane: 'phantom',
          })
        }

        PolylabelCollection.prototype._deleteLabelCollection = function _deleteLabelCollection() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this5 = this

          this._polygonsCollection.each(polygon => {
            const label = _this5._labelsState.get(polygon, 'label')
            if (label) { label.destroy() }
          })
          this._clearLabelCollection()
        }

        PolylabelCollection.prototype._calculatePolygonLabelData = function _calculatePolygonLabelData(polygon, isLabelCreated) {
          const options = this.getConfigOptions(polygon)
          const zoomRangeOptions = this.getConfigZoomRangeOptions(polygon)

          const label = isLabelCreated ? this._labelsState.get(polygon, 'label') : new Label(this._map, polygon, this._labelsCollection, this)

          label.createLabelData(options, zoomRangeOptions)
          label.createLayoutTemplates()
          return _vow.Promise.resolve(label)
        }

        PolylabelCollection.prototype._setLabelData = function _setLabelData(polygon, label, visibleState) {
          const data = label.setDataByZoom(this._map.getZoom(), visibleState)
          this._setCurrentConfiguredVisibility(polygon, data.currentConfiguredVisibileType)
          this._setCurrentVisibility(polygon, data.currentVisibleType)
          this._setCurrentCenter(polygon, data.currentCenter)
        }

        PolylabelCollection.prototype._setCurrentCenter = function _setCurrentCenter(polygon, center: ymaps.Coordinate) {
          this._userState.set(polygon, 'center', center)
        }

        PolylabelCollection.prototype._setCurrentConfiguredVisibility = function _setCurrentConfiguredVisibility(polygon, type: string) {
          this._userState.set(polygon, 'currentConfiguredVisibility', type)
        }

        PolylabelCollection.prototype._setCurrentVisibility = function _setCurrentVisibility(polygon, type: string) {
          this._userState.set(polygon, 'currentVisibility', ['dot', 'label'].includes(type) ? type : 'none')
        }

        PolylabelCollection.prototype._calculateNewPolygon = function _calculateNewPolygon(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this6 = this

          if (polygon.geometry.getType() !== 'Polygon') {
            return _vow.Promise.reject()
          }

          return new _vow.Promise(resolve => {
            _this6._calculatePolygonLabelData(polygon).then(label => {
              _this6._labelsState.set(polygon, 'label', label)
              _this6._initUserStateListener(polygon)
              _this6._initPolygonListener(polygon)
              label.createPlacemarks()
              label.addToCollection().then(() => {
                resolve(label)
              })
            })
          })
        }

        PolylabelCollection.prototype._clearVisibilityInLabelsState = function _clearVisibilityInLabelsState(value) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this7 = this

          this._polygonsCollection.each(polygon => {
            _this7._userState.set(polygon, 'visible', value)
          })
        }

        PolylabelCollection.prototype._initUserStateListener = function _initUserStateListener(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this8 = this

          const monitor = new Monitor(this._userState.getState(polygon))
          this._userState.set(polygon, '_labelMonitor', monitor)
          monitor.add('visible', newValue => {
            _this8._setLabelData(polygon, _this8._labelsState.get(polygon, 'label'), newValue)
          })
        }

        PolylabelCollection.prototype._initPolygonListener = function _initPolygonListener(polygon) {
          if (polygon.geometry.getType() === 'Polygon') {
            polygon.events.add(['optionschange', 'propertieschange'], this._onPolygonOptionsChangeHandler, this)
            polygon.events.add('parentchange', this._onPolygonParentChangeHandler, this)
          }
        }

        PolylabelCollection.prototype._onPolygonParentChangeHandler = function _onPolygonParentChangeHandler(event) {
          this._isPolygonParentChange.set(event.get('target'), true)
        }

        PolylabelCollection.prototype._onPolygonOptionsChangeHandler = function _onPolygonOptionsChangeHandler(event) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this9 = this

          nextTick(() => {
            // тк может произойти удаление объекта и optionschange тоже дернется
            // сделан nextTick чтобы до этого проверить был ли parentchange, тк он происходит до optionschange
            const polygon = event.get('target')
            const label = _this9._labelsState.get(polygon, 'label')

            const curr = _this9._isPolygonParentChange.get(polygon)
            if (curr || !label) { return }

            label.setVisibilityForce('none')
            label.setLayoutTemplate()

            // eslint-disable-next-line @typescript-eslint/no-shadow
            _this9._calculatePolygonLabelData(polygon, true).then(label => {
              label.updateLayouts()
              _this9._labelsState.set(polygon, 'label', label)
              _this9._setLabelData(polygon, label)
            })
          })
        }

        PolylabelCollection.prototype._initPolygonCollectionListeners = function _initPolygonCollectionListeners() {
          this._polygonsCollection.events.add(['add', 'remove'], this._polygonCollectionEventHandler, this)
        }

        PolylabelCollection.prototype._polygonCollectionEventHandler = function _polygonCollectionEventHandler(event) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this10 = this

          switch (event.get('type')) {
            case 'add':
            {
              const polygon = event.get('child')
              this._calculateNewPolygon(polygon).then(label => {
                _this10._setLabelData(polygon, label)
              })
              break
            }
            case 'remove':
            {
              const _polygon = event.get('child')
              this._deleteLabelStateListener(_polygon)
              const label = this._labelsState.get(_polygon, 'label')
              if (label) { label.destroy() }
              break
            }
          }
        }

        PolylabelCollection.prototype._initLabelCollectionListeners = function _initLabelCollectionListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this11 = this

          const controller = {
            onBeforeEventFiring: function onBeforeEventFiring(events, type: string, event) {
              if (event.get('target').options.get('pane') === 'phantom') { return false }

              const polygon = event.get('target').properties.get('polygon')
              if (!polygon) { return false }

              if (type === 'mouseenter' || type === 'mouseleave') {
                const labelInst = _this11._labelsState.get(polygon, 'label')
                if (labelInst && labelInst.getLabelData().isDotDefault) {
                  if (type === 'mouseenter') {
                    labelInst.addDotClass('ymaps-polylabel-dot-default_hover')
                  } else {
                    labelInst.removeDotClass('ymaps-polylabel-dot-default_hover')
                  }
                }
              }

              const newEvent = new Event({
                target: polygon,
                type: `label${type}`,
              }, event)

              polygon.events.fire(`label${type}`, newEvent)
              return false
            },
          }
          const eventManager = new EventManager({
            controllers: [controller],
          })
          this._labelsCollection.events.setParent(eventManager)
        }

        PolylabelCollection.prototype._initMapListeners = function _initMapListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this12 = this

          this.initMapListeners(type => {
            if (type === 'actionendzoomchange') {
              _this12._clearVisibilityInLabelsState()
              _this12._calculatePolygons()
            } else if (type === 'actionbeginzoomchange') {
              _this12._clearVisibilityInLabelsState('none')
            }
          })
        }

        PolylabelCollection.prototype._deleteLabelStateListeners = function _deleteLabelStateListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this13 = this

          this._polygonsCollection.each(polygon => {
            _this13._deleteLabelStateListener(polygon)
          })
        }

        PolylabelCollection.prototype._deleteLabelStateListener = function _deleteLabelStateListener(polygon) {
          const monitor = this._userState.get(polygon, '_labelMonitor')
          if (monitor) { monitor.removeAll() }
        }

        PolylabelCollection.prototype._deletePolygonCollectionListeners = function _deletePolygonCollectionListeners() {
          this._polygonsCollection.events.remove(['add', 'remove'], this._polygonCollectionEventHandler, this)
          this.destroyMapListeners()
        }

        PolylabelCollection.prototype._deletePolygonsListeners = function _deletePolygonsListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this14 = this

          this._polygonsCollection.each(polygon => {
            _this14._deletePolygonListener(polygon)
          })
        }

        PolylabelCollection.prototype._deletePolygonListener = function _deletePolygonListener(polygon) {
          polygon.events.remove(['optionschange', 'propertieschange'], this._onPolygonOptionsChangeHandler, this)
          polygon.events.remove('parentchange', this._onPolygonParentChangeHandler, this)
        }

        return PolylabelCollection
      }(PBase))

      _provide(PolylabelCollection)
    })
    // # sourceMappingURL=PolylabelCollection.js.map

    ymaps.modules.define('polylabel.polylabel.PolylabelObjectManager', ['util.defineClass', 'vow', 'Monitor', 'ObjectManager', 'system.nextTick', 'event.Manager', 'polylabel.polylabel.PolylabelBase', 'polylabel.label.ObjectManager.Label', 'polylabel.util.State'], (_provide, _utilDefineClass, _vow, Monitor, ObjectManager, nextTick, EventManager, PBase, Label, State) => {
      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }

      function _possibleConstructorReturn(self, call) {
        if (!self) {
          throw new ReferenceError("this hasn't been initialised - super() hasn't been called")
        }

        return call && (typeof call === 'object' || typeof call === 'function') ? call : self
      }
      // eslint-disable-next-line func-names
      const PolylabelObjectManager = (function(_PBase) {
        _utilDefineClass(PolylabelObjectManager, _PBase)

        // eslint-disable-next-line @typescript-eslint/no-shadow
        function PolylabelObjectManager(map: ymaps.Map, objectManager) {
          // @ts-ignore
          _classCallCheck(this, PolylabelObjectManager)
          // @ts-ignore
          const _this = _possibleConstructorReturn(this, _PBase.call(this, map))

          _this._map = map
          _this._polygonsObjectManager = objectManager
          _this._labelsObjectManager = new ObjectManager()
          _this._labelsState = new State()
          _this._userState = new State() // everything that needs to be added to the user
          _this._polylabelType = 'objectmanager'
          _this._init()
          return _this
        }

        PolylabelObjectManager.prototype.destroy = function destroy() {
          this._deleteLabelsOverlaysListeners()
          this._deleteLabelStateListeners()
          this._deletePolygonsListeners()
          this._deletePolygonsObjectsListeners()
          this._deleteLabelsOMListeners()
        }

        PolylabelObjectManager.prototype.getLabelState = function getLabelState(polygon) {
          return this._userState.getState(polygon)
        }

        PolylabelObjectManager.prototype._init = function _init() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this2 = this

          this._map.geoObjects.add(this._labelsObjectManager)
          this._initLabelsOverlaysListeners()
          this._initPolygonsObjectsListeners()
          this._initPolygonsListeners()
          this._initLabelsOMListeners()

          this._calculatePolygons().then(() => _this2._initMapListeners())
        }

        PolylabelObjectManager.prototype._calculatePolygons = function _calculatePolygons() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this3 = this

          this._polygonsObjectManager.objects.each(polygon => {
            _this3._calculateNewPolygon(polygon)
          })
          return _vow.Promise.resolve()
        }

        PolylabelObjectManager.prototype._calculatePolygonLabelData = function _calculatePolygonLabelData(polygon, isLabelCreated) {
          const options = this.getConfigOptions(polygon)
          const zoomRangeOptions = this.getConfigZoomRangeOptions(polygon)

          const label = isLabelCreated ? this._labelsState.get(polygon, 'label') : new Label(this._map, polygon, this._labelsObjectManager, this)

          label.createLabelData(options, zoomRangeOptions)
          label.createLayoutTemplates()
          return _vow.Promise.resolve(label)
        }

        PolylabelObjectManager.prototype._setLabelData = function _setLabelData(polygon, label, visibleState: string, types: Array<string>) {
          const data = label.setDataByZoom(this._map.getZoom(), types, visibleState)
          this._setCurrentConfiguredVisibility(polygon, data.currentConfiguredVisibileType)
          this._setCurrentVisibility(polygon, data.currentVisibleType)
          this._setCurrentCenter(polygon, data.currentCenter)
        }

        PolylabelObjectManager.prototype._setCurrentCenter = function _setCurrentCenter(polygon, center: ymaps.Coordinate) {
          this._userState.set(polygon, 'center', center)
        }

        PolylabelObjectManager.prototype._setCurrentConfiguredVisibility = function _setCurrentConfiguredVisibility(polygon, type: string) {
          this._userState.set(polygon, 'currentConfiguredVisibility', type)
        }

        PolylabelObjectManager.prototype._setCurrentVisibility = function _setCurrentVisibility(polygon, type: string) {
          this._userState.set(polygon, 'currentVisibility', ['dot', 'label'].includes(type) ? type : 'none')
        }

        PolylabelObjectManager.prototype._initLabelStateListener = function _initLabelStateListener(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this4 = this

          const monitor = new Monitor(this._userState.getState(polygon))
          this._userState.set(polygon, '_labelMonitor', monitor)
          monitor.add('visible', newValue => {
            if (!polygon) { return }
            _this4._setLabelData(polygon, _this4._labelsState.get(polygon, 'label'), newValue, ['dot', 'label'])
          })
        }

        PolylabelObjectManager.prototype._initLabelsOverlaysListeners = function _initLabelsOverlaysListeners() {
          this._labelsObjectManager.objects.overlays.events.add(['add', 'remove'], this._labelsOverlaysEventHandler, this)
        }

        PolylabelObjectManager.prototype._getLabelType = function _getLabelType(labelId) {
          return labelId.includes('label#') ? 'label' : 'dot'
        }

        PolylabelObjectManager.prototype._labelOverlaysGeometryChangeHandler = function _labelOverlaysGeometryChangeHandler(event) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this5 = this

          const overlay = event.get('target')
          const labelId = overlay._data.id

          overlay.getLayout().then(layout => {
            const labelType: string = _this5._getLabelType(labelId)
            const label = _this5._labelsObjectManager.objects.getById(labelId)
            if (!label) { return }

            const { polygon } = label.properties
            const labelInst = _this5._labelsState.get(polygon, 'label')
            labelInst.setLayout(labelType, layout)
            _this5._setLabelData(polygon, labelInst, undefined, [labelType])
          })
        }

        PolylabelObjectManager.prototype._labelsOverlaysEventHandler = function _labelsOverlaysEventHandler(event) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this6 = this

          const labelId: string = event.get('objectId')
          const labelType: string = this._getLabelType(labelId)
          const overlay = event.get('overlay')

          switch (event.get('type')) {
            case 'add':
            {
              overlay.events.add('geometrychange', this._labelOverlaysGeometryChangeHandler, this)
              nextTick(() => {
                // overlay добавляется на карту на следующем тике
                if (!overlay) { return }
                overlay.getLayout().then(layout => {
                  if (!layout) { return }
                  const label = _this6._labelsObjectManager.objects.getById(labelId)
                  if (!label) { return }

                  const { polygon } = label.properties
                  const labelInst = _this6._labelsState.get(polygon, 'label')
                  labelInst.setLayout(labelType, layout)
                  _this6._setLabelData(polygon, labelInst, undefined, [labelType])
                })
              })
              break
            }
            case 'remove':
            {
              overlay.events.remove('geometrychange', this._labelOverlaysGeometryChangeHandler, this)
              break
            }
          }
        }

        PolylabelObjectManager.prototype._clearVisibilityInLabelsState = function _clearVisibilityInLabelsState(value) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this7 = this

          this._polygonsObjectManager.objects.each(polygon => {
            _this7._userState.set(polygon, 'visible', value)
          })
        }

        PolylabelObjectManager.prototype._initMapListeners = function _initMapListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this8 = this

          this.initMapListeners(type => {
            if (type === 'actionendzoomchange') {
              _this8._clearVisibilityInLabelsState()
            } else if (type === 'actionbeginzoomchange') {
              _this8._clearVisibilityInLabelsState('none')
            }
          })
        }

        PolylabelObjectManager.prototype._initPolygonsObjectsListeners = function _initPolygonsObjectsListeners() {
          this._polygonsObjectManager.objects.events.add(['add', 'remove'], this._polygonCollectionEventHandler, this)
        }

        PolylabelObjectManager.prototype._polygonCollectionEventHandler = function _polygonCollectionEventHandler(event) {
          switch (event.get('type')) {
            case 'add':
            {
              const polygon = event.get('child')
              this._calculateNewPolygon(polygon)
              break
            }
            case 'remove':
            {
              const _polygon = event.get('child')
              this._deleteLabelStateListener(_polygon)
              const label = this._labelsState.get(_polygon, 'label')
              if (label) { label.destroy() }
              break
            }
          }
        }

        PolylabelObjectManager.prototype._calculateNewPolygon = function _calculateNewPolygon(polygon) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this9 = this

          if (polygon.geometry.type === 'Polygon') {
            this._calculatePolygonLabelData(polygon).then(label => {
              _this9._labelsState.set(polygon, 'label', label)
              _this9._initLabelStateListener(polygon)
              label.createPlacemarks()
              label.addToObjectManager()
            })
          }
        }

        PolylabelObjectManager.prototype._initPolygonsListeners = function _initPolygonsListeners() {
          this._polygonsObjectManager.objects.events.add(['optionschange', 'objectoptionschange'], this._onPolygonOptionsChangeHandler, this)
        }

        PolylabelObjectManager.prototype._onPolygonOptionsChangeHandler = function _onPolygonOptionsChangeHandler(event) {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this10 = this

          const polygon = this._polygonsObjectManager.objects.getById(event.get('objectId'))
          if (!polygon) { return }

          this._calculatePolygonLabelData(polygon, true).then(label => {
            label.setVisibilityForce('none')
            _this10._labelsState.set(polygon, 'label', label)

            label.setLayoutTemplate()
            label.updateOptions()
            label.updateLayouts()
            _this10._setLabelData(polygon, _this10._labelsState.get(polygon, 'label'), undefined, ['dot', 'label'])
          })
        }

        PolylabelObjectManager.prototype._initLabelsOMListeners = function _initLabelsOMListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this11 = this

          const controller = {
            onBeforeEventFiring: function onBeforeEventFiring(events, type: string, event) {
              const labelId = event.get('objectId')
              if (!labelId) { return false }

              let polygonId = labelId.split('#')[1]
              polygonId = isNaN(Number(polygonId)) ? polygonId : Number(polygonId)

              const polygon = _this11._polygonsObjectManager.objects.getById(polygonId)
              const label = _this11._labelsObjectManager.objects.getById(labelId)
              // eslint-disable-next-line no-mixed-operators
              if (label && label.options.pane === 'phantom' || !polygon) { return false }

              if (type === 'mouseenter' || type === 'mouseleave') {
                const labelInst = _this11._labelsState.get(polygon, 'label')
                if (labelInst && labelInst.getLabelData().isDotDefault) {
                  if (type === 'mouseenter') {
                    labelInst.addDotClass('ymaps-polylabel-dot-default_hover')
                  } else {
                    labelInst.removeDotClass('ymaps-polylabel-dot-default_hover')
                  }
                }
              }

              _this11._polygonsObjectManager.events.fire(`label${type}`, {
                objectId: polygonId,
                type: `label${type}`,
              })

              return false
            },
          }
          const eventManager = new EventManager({
            controllers: [controller],
          })
          this._labelsObjectManager.events.setParent(eventManager)
        }

        PolylabelObjectManager.prototype._deleteLabelStateListeners = function _deleteLabelStateListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this12 = this

          this._polygonsObjectManager.objects.each(polygon => {
            if (polygon.geometry.type === 'Polygon') {
              _this12._deleteLabelStateListener(polygon)
            }
          })
        }

        PolylabelObjectManager.prototype._deleteLabelStateListener = function _deleteLabelStateListener(polygon) {
          const monitor = this._userState.get(polygon, '_labelMonitor')
          if (monitor) { monitor.removeAll() }
        }

        PolylabelObjectManager.prototype._deleteLabelsOverlaysListeners = function _deleteLabelsOverlaysListeners() {
          this._labelsObjectManager.objects.overlays.events.remove(['add', 'remove'], this._labelsOverlaysEventHandler, this)
        }

        PolylabelObjectManager.prototype._deletePolygonsListeners = function _deletePolygonsListeners() {
          this._polygonsObjectManager.objects.events.remove(['optionschange', 'objectoptionschange'], this._onPolygonOptionsChangeHandler, this)
        }

        PolylabelObjectManager.prototype._deletePolygonsObjectsListeners = function _deletePolygonsObjectsListeners() {
          this._polygonsObjectManager.objects.events.remove(['add', 'remove'], this._polygonCollectionEventHandler, this)
          this.destroyMapListeners()
        }

        PolylabelObjectManager.prototype._deleteLabelsOMListeners = function _deleteLabelsOMListeners() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const _this13 = this

          this._polygonsObjectManager.objects.each(polygon => {
            const label = _this13._labelsState.get(polygon, 'label')
            if (polygon.geometry.type === 'Polygon' && label) {
              label.destroy()
            }
          })
          this._clearLabels()
        }

        PolylabelObjectManager.prototype._clearLabels = function _clearLabels() {
          this._labelsObjectManager.removeAll()
        }

        return PolylabelObjectManager
      }(PBase))

      _provide(PolylabelObjectManager)
    })
    // # sourceMappingURL=PolylabelObjectManager.js.map

    ymaps.modules.define('polylabel.util.checkPointPosition', [], _provide => {
      /**
       * Check if the point is inside the polygon.
       * @param {Array[2]} point
       * @param {Array} coords - Polygon coords.
       */
      function isInside(point, coords) {
        let parity = 0
        for (let i = 0; i < coords.length - 1; i += 1) {
          const e = [coords[i], coords[i + 1]]

          switch (edgeType(point, e)) {
            case 'TOUCHING':
              return 'BOUNDARY'
            case 'CROSSING':
              parity = 1 - parity
          }
        }
        return parity ? 'INSIDE' : 'OUTSIDE'
      }

      _provide(isInside)

      /**
       * Determines the position of the point relative to the edge.
       * @param {Array[2]} p - The investigated point.
       * @param {Array[2]} p0 - The first point of the edge.
       * @param {Array[2]} p1 - The second point of the edge.
       */
      function pointClassify(p, p0, p1) {
        const a = pointMinus(p1, p0)

        const b = pointMinus(p, p0)
        const sa = a[0] * b[1] - b[0] * a[1]
        if (sa > 0) {
          return 'LEFT'
        }
        if (sa < 0) {
          return 'RIGHT'
        }
        if (a[0] * b[0] < 0 || a[1] * b[1] < 0) {
          return 'BEHIND'
        }

        if (pointLength(a) < pointLength(b)) {
          return 'BEYOND'
        }

        if (pointEquals(p0, p)) {
          return 'ORIGIN'
        }

        if (pointEquals(p1, p)) {
          return 'DESTINATION'
        }
        return 'BETWEEN'
      }

      function pointMinus(p1, p2) {
        return [p1[0] - p2[0], p1[1] - p2[1]]
      }

      function pointLength(p) {
        return Math.sqrt(p[0] ** 2 + p[1] ** 2)
      }

      function pointEquals(p1, p2) {
        return p1[0] === p2[0] && p1[1] === p2[1]
      }

      /**
       * Determines the positions of the ray released from the point relative to the edge (Crosses, Affects, Neutral).
       * @param {Arrya[2]} point - The investigated point.
       * @param {Array} edge - Edge.
       */
      function edgeType(point, edge) {
        const v = edge[0]
        const w = edge[1]
        switch (pointClassify(point, v, w)) {
          case 'LEFT':
          {
            return v[1] < point[1] && point[1] <= w[1] ? 'CROSSING' : 'INESSENTIAL'
          }
          case 'RIGHT':
          {
            return w[1] < point[1] && point[1] <= v[1] ? 'CROSSING' : 'INESSENTIAL'
          }
          case 'BETWEEN':
          case 'ORIGIN':
          case 'DESTINATION':
            return 'TOUCHING'
          default:
          {
            return 'INESSENTIAL'
          }
        }
      }
    })
    // # sourceMappingURL=checkPointPosition.js.map

    ymaps.modules.define('polylabel.util.getPolesOfInaccessibility', [], _provide => {
      _provide((polygonCoords, precision, debug) => getPolesOfInaccessibility([polygonCoords], precision, debug))

      function TinyQueue(data, compare) {
        // @ts-ignore
        if (!(this instanceof TinyQueue)) { return new TinyQueue(data, compare) }

        // @ts-ignore
        this.data = data || []
        // @ts-ignore
        this.length = this.data.length
        // @ts-ignore

        this.compare = compare || defaultCompare

        // @ts-ignore
        if (this.length > 0) {
          // @ts-ignore
          for (let i = Math.floor(this.length / 2); i >= 0; i -= 1) {
            // @ts-ignore
            this._down(i)
          }
        }
      }

      function defaultCompare(a, b) {
        // eslint-disable-next-line no-nested-ternary
        return a < b ? -1 : a > b ? 1 : 0
      }

      TinyQueue.prototype = {

        push: function push(item) {
          this.data.push(item)
          this.length += 1
          this._up(this.length - 1)
        },

        pop: function pop() {
          if (this.length === 0) { return undefined }

          const top = this.data[0]
          this.length -= 1

          if (this.length > 0) {
            this.data[0] = this.data[this.length]
            this._down(0)
          }
          this.data.pop()

          return top
        },

        peek: function peek() {
          return this.data[0]
        },

        _up: function _up(pos) {
          const { data } = this
          const { compare } = this
          const item = data[pos]

          while (pos > 0) {
            // eslint-disable-next-line no-bitwise
            const parent = pos - 1 >> 1
            const current = data[parent]
            if (compare(item, current) >= 0) { break }
            data[pos] = current
            pos = parent
          }

          data[pos] = item
        },

        _down: function _down(pos) {
          const { data } = this
          const { compare } = this
          // eslint-disable-next-line no-bitwise
          const halfLength = this.length >> 1
          const item = data[pos]

          while (pos < halfLength) {
            // eslint-disable-next-line no-bitwise
            let left = (pos << 1) + 1
            const right = left + 1
            let best = data[left]

            if (right < this.length && compare(data[right], best) < 0) {
              left = right
              best = data[right]
            }
            if (compare(best, item) >= 0) { break }

            data[pos] = best
            pos = left
          }

          data[pos] = item
        },
      }

      const Queue = TinyQueue

      function getPolesOfInaccessibility(polygon, precision, debug) {
        precision = precision || 1.0

        // find the bounding box of the outer ring
        let minX
        let minY
        let maxX
        let maxY

        for (let i = 0; i < polygon[0].length; i += 1) {
          const p = polygon[0][i]
          // eslint-disable-next-line prefer-destructuring
          if (!i || p[0] < minX) { minX = p[0] }
          // eslint-disable-next-line prefer-destructuring
          if (!i || p[1] < minY) { minY = p[1] }
          // eslint-disable-next-line prefer-destructuring
          if (!i || p[0] > maxX) { maxX = p[0] }
          // eslint-disable-next-line prefer-destructuring
          if (!i || p[1] > maxY) { maxY = p[1] }
        }

        const width = maxX - minX
        const height = maxY - minY
        const cellSize = Math.min(width, height)
        let h = cellSize / 2

        // a priority queue of cells in order of their "potential" (max distance to polygon)
        // @ts-ignore

        const cellQueue = new Queue(null, compareMax)

        if (cellSize === 0) { return [minX, minY] }

        // cover polygon with initial cells
        for (let x = minX; x < maxX; x += cellSize) {
          for (let y = minY; y < maxY; y += cellSize) {
            cellQueue.push(new Cell(x + h, y + h, h, polygon))
          }
        }

        // take centroid as the first best guess

        let bestCell = getCentroidCell(polygon)

        // special case for rectangular polygons

        const bboxCell = new Cell(minX + width / 2, minY + height / 2, 0, polygon)
        if (bboxCell.d > bestCell.d) { bestCell = bboxCell }

        let numProbes = cellQueue.length

        while (cellQueue.length) {
          // pick the most promising cell from the queue
          const cell = cellQueue.pop()

          // update the best cell if we found a better one
          if (cell.d > bestCell.d) {
            bestCell = cell
          }

          // do not drill down further if there's no chance of a better solution
          // eslint-disable-next-line no-continue
          if (cell.max - bestCell.d <= precision) { continue }

          // split the cell into four cells
          h = cell.h / 2

          cellQueue.push(new Cell(cell.x - h, cell.y - h, h, polygon))

          cellQueue.push(new Cell(cell.x + h, cell.y - h, h, polygon))

          cellQueue.push(new Cell(cell.x - h, cell.y + h, h, polygon))

          cellQueue.push(new Cell(cell.x + h, cell.y + h, h, polygon))
          numProbes += 4
        }

        return [bestCell.x, bestCell.y]
      }

      function compareMax(a, b) {
        return b.max - a.max
      }

      function Cell(x, y, h, polygon) {
        // @ts-ignore
        this.x = x // cell center x
        // @ts-ignore
        this.y = y // cell center y
        // @ts-ignore
        this.h = h // half the cell size
        // @ts-ignore

        this.d = pointToPolygonDist(x, y, polygon) // distance from cell center to polygon
        // @ts-ignore
        this.max = this.d + this.h * Math.SQRT2 // max distance to polygon within a cell
      }

      // signed distance from point to polygon outline (negative if point is outside)
      function pointToPolygonDist(x, y, polygon) {
        let inside = false
        let minDistSq = Infinity
        for (let k = 0; k < polygon.length; k += 1) {
          const ring = polygon[k]
          for (let i = 0, len = ring.length, j = len - 1; i < len; j = i, i += 1) {
            const a = ring[i]
            const b = ring[j]

            // eslint-disable-next-line no-mixed-operators
            if (a[1] > y !== b[1] > y && x < (b[0] - a[0]) * (y - a[1]) / (b[1] - a[1]) + a[0]) { inside = !inside }

            minDistSq = Math.min(minDistSq, getSegDistSq(x, y, a, b))
          }
        }

        return (inside ? 1 : -1) * Math.sqrt(minDistSq)
      }

      // get polygon centroid
      function getCentroidCell(polygon) {
        let area = 0
        let x = 0
        let y = 0
        const points = polygon[0]
        for (let i = 0, len = points.length, j = len - 1; i < len; j = i, i += 1) {
          const a = points[i]
          const b = points[j]
          const f = a[0] * b[1] - b[0] * a[1]
          x += (a[0] + b[0]) * f
          y += (a[1] + b[1]) * f
          area += f * 3
        }
        if (area === 0) { return new Cell(points[0][0], points[0][1], 0, polygon) }
        return new Cell(x / area, y / area, 0, polygon)
      }

      // get squared distance from a point to a segment
      function getSegDistSq(px, py, a, b) {
        let x = a[0]
        let y = a[1]
        let dx = b[0] - x
        let dy = b[1] - y

        if (dx !== 0 || dy !== 0) {
          const t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy)

          if (t > 1) {
            // eslint-disable-next-line prefer-destructuring
            x = b[0]
            // eslint-disable-next-line prefer-destructuring
            y = b[1]
          } else if (t > 0) {
            x += dx * t
            y += dy * t
          }
        }

        dx = px - x
        dy = py - y

        return dx * dx + dy * dy
      }
    })
    // # sourceMappingURL=getPolesOfInaccessibility.js.map

    ymaps.modules.define('polylabel.util.getPolygonWithMaxArea', ['calculateArea', 'GeoObject'], (_provide, calculateArea, GeoObject) => {
      _provide(polygonCoords => {
        if (typeof calculateArea === 'undefined') {
          throw new TypeError('Didn\'t find calculateArea module')
        }
        let maxArea = Number.MIN_VALUE
        let indexOfMaxArea = 0
        for (let i = 0; i < polygonCoords.length; i += 1) {
          const polygon = new GeoObject({
            geometry: {
              type: 'Polygon', coordinates: [polygonCoords[i]],
            },
          })
          const area = Math.round(calculateArea(polygon))
          if (maxArea < area) {
            maxArea = area
            indexOfMaxArea = i
          }
        }
        return polygonCoords[indexOfMaxArea]
      })
    })
    // # sourceMappingURL=getPolygonWithMaxArea.js.map

    ymaps.modules.define('polylabel.util.State', ['util.WeakMap', 'data.Manager'], (_provide, _utilWeakMap, DataManager) => {
      function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
          throw new TypeError('Cannot call a class as a function')
        }
      }
      // eslint-disable-next-line func-names
      const State = (function() {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        function State() { // @ts-ignore
          _classCallCheck(this, State)
          // @ts-ignore
          this._state = new _utilWeakMap()
        }

        State.prototype._getDataManager = function _getDataManager(polygon) {
          let dataManager = this._state.get(polygon)
          if (!dataManager) {
            dataManager = new DataManager()
            this._state.set(polygon, dataManager)
          }
          return dataManager
        }

        State.prototype.set = function set(polygon, key, value) {
          const dataManager = this._getDataManager(polygon)
          dataManager.set(key, value)
        }

        // eslint-disable-next-line consistent-return
        State.prototype.get = function get(polygon, key) {
          const dataManager = this._getDataManager(polygon)
          if (dataManager) {
            return dataManager.get(key)
          }
        }

        State.prototype.getState = function getState(polygon) {
          return this._getDataManager(polygon)
        }

        return State
      }())

      _provide(State)
    })
    // # sourceMappingURL=State.js.map

    ymaps.modules.define('polylabel.util.templateFilterStorage.dotColor', ['polylabel.util.transformHexToRGB', 'polylabel.config'], (_provide, transformHexToRGB, CONFIG) => {
      _provide((data, dateString) => transformHexToRGB(dateString || CONFIG.DEFAULT_POLYGON_FILL_COLOR, 0.9))
    })
    // # sourceMappingURL=dotColor.js.map

    ymaps.modules.define('polylabel.util.transformHexToRGB', [], _provide => {
      _provide((hex, opacity) => {
        if (!hex) { return }
        // eslint-disable-next-line consistent-return
        if (hex.includes('rgb')) { return hex }
        hex = hex[0] !== '#' ? `#${hex}` : hex
        hex = hex.slice(0, 7)
        // eslint-disable-next-line no-void
        let c = void 0
        if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) { return }

        c = hex.substring(1).split('')
        // @ts-ignore
        if (c.length === 3) {
          // @ts-ignore
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        // @ts-ignore
        c = `0x${c.join('')}`
        // @ts-ignore
        // eslint-disable-next-line consistent-return,no-bitwise
        return `rgba(${[c >> 16 & 255, c >> 8 & 255, c & 255].join(',')}, ${opacity || 1})`
      })
    })
    // # sourceMappingURL=transformHexToRGB.js.map

    ymaps.modules.define('polylabel.util.transformPolygonCoords', [], _provide => {
      // these functions are needed to transform the coordinates of the polygon,
      // because there are transitions from 180 to -180,
      // that violates the function of calculating the center
      const methods = {

        polygon,

        point,
      }

      function point(coords, isPositivePart) {
        const key = getKey(coords)

        return transformPoint(isPositivePart, key, coords)
      }

      function polygon(coords) {
        const result = []
        let isPositivePart = true // true = positive, false = negative
        for (let i = 0; i < coords.length; i += 1) {
          const pointCoords = coords[i]
          if (i === 0) {
            isPositivePart = pointCoords[1] >= 0
          }

          const key = getKey(pointCoords)
          // @ts-ignore

          result.push(transformPoint(isPositivePart, key, pointCoords))
        }
        return {
          coords: result,
          isPositivePart,
        }
      }

      function transformPoint(isPositivePart, key, pointCoords) {
        return isPositivePart ? transformPositive(key, pointCoords) : transformNegative(key, pointCoords)
      }

      /**
       * Gets the key, which says which of the seams is closer
       */
      function getKey(p) {
        const arr = [{
          key: '180',
          distance: 180 - p[1],
        }, {
          key: '-180',
          distance: Math.abs(-180 - p[1]),
        }, {
          key: '0',
          distance: Math.abs(p[1]),
        }]

        return arr.sort(comparator)[0].key
      }
      // @typescript-eslint/no-shadow

      // eslint-disable-next-line @typescript-eslint/no-shadow
      function transformPositive(key, point) {
        return key === '-180' ? [point[0], 360 + point[1]] : point
      }

      // @typescript-eslint/no-shadow
      // eslint-disable-next-line @typescript-eslint/no-shadow
      function transformNegative(key, point) {
        return key === '180' ? [point[0], -360 + point[1]] : point
      }

      function comparator(a, b) {
        return a.distance - b.distance
      }

      _provide(methods)
    })
    // # sourceMappingURL=transformPolygonCoords.js.map

    ymaps.modules.define('polylabel.util.zoom.getFirstZoomInside', ['polylabel.util.checkPointPosition', 'polylabel.config'], (_provide, isInside, CONFIG) => {
      _provide((map: ymaps.Map, center: ymaps.Coordinate, coords, size: Size, offset: Array<number>, resolvedInaccuracy) => {
        let i = CONFIG.MIN_ZOOM
        let j = CONFIG.MAX_ZOOM

        let zoom = 0
        while (i < j) {
          // @ts-ignore
          zoom = Math.floor((i + j) / 2)
          const ri = isNaN(Number(resolvedInaccuracy)) ? 0 : Number(resolvedInaccuracy)

          const elemPoints = getElemPoints(map, center, zoom, size, offset || [0, 0], ri)
          // eslint-disable-next-line no-mixed-operators
          if (checkIsInside(map, coords, elemPoints.normal, zoom) || ri !== 0 && checkIsInside(map, coords, elemPoints.withInaccuracy, zoom)) {
            j = zoom
          } else {
            // @ts-ignore
            i = zoom + 1
          }
        }
        return i
      })

      function getElemPoints(map: ymaps.Map, center: ymaps.Coordinate, zoom: number, size: Size, offset: Array<number>, ri) {
        const centerProj = map.options.get('projection').toGlobalPixels(center, zoom)
        const w = size.width
        const h = size.height

        centerProj[0] += offset[0]
        centerProj[1] += offset[1]

        const elemPoints = []
        const elemPointsWithInaccuracy = []
        // @ts-ignore
        elemPoints.push([centerProj[0] - w / 2, centerProj[1] - h / 2], [centerProj[0] - w / 2, centerProj[1] + h / 2], [centerProj[0] + w / 2, centerProj[1] - h / 2], [centerProj[0] + w / 2, centerProj[1] + h / 2])
        // @ts-ignore
        elemPointsWithInaccuracy.push([elemPoints[0][0] + ri > centerProj[0] ? centerProj[0] : elemPoints[0][0] + ri, elemPoints[0][1] + ri > centerProj[1] ? centerProj[1] : elemPoints[0][1] + ri], [elemPoints[1][0] + ri > centerProj[0] ? centerProj[0] : elemPoints[1][0] + ri, elemPoints[1][1] - ri < centerProj[1] ? centerProj[1] : elemPoints[1][1] - ri], [elemPoints[2][0] - ri < centerProj[0] ? centerProj[0] : elemPoints[2][0] - ri, elemPoints[2][1] + ri > centerProj[1] ? centerProj[1] : elemPoints[2][1] + ri], [elemPoints[3][0] - ri < centerProj[0] ? centerProj[0] : elemPoints[3][0] - ri, elemPoints[3][1] - ri < centerProj[1] ? centerProj[1] : elemPoints[3][1] - ri])
        return {
          normal: elemPoints,
          withInaccuracy: elemPointsWithInaccuracy,
        }
      }

      function checkIsInside(map: ymaps.Map, coords, elemPoints, zoom: number) {
        for (let i = 0; i < elemPoints.length; i += 1) {
          const point = map.options.get('projection').fromGlobalPixels(elemPoints[i], zoom)
          if (isInside(point, coords) !== 'INSIDE') {
            return false
          }
        }
        return true
      }
    })
    // # sourceMappingURL=getFirstZoomInside.js.map

    ymaps.modules.define('polylabel.util.zoom.parseZoomData', ['util.array', 'polylabel.config'], (_provide, _utilArray, CONFIG) => {
      // eslint-disable-next-line func-names
      const _typeof = typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol' ? function(obj) {
        return typeof obj
        // eslint-disable-next-line func-names
      } : function(obj) {
        return obj && typeof Symbol === 'function' && obj.constructor === Symbol && obj !== Symbol.prototype ? 'symbol' : typeof obj
      }

      const { MIN_ZOOM } = CONFIG
      const { MAX_ZOOM } = CONFIG

      /**
       * Parse data about zoom.
       * @param {Object|primitive} zoomData
       * Supported object properties view: number, string
       * @return {Object} - Returned object with zoom, where the parsed values.
       * @example
       * zoomData = {1: 'value1', '3_5': 'value2'}
       * return {1: 'value1', 2: undefined ... 3: 'value2', 4: 'value2', 5: 'value2', 6: undefined ...}
       * zoomData = 'value123'
       * return {1: 'value123' ... 19: 'value123'}
       */
      // eslint-disable-next-line consistent-return
      function parseZoomData(zoomData) {
        const valid = ['number', 'string', 'boolean', 'object']
        if (zoomData && !_utilArray.isArray(zoomData) && (typeof zoomData === 'undefined' ? 'undefined' : _typeof(zoomData)) === 'object') {
          // @ts-ignore
          return Object.keys(zoomData).reduce(
            (result, key) => {
              if (typeof key === 'string') {
                parseString(result, key, zoomData[key])
              } else if (typeof key === 'number') {
                parseNumber(result, key, zoomData[key])
              }
              return result
            },
            // @ts-ignore

            createDefZoomObj(),
          )
        } if (valid.includes(typeof zoomData === 'undefined' ? 'undefined' : _typeof(zoomData))) {
          return createDefZoomObj(zoomData)
        }
      }

      _provide(parseZoomData)

      function parseNumber(target, zoom: number, value) {
        target[zoom] = value
      }

      function parseString(target, zoom: number, value) {
        if (!isNaN(Number(zoom))) {
          target[Number(zoom)] = value
          return
        }
        const zoomRange = zoom.split('_').map(Number)
        if (isNaN(zoomRange[0]) || isNaN(zoomRange[1])) {
          return
        }
        let bottom = zoomRange[0] < MIN_ZOOM ? MIN_ZOOM : zoomRange[0]
        const top = zoomRange[1] > MAX_ZOOM ? MAX_ZOOM : zoomRange[1]
        while (bottom <= top) {
          target[bottom] = value
          bottom += 1
        }
      }

      function createDefZoomObj(val) {
        const result = {}
        for (let i = MIN_ZOOM; i <= MAX_ZOOM; i += 1) {
          result[i] = val
        }
        return result
      }
    })
    // # sourceMappingURL=parseZoomData.js.map

    ymaps.modules.define('polylabel.util.zoom.setZoomVisibility', ['polylabel.util.zoom.getFirstZoomInside', 'polylabel.label.util.getLayoutSize'], (_provide, getFirstZoomInside, getLayoutSize) => {
      _provide((labelType: string, map: ymaps.Map, zoom: number, currVisible: string, layout, center: ymaps.Coordinate, polygonCoordinates, labelOffset: Array<number>, permissibleInaccuracyOfVisibility) => analyze(labelType, map, zoom, currVisible, layout, center, polygonCoordinates, labelOffset, permissibleInaccuracyOfVisibility))

      function getVisible(currentType: string, newType: string, newIsVisible: boolean) {
        const types = ['none', 'dot', 'label']
        let result = currentType
        if (newIsVisible) {
          result = types.indexOf(newType) > types.indexOf(currentType) ? newType : currentType
        }
        return result
      }

      function analyze(type: string, map: ymaps.Map, zoom: number, currVisible: string, layout, center: ymaps.Coordinate, polygonCoordinates, labelOffset: Array<number>, permissibleInaccuracyOfVisibility) {
        const size = getLayoutSize(layout)
        if (!size || size.width === 0 || size.height === 0) { return }

        const firstZoomInside = getFirstZoomInside(map, center, polygonCoordinates, size, labelOffset, type === 'dot' ? 0 : permissibleInaccuracyOfVisibility)

        // eslint-disable-next-line consistent-return
        return {
          visible: getVisible(currVisible, type, zoom >= firstZoomInside),
          size,
        }
      }
    })
    // # sourceMappingURL=setZoomVisibility.js.map
  }
}

<template>
  <div class="flex items-center h-full p-4">
    <div class="md:mr-[20px]">
      <app-logo :mini="!isMd" />
    </div>
    <div class="max-md:ml-auto mr-6 md:mr-auto">
      <select-address
        :address-service="addressService"
        :is-display-show-button-when-country-is-not-selected="isDisplayShowButtonWhenCountryIsNotSelected"
        :color="selectsColor"
      />
    </div>
    <div class="mr-auto hidden lg:flex">
      <main-menu-nav-links
        :links="links"
        class="max-xl:px-4 gap-x-4 xl:gap-x-8"
      />
    </div>
    <div class="mr-auto hidden lg:flex">
      <custom-button
        class="font-semibold"
        pill
        @click="showAuthModalOrCallback(fix)"
      >
        {{ t('clients.fixation.actions.add') }}
      </custom-button>
    </div>
    <div class="mr-6">
      <app-menu-units-select />
    </div>
    <div v-if="isNavMenuVisible">
      <nav-menu />
    </div>
    <auth-login-and-sign-in-btns />
    <nav-menu-un-authorized v-if="isUnauthorized && width < 1024" />
  </div>
  <clients-form-fixation-or-booking-modal
    v-model="isModalFixationVisible"
    action="fixation"
  />
</template>

<script setup lang="ts">
import AppLogo from '~/components/app/AppLogo.vue'
import AuthLoginAndSignInBtns from '~/modules/auth/components/AuthLoginAndSignInBtns.vue'
import NavMenuUnAuthorized from '~/modules/nav-menu/components/NavMenuUnAuthorized.vue'
import { useWindowSize } from '@vueuse/core'
import MainMenuNavLinks from '~/modules/main-menu/components/MainMenuNavLinks.vue'
import { SelectAddress } from '~/modules/address/AddressModule'
import { AddressGuestService } from '~/modules/address/service/AddressGuestService'
import { AddressService } from '~/modules/address/service/AddressService'
import { NavMenu } from '~/modules/nav-menu/NavMenuModule'
import { useAppStateStore } from '~/store/app'
import { useUserStore } from '~/modules/user/store'
import { MAIN_MENU_HOME_LINKS } from '~/modules/main-menu/constants/MainMenuHomeLinks'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import ClientsFormFixationOrBookingModal
  from '~/modules/clients/components/forms/ClientsFormActionModal.vue'
import { showAuthModalOrCallback } from '~/modules/auth/helpers/showAuthModalOrCallback'

defineProps({
  isNavMenuVisible: {
    type: Boolean,
    default: true,
  },
  addressService: {
    type: Object as PropType<AddressService | AddressGuestService>,
    default: null,
  },
  selectsColor: {
    type: String as PropType<'default' | 'primary'>,
    default: 'default',
  },
  isDisplayShowButtonWhenCountryIsNotSelected: {
    type: Boolean,
    default: false,
  },
})

const isModalFixationVisible = ref(false)

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const { width } = useWindowSize()

const { t } = useI18n()

const isUnauthorized = computed(() => !useUserStore().isUserAuthorized)

const fix = () => {
  isModalFixationVisible.value = true
}

const links = computed(() => Object.values(MAIN_MENU_HOME_LINKS).map(link => ({
  title: t(`mainMenu.navLinks.home.${link}`),
  link,
})))
</script>

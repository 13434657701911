<template>
  <slot />
</template>
<script setup lang="ts">

import { useTitle } from '@vueuse/core'
import { getPageTitle } from '~/common/helpers/getPageTitle'

const props = defineProps({
  params: {
    type: String as PropType<string | null>,
    default: null,
  },
})

const { t } = useI18n()

const route = useRouter().currentRoute.value.name

const title = useTitle()

const { $i18n } = useNuxtApp()

useHead({
  titleTemplate: getPageTitle(route, t, props.params),
})

const updatePageTitle = () => {
  title.value = getPageTitle(route, t, props.params)
}

watch($i18n.locale, () => {
  updatePageTitle()
})

</script>

import { SuggestionsHttpApiService } from '~/modules/suggestions/api/SuggestionsHttpApiService'
import { ManagingSuggestion, Suggestion } from '~/modules/suggestions/types/Suggestion'
import { useSuggestionsStore } from '~/modules/suggestions/store'
import { useAppStateStore } from '~/store/app'
import { type SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import SentryLogger from '~/services/sentry/SentryLogger'
import { HttpApiService } from '~/services/http/HttpApiService'

export class SuggestionsService {
  http: SuggestionsHttpApiService

  store

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new SuggestionsHttpApiService(httpInstance as HttpApiService)
    this.store = useSuggestionsStore()
  }

  public getSuggestion(uniqueId: string): Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.getSuggestion(uniqueId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.status !== 404) {
            SentryLogger.captureScopeException(error, {
              message: 'Не удалось получить подборку',
              uniqueId,
            })
          }
          reject(error)
        })
    })
  }

  public getSuggestions(pageUrl: string | null = null) : Promise<{data: Array<ManagingSuggestion>, nextPageUrl: string | null}> {
    const page = pageUrl === null ? 1 : Number(new URL(pageUrl).searchParams.get('page'))
    return new Promise((resolve, reject) => {
      this.http.getSuggestions(page)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить подборки',
            page,
          })
          reject(error)
        })
    })
  }

  public setSuggestionInStore(suggestion: Suggestion): void {
    this.store.setSuggestion(suggestion)
  }

  public createSuggestion(suggestion: Pick<ManagingSuggestion, 'title' | 'manager'>) : Promise<ManagingSuggestion> {
    const appStore = useAppStateStore()
    return new Promise((resolve, reject) => {
      appStore.isFetching = true
      this.http.createSuggestion(suggestion.title, suggestion.manager)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать подборку',
            title: suggestion.title,
            manager: suggestion.manager,
          })
          reject(error)
        })
        .finally(() => {
          appStore.isFetching = false
        })
    })
  }

  public removeSuggestion(suggestionId: Pick<ManagingSuggestion, 'id'>) : Promise<ManagingSuggestion> {
    const appStore = useAppStateStore()
    return new Promise((resolve, reject) => {
      appStore.isFetching = true
      this.http.removeSuggestion(suggestionId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось удалить подборку',
            suggestionId,
          })
          reject(error)
        })
        .finally(() => {
          appStore.isFetching = false
        })
    })
  }

  public addBuildingsToSuggestion(buildings: Array<SuggestionBuilding>, suggestion: ManagingSuggestion): Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.addBuildingsToSuggestion(buildings.map(building => building.id), suggestion)
        .then(response => {
          resolve(response.data as ManagingSuggestion)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось добавить жк в подборку',
            buildingIds: buildings.map(building => building.id),
            suggestion,
          })
          reject(error)
        })
    })
  }

  public addFlatsToSuggestion(flats: Array<any>, suggestion: ManagingSuggestion): Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.addFlatsToSuggestion(flats.map(flat => flat.id), suggestion)
        .then(response => {
          resolve(response.data as ManagingSuggestion)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось добавить квартиру в  подборку',
            flatsIds: flats.map(flat => flat.id),
            suggestion,
          })
          reject(error)
        })
    })
  }

  public removeBuildingsFromSuggestion(buildings: Array<SuggestionBuilding>, suggestion: ManagingSuggestion) : Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.removeBuildingsFromSuggestion(buildings.map(building => building.id), suggestion)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось удалить жк из побдорки',
            buildingIds: buildings.map(building => building.id),
            suggestion,
          })
          reject(error)
        })
    })
  }

  public removeFlatsFromSuggestion(flats: Array<any>, suggestion: ManagingSuggestion): Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.removeFlatsFromSuggestion(flats.map(building => building.id), suggestion)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось удалить квартиру из побдорки',
            flatsIds: flats.map(flat => flat.id),
            suggestion,
          })
          reject(error)
        })
    })
  }

  public addClientToSuggestion(suggestionId: number, clientId: number): Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.addClientToSuggestion(suggestionId, clientId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось добавить клиента в подборку',
            suggestionId,
            clientId,
          })
          reject(error)
        })
    })
  }

  public removeClientFromSuggestion(suggestionId: number, clientId: number): Promise<ManagingSuggestion> {
    return new Promise((resolve, reject) => {
      this.http.removeClientFromSuggestion(suggestionId, clientId)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось удалить клиента из подборки',
            suggestionId,
            clientId,
          })
          reject(error)
        })
    })
  }
}

<template>
  <custom-button-group
    v-model="entityName"
    :options="entityOptions"
    button-class="flex items-center font-semibold"
  />
</template>
<script setup lang="ts">
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'
import { DISPLAYING_ENTITY } from '~/modules/market-entities-actions/constants/displaying/Displaying.entity'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'

const { t } = useI18n()
const store = useMarketEntitiesActionsStore()

const entityName = computed({
  get: () => store.displayingEntity,
  set: value => {
    store.setDisplayingEntity(value)
  },
})

const entityOptions = computed(() => [
  { value: DISPLAYING_ENTITY.buildings, label: t('building.entityName'), icon: 'entities/building' },
  { value: DISPLAYING_ENTITY.flats, label: t('flat.entityName'), icon: 'common/list' },
  { value: DISPLAYING_ENTITY.layouts, label: t('layout.entityName'), icon: 'common/grid' },
])
</script>

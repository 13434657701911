<template>
  <custom-button
    variant="withoutBorders"
    class="font-semibold !p-0 flex items-center"
    :class="{'!text-neutral-500' : color === 'default'}"
    @click="isModalVisible = true"
  >
    <nuxt-icon
      name="common/map-point"
      class="text-[24px]"
    />
    <span
      v-if="isMd"
      class="border-dashed py-1 border-inherit border-0 border-b-2 ml-2"
    >
      {{ selectedLocation }}
    </span>
  </custom-button>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :drawer-props="{fullHeight: true, footerClass: 'justify-center', hideHeader: false}"
    :modal-props="{footerClass: 'justify-end', bodyClass: 'pt-0'}"
  >
    <template #header>
      <div class="flex gap-x-2.5">
        <span>{{ t('address.select.modal.title.choose') }}</span>
        <span class="text-neutral-500">{{ selectedStep }}</span>
      </div>
    </template>

    <search-address-input
      v-if="!selectedCountry"
      v-model="selectedCountryInput"
      :placeholder="t('address.country.placeholder')"
    />
    <search-address-input
      v-if="selectedCountry && !selectedRegion"
      v-model="selectedRegionInput"
      :placeholder="t('address.region.placeholder')"
    />
    <search-address-input
      v-if="selectedRegion"
      v-model="selectedCityInput"
      :placeholder="t('address.city.placeholder')"
    />
    <div class="mt-4 flex items-center pb-[18px] border-b-[2px] border-neutral-35">
      <template v-for="(step, i) of steps">
        <span
          v-if="i === steps.length - 1"
          :key="step.step"
          class="font-semibold text-primary leading-none"
        >
          {{ step.label }}
        </span>
        <custom-button
          v-else
          :key="step.step + i"
          variant="withoutBorders"
          class="font-semibold !px-0"
          @click="step.action"
        >
          {{ step.label }}
        </custom-button>
        <span
          class="font-semibold text-neutral-500 mx-3"
        >/</span>
        <span
          v-if="!selectedCountry || (!selectedRegion && step.step === 'country') || (!selectedCity && step.step === 'region')"
          class="font-semibold text-primary"
        >..</span>
      </template>
    </div>
    <div
      v-if="isFetching"
      class="flex justify-center w-full"
    >
      <custom-cube-spinner />
    </div>
    <div
      v-if="!selectedCountry"
      class="flex flex-col flex-wrap min-h-[20vh] mt-4"
    >
      <div
        v-for="country of filteredCountries"
        :key="country.id"
        class="w-[33%] cursor-pointer text-primary"
        @click="selectCountry(country)"
      >
        {{ country.name }} - <span class="font-semibold">{{ country.buildingsCount }} {{
          $t('building.entityName')
        }}</span>
      </div>
    </div>
    <div
      v-if="selectedCountry && !selectedRegion && !isFetching"
      class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4"
    >
      <div
        v-for="region of filteredRegions"
        :key="region.regionName"
        class="cursor-pointer text-primary"
        @click="selectRegion(region)"
      >
        {{ region.regionName }}
      </div>
    </div>
    <div
      v-if="selectedRegion && !selectedCity"
      class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4"
    >
      <div
        v-for="city of filteredCities"
        :key="city.fullAddress"
        class="cursor-pointer text-primary"
        @click="selectCity(city)"
      >
        {{ city.locality }}
      </div>
    </div>
    <template
      #footer
    >
      <div class="min-h-[40px] w-full md:w-full lg:w-auto ">
        <custom-button
          v-if="isDisplayShowButtonWhenCountryIsNotSelected || selectedCountry"
          class="block mx-auto !rounded-[19px] font-semibold w-full md:w-full lg:w-auto"
          @click="show"
        >
          {{ $t('common.show') }}
        </custom-button>
      </div>
    </template>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { AddressService } from '~/modules/address/service/AddressService'
import { useAddressStore } from '~/modules/address/store'
import type { Country } from '~/modules/address/types'

import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import {ComputedRef} from 'vue'
import { Region } from '~/modules/address/types/Region'
import SearchAddressInput from '~/modules/address/components/SearchAddressInput.vue'
import { City } from '~/modules/address/types/City'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import { AddressGuestService } from '~/modules/address/service/AddressGuestService'
import { useAppStateStore } from '~/store/app'

type Step = 'allCountries' | 'country' | 'region' | 'city'

type Section = { step: Step, action: () => void, label: string }

const props = defineProps({
  addressService: {
    type: Object as PropType<AddressService | AddressGuestService>,
      default: null
  },
  isDisplayShowButtonWhenCountryIsNotSelected: {
    type: Boolean,
    default: false
  },
  color: {
    type: String as PropType<'default' | 'primary'>,
    default: 'default',
  },
})

const addressService = props.addressService || new AddressService()

const addressStore = useAddressStore()

const actionsStore = useMarketEntitiesActionsStore()

const { t } = useI18n()

const isModalVisible = ref(false)

const isFetching = ref(false)

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const selectedCountry = computed<Country | null>({
  get() {
    return addressStore.country
  },
  set(country: Country | null) {
    addressStore.setCountry(country)
  },
})

const selectedRegion = computed<Region | null>({
  get() {
    return addressStore.region
  },
  set(region: Region | null) {
    addressStore.setRegion(region)
    addressStore.setCities(region?.localities || null)
  },
})

const selectedCity = computed<City | null>({
  get() {
    return addressStore.city
  },
  set(city: City | null) {
    addressStore.setCity(city)
  },
})

const countries = ref<Country[]>([])

const regions = ref<Array<Region>>(selectedRegion.value ? [selectedRegion.value] : [])

const cities = ref<Array<City>>(selectedCity.value ? [selectedCity.value] : [])

const selectedCountryInput = ref(selectedCountry.value?.name ?? '')

const selectedRegionInput = ref(selectedRegion.value?.regionName ?? '')

const selectedCityInput = ref(selectedCity.value?.locality ?? '')

const filterInputValue = <T>(inputValue: string, fieldExtractor: (item: T) => string) => {
  const searchText = new RegExp(`^${inputValue}`, 'i')
  return (item: T) => searchText.test(fieldExtractor(item))
}

const filteredCountries = computed(() => {
  return countries.value.filter(filterInputValue<Country>(selectedCountryInput.value, country => country.name))
})

const filteredRegions = computed(() => {
  return regions.value.filter(filterInputValue<Region>(selectedRegionInput.value, region => region.regionName))
})

const filteredCities = computed(() => {
  return cities.value.filter(filterInputValue<City>(selectedCityInput.value, city => city.locality))
})

const steps: ComputedRef<Array<Section>> = computed(() => {
  const selectionSteps: Array<Section> = []
  selectionSteps.push({
    step: 'allCountries',
    action: () => {
      selectCountry(null)
    },
    label: t('address.allCountries'),
  })
  if (selectedCountry.value) {
    selectionSteps.push({
      step: 'country',
      action: () => {
        selectRegion(null)
      },
      label: selectedCountry.value?.name || '',
    })
  }
  if (selectedRegion.value) {
    selectionSteps.push({
      step: 'region',
      action: () => {
        selectCity(null)
      },
      label: selectedRegion.value?.regionName || '',
    })
  }
  if (selectedCity.value) {
    selectionSteps.push({
      step: 'city',
      action: () => {
      },
      label: selectedCity.value?.locality || '',
    })
  }
  return selectionSteps
})

const getCities = () => {
  const region = selectedRegion.value
  if (region) {
    cities.value = region.localities
    addressStore.setCities(region.localities)
  } else {
    cities.value = []
    selectCity(null)
  }
}

const selectCountry = (country: Country | null) => {
  selectedCountry.value = country
  selectedCountryInput.value = country ? country.name : ''
  if (!country) {
    regions.value = []
    selectRegion(null)
  }
}

const selectRegion = (region: Region | null) => {
  selectedRegion.value = region
  selectedRegionInput.value = region ? region.regionName : ''
  getCities()
}

const selectCity = (city: City | null) => {
  selectedCity.value = city
  selectedCityInput.value = city ? city.locality : ''
}

const selectedLocation = computed(() => {
  let text = t('address.select.button.label')

  if (selectedCity.value) {
    text = selectedCity.value?.locality
  } else if (selectedRegion.value) {
    text = selectedRegion.value?.regionName
  } else if (selectedCountry.value) {
    text = selectedCountry.value?.name
  }

  return text
})

const selectedStep = computed(() => {
  let text = ''

  if (!selectedCountry.value) {
    text = t('address.select.modal.title.country')
  } else if (selectedCity.value) {
    text = t('address.select.modal.title.city')
  } else if (selectedRegion.value) {
    text = t('address.select.modal.title.city')
  } else if (selectedCountry.value) {
    text = t('address.select.modal.title.region')
  }

  return text
})


const show = () => {
  actionsStore.toSearch = true
  addressStore.onClickByShowButton()
  isModalVisible.value = false
}

const getAvailableRegions = (countryId: Country['id']) => {
  isFetching.value = true
  addressService.getAvailableRegions(countryId)
    .then(regionsData => {
      regions.value = regionsData
      addressStore.setRegions(regionsData)
    })
    .catch(() => {
      toast(t('address.toast.error'), {variant: 'danger'})
    })
    .finally(() => {
      isFetching.value = false
    })
}

watch(() => ({
  country: selectedCountry.value,
  region: selectedRegion.value,
  city: selectedCity.value,
}), ({country, region, city}) => {

  if (country && !region) {
    getAvailableRegions(country.id)
  }
  if (!city) {
    getCities()
  }
  if(country?.ISO !== addressStore.country?.ISO || !country) {
    actionsStore.setFilter({...actionsStore.filter, buildingFilter: {...actionsStore.filter.buildingFilter, included: [],}, developerFilter: {...actionsStore.filter.developerFilter, included: []}})
  }
})


onBeforeMount(() => {
  addressStore.isAvailableCountriesFetching = true
  addressService.getAvailableCountries()
    .then(availableCountries => {
      countries.value = availableCountries.filter(country => {
        return !!country.buildingsCount
      })
      addressStore.setCountries(availableCountries)
    })
    .catch(() => {
      toast(t('address.toast.error'), {variant: 'danger'})
    })
    .finally(() => {
      addressStore.isAvailableCountriesFetching = false
    })
  if ((selectedCountry.value && !selectedRegion.value) || (selectedCountry.value && selectedRegion.value && !selectedCity.value)) {
    getAvailableRegions(selectedCountry.value?.id)
    selectRegion(selectedRegion.value)
  }
})
</script>

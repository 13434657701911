import {
  InviteRegisterData,
  LoginResponse,
  LogoutResponse,
  RegisterData,
  SetOrganisationResponse,
} from '~/modules/auth/types/auth'
import { AuthHttpApiService } from '~/modules/auth/api/AuthHttpApiService'
import { AuthHttpApi } from '~/modules/auth/interfaces/authHttpApi'
import QuickDealHttpApiService from '~/services/http/quick-deal/QuickDealHttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { AuthTokenResponse } from '~/modules/auth/types/Auth.tokenResponse'
import { AuthToken } from '~/modules/auth/types/Auth.token'
import { Organisation } from '~/common/types/organisation/Organisation'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import SentryLogger from '~/services/sentry/SentryLogger'
import { useAppStateStore } from '~/store/app'
import useOrganisationStore from '~/modules/organisation/store'
import { useUserStore } from '~/modules/user/store'
import { AppHttpApiService } from '~/common/api/app/AppHttpApiService'
import { InitAppData } from '~/common/types/app/init'
import { ORGANISATION_ACCESS_STATUS } from '~/common/constants/organisation/Organisation.accessStatus'
import { User } from '~/modules/user/UserModule'
import { SHARED_ROUTES } from '~/router/constants/Router.authAndGuestRoutes'
import { QdStorage as IQdStorage } from '~/common/storage/interfaces/QdStorage'
import { Capacitor } from '@capacitor/core'
import { PLATFORMS } from '~/common/constants/platform/Platforms'
import QdStorage from '~/common/storage/QdStorage'
import { MobilePlatformStorage } from '~/common/storage/MobilePlatformStorage'
import { PushNotificationService } from '~/mobile-platforms/push-notifications/PushNotificationService'

export class AuthService {
  http: AuthHttpApi

  httpInstance: QuickDealHttpApiService

  static ERROR_STATUS = 422

  static UNAUTHORIZED_ERROR_STATUS = 401

  static FORBIDDEN_ERROR_STATUS = 403

  accessToken: AuthToken | null = null
  refreshToken: AuthToken | null = null

  private readonly storage: IQdStorage

  constructor() {
    const nuxtApp = useNuxtApp()
    // TODO нужно разобраться, почему он выдает ошибку когда используется экземпляр из nuxtApp.$appStorage
    this.storage = Capacitor.getPlatform() === PLATFORMS.web ? new QdStorage() : new MobilePlatformStorage()

    this.httpInstance = nuxtApp.$qdHttpApiInstance
    this.http = new AuthHttpApiService(this.httpInstance)
  }

  public login(username: string, password: string, invitationSecret?: string): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      let loginPromise: Promise<HttpHandledResponse<LoginResponse>> | null = null
      if (invitationSecret) {
        loginPromise = this.http.loginByInvitation<LoginResponse>(username, password, invitationSecret)
      } else {
        loginPromise = this.http.login<LoginResponse>(username, password)
      }
      loginPromise.then(response => {
        const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt } = response.data.tokens
        this.httpInstance.setAuthToken(accessToken)

        if (response.data.organisations.length === 1) {
          this.setAccessToken({ token: accessToken, expiresAtTime: accessTokenExpiresAt })
          this.setRefreshToken({ token: refreshToken, expiresAtTime: refreshTokenExpiresAt })
        }
        this.accessToken = { token: accessToken, expiresAtTime: accessTokenExpiresAt }
        this.refreshToken = { token: refreshToken, expiresAtTime: refreshTokenExpiresAt }
        this.httpInstance.setAuthToken(accessToken)

        resolve(response.data)
      })
        .catch(error => {
          if (error.response && error.response.status === AuthService.ERROR_STATUS) {
            reject(error)
          } else {
            SentryLogger.captureScopeException(
              error,
              {
                message: 'Не удалось авторизоваться',
              },
            )
            reject(error)
          }
        })
    })
  }

  public async logout():Promise<LogoutResponse> {
    if (Capacitor.isNativePlatform()) {
      await new PushNotificationService().removeToken()
    }
    return new Promise((resolve, reject) => {
      this.http.logout<LogoutResponse>()
        .then(response => {
          this.onLogout()
          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.status === AuthService.ERROR_STATUS) {
            reject(error)
          } else {
            SentryLogger.captureScopeException(
              error,
              {
                message: 'Не удалось разлогиниться',
              },
            )
            reject(error)
          }
        })
    })
  }

  public createOrganisation():Promise<Organisation> {
    return new Promise((resolve, reject) => {
      this.http.createOrganisation<Organisation>()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось создать организацию',
          })
          reject(error)
        })
    })
  }

  public setUserOrganisation(organisation: Organisation):Promise<SetOrganisationResponse> {
    return new Promise((resolve, reject) => {
      this.http.setUserOrganisation<SetOrganisationResponse>(organisation.id)
        .then(response => {
          this.setAccessToken(this.accessToken)
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось присвоить пользователя к организации',
          })
          reject(error)
        })
    })
  }

  public signup(registerData: RegisterData) {
    return new Promise((resolve, reject) => {
      this.http.register<LoginResponse>(registerData)
        .then(response => {
          const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt } = response.data.tokens
          this.httpInstance.setAuthToken(accessToken)
          this.setAccessToken({ token: accessToken, expiresAtTime: accessTokenExpiresAt })
          this.setRefreshToken({ token: refreshToken, expiresAtTime: refreshTokenExpiresAt })

          resolve(response.data)
        })
        .catch(error => {
          if (error.response && error.response.status === AuthService.ERROR_STATUS) {
            reject(error)
          } else {
            SentryLogger.captureScopeException(error, {
              message: 'Не удалось зарегистристрироваться ',
            })
            reject(error)
          }
        })
    })
  }

  public invitationSignup(registerData: InviteRegisterData) {
    return new Promise((resolve, reject) => {
      this.http.invitationRegister<LoginResponse>(registerData)
        .then(response => {
          const { accessToken, accessTokenExpiresAt, refreshToken, refreshTokenExpiresAt } = response.data.tokens
          this.httpInstance.setAuthToken(accessToken)
          this.setAccessToken({ token: accessToken, expiresAtTime: accessTokenExpiresAt })
          this.setRefreshToken({ token: refreshToken, expiresAtTime: refreshTokenExpiresAt })

          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Проблема с приглашением для регистрации',
          })
          reject(error)
        })
    })
  }

  public recoverPassword(email: string) {
    return new Promise((resolve, reject) => {
      this.http.recoverPassword<LoginResponse>(email)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось восстановить пароль',
          })
          reject(error)
        })
    })
  }

  public resetPassword(email: string, password: string, resetToken: string) {
    return new Promise((resolve, reject) => {
      this.http.resetPassword<LoginResponse>(email, password, resetToken)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось сбросить пароль',
          })
          reject(error)
        })
    })
  }

  public getOrganisationInfo(invitationSecret: string | null) {
    return new Promise((resolve, reject) => {
      if (invitationSecret) {
        this.http.getOrganisationInfo<LoginResponse>(invitationSecret)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            SentryLogger.captureScopeException(error, {
              message: 'Не удалось получить данные об организации',
            })
            reject(error)
          })
      } else {
        reject(new Error('invitationSecret required'))
      }
    })
  }

  public updateTokens(refreshToken: string): Promise<AuthTokenResponse> {
    return new Promise((resolve, reject) => {
      this.http.updateTokens<AuthTokenResponse>(refreshToken)
        .then(response => {
          this.httpInstance.setAuthToken(response.data.accessToken)
          this.setAccessToken({ token: response.data.accessToken, expiresAtTime: response.data.accessTokenExpiresAt })
          this.setRefreshToken({ token: response.data.refreshToken, expiresAtTime: response.data.refreshTokenExpiresAt })
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Проблема с обновлением токенов',
            refreshToken,
          })
          reject(error)
        })
    })
  }

  public async getAccessToken(): Promise<AuthToken | null | undefined> {
    const accessToken = await this.storage.getItem('accessToken') as AuthToken | null | undefined
    return accessToken
  }

  public async setAccessToken(token: AuthToken | null): Promise<void> {
    await this.storage.setItem('accessToken', token)
  }

  public async getRefreshToken(): Promise<AuthToken | null | undefined> {
    const refreshToken = await this.storage.getItem('refreshToken') as AuthToken | null | undefined
    return refreshToken
  }

  public async setRefreshToken(token: AuthToken | null): Promise<void> {
    await this.storage.setItem('refreshToken', token)
  }

  public getExchangeToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.getExchangeToken()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить токен',
          })
          reject(error)
        })
    })
  }

  public onLogout(): void {
    this.storage.clear()
    this.httpInstance.resetAuthToken()
    useUserStore().setUser({} as User)
    useOrganisationStore().setOrganisation({} as Organisation)
    useRouter().replace({ name: ROUTES_NAMES.home })
  }

  private setUserAndOrganisationToStore(user: User, organisation: Organisation) {
    const organisationStore = useOrganisationStore()
    const userStore = useUserStore()

    userStore.setUser(user)
    organisationStore.setOrganisation(organisation)
  }

  private checkOrganisationStatusAndSetData(data: InitAppData, toName: string) {
    const { user, organisation } = data

    if (organisation.emAccessStatus !== ORGANISATION_ACCESS_STATUS.granted && !SHARED_ROUTES.includes(toName)) {
      useRouter().replace({ name: ROUTES_NAMES.home }).then(() => {
        this.setUserAndOrganisationToStore(user, organisation)
      })
    } else {
      this.setUserAndOrganisationToStore(user, organisation)
    }
  }

  public initApp(authToken: string, withGlobalFetching = true, toName = ''): Promise<InitAppData> {
    return new Promise((resolve, reject) => {
      const appStore = useAppStateStore()
      this.httpInstance.setAuthToken(authToken)
      const appHttpApiService = new AppHttpApiService(this.httpInstance)

      if (withGlobalFetching) {
        appStore.setIsFetchingApp(true)
      }

      appHttpApiService.init()
        .then(response => {
          this.checkOrganisationStatusAndSetData(response.data, toName)

          if (Capacitor.isNativePlatform()) {
            new PushNotificationService().init()
          }

          resolve(response.data)
        })
        .catch(error => {
          if (error.status === AuthService.UNAUTHORIZED_ERROR_STATUS) {
            reject(error)
          } else if (error.status === AuthService.FORBIDDEN_ERROR_STATUS && error.data?.description === 'userFired') {
            this.logout()
            reject(error)
          } else {
            SentryLogger.captureScopeException(error, {
              message: 'Проблема с инициализацией',
            })
            reject(error)
          }
        })
        .finally(() => {
          if (withGlobalFetching) {
            appStore.setIsFetchingApp(false)
          }
        })
    })
  }
}

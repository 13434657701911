<template>
  <tr
    v-for="(flat, inx) in flats"
    :key="inx"
    class="!border-b-[10px] !border-white"
    :class="[getTableRowClass(flat.status), {'border-b-[6px]' : isLg}]"
  >
    <td>
      <div class="flex justify-center">
        <img
          class="w-16 lg:w-20 "
          :class="{'cursor-pointer' : flat.layout?.images}"
          :src="flat.layout?.images ? flat.layout.images[0].link : defaultLayoutImage"
          @click="selectFlat(flat)"
        >
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ getSectionName(flat) }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ flat.floorNumber }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ flat.flatNumber }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ flat.totalArea }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ flat.kitchenArea }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ flat.windowsViewTypes && flat.windowsViewTypes.length ? flat.windowsViewTypes.map((type) => $t(`flat.windowViewType.options.${type}.label`).toLowerCase()).join(', ') : '' }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ flat.cardinalDirections && flat.cardinalDirections.length ? flat.cardinalDirections.map((direction) => $t(`flat.cardinalDirections.options.${direction}.label`)).join(', ') : '' }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ $n(flat.price.amount) }} {{ $t(`currency.options.${flat.price.currency}`) }}
      </div>
    </td>
    <td>
      <div class="flex items-center gap-x-2">
        {{ $t('flat.pricePerSquareMeter',
              {amount: $n(flat.pricePerSquareMeter?.amount ?? 0), currency: $t(`currency.options.${flat.pricePerSquareMeter?.currency ?? CURRENCY.usd}`)}
        ) }}
      </div>
    </td>
    <td>
      <div
        v-if="forPage === 'building'"
        class="flex items-center gap-x-2 font-semibold"
        :class="getFlatStatusClass(flat.status)"
      >
        {{ $t(`flat.status.options.${flat.status}`) }}
      </div>
      <div
        v-else
        class="flex items-center gap-x-2 font-semibold"
      >
        <slot
          name="building"
          :building="flat.building"
        />
      </div>
    </td>
    <td v-if="isDisplayAgentMotivation">
      <building-carousel-btn-motivation
        v-if="flat.agentMotivation?.percent"
        :flat="flat"
        :building="building"
        :entity="AGENT_MOTIVATION_ENTITY.flat"
      >
        <template #button>
          <div
            class="flex items-center flex-nowrap gap-x-2"
          >
            <span>
              {{ `${$n(flat.agentMotivation.amount)}&nbsp;${$t(`currency.options.${flat.agentMotivation.currency}`)}` }}
            </span>
            <span>
              {{ `(${flat.agentMotivation.percent}%)` }}
            </span>
          </div>
        </template>
      </building-carousel-btn-motivation>
      <div v-else>
        —
      </div>
    </td>
    <td>
      <slot
        name="actions"
        :flat="flat"
      >
        <component
          :is="flatBtns"
          :flat="flat"
          button-class="bg-white"
        />
      </slot>
    </td>
  </tr>
  <modal-or-bottom-sheet
    v-model="isFlatImageModalOpen"
    :modal-props="{size: 'lg'}"
    @close="selectedFlat = null"
  >
    <custom-carousel
      :images="flatImages"
      max-height="50vh"
      min-height="50vh"
      :is-open-modal-on-click="false"
    />
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import defaultLayoutImage from '~/assets/images/building/layout-default-image.svg'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import BuildingCarouselBtnMotivation from '~/modules/building/components/carousel/BuildingCarouselBtnMotivation.vue'
import type { Flat } from '~/common/types/flat/Flat'
import { FlatStatus } from '~/common/types/flat/Flat.status'
import { FLAT_STATUS_CLASSES } from '~/common/constants/flat/Flat.statusClasses'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { CURRENCY } from '~/common/constants/money/Currency'
import { AvailableCountry } from '~/common/types/country/Country.available'
import SentryLogger from '~/services/sentry/SentryLogger'
import { getEntityName } from '~/common/helpers/getEntityName'
import { Building } from '~/modules/building/types/Building'
import DISPLAYING_TYPE from '~/modules/building/constants/Building.displayingType'
import { AGENT_MOTIVATION_ENTITY } from '~/modules/building/constants/AgentMotivation.entity'
import { useAppStateStore } from '~/store/app'
import CustomCarousel from '~/ui/carousels/CustomCarousel.vue'
import { Image } from '~/common/types/image/Image'

const props = defineProps({
  flats: {
    type: Array as PropType<Flat[]>,
    required: true,
  },
  forPage: {
    type: String as PropType<'building' | 'suggestion'>,
    default: 'building',
  },
  displayType: {
    type: String as PropType<DISPLAY_TYPE>,
    default: DISPLAY_TYPE.basic,
  },
  building: {
    type: Object as PropType<Building>,
    required: true,
  },
})

const isFlatImageModalOpen = ref(false)
const appStateStore = useAppStateStore()

const displayingType: ComputedRef<DISPLAYING_TYPE> = inject('displayingType', DISPLAYING_TYPE.guest)

const isDisplayAgentMotivation = computed(() => displayingType.value === DISPLAYING_TYPE.basic && !appStateStore.clientMode)

const selectedFlat = ref<Flat | null>(null)

const selectFlat = (flat: Flat) => {
  if (flat.layout?.images.length) {
    selectedFlat.value = flat
    isFlatImageModalOpen.value = true
  }
}

const flatImages = computed(() => {
  const images: Array<Image> = []
  const layoutImages = selectedFlat.value?.layout?.images
  const decorationImages = selectedFlat.value?.decoration?.images

  if (layoutImages?.length) {
    images.push(...layoutImages)
  }

  if (decorationImages?.length) {
    images.push(...decorationImages)
  }

  if (images.length) {
    return images
  }

  return [{
    link: defaultLayoutImage,
  }]
})

const injectedFlatAddressCountryCode: AvailableCountry | undefined = inject('flatAddressCountryCode')
const flatAddressCountryCode = props.flats[0]?.building?.address?.countryIsoCode || props.flats[0]?.address?.countryIsoCode || injectedFlatAddressCountryCode

if (!flatAddressCountryCode) {
  throw new Error('Не передан flatAddressCountryCode')
}
const getSectionName = (flat: Flat): string => {
  const flatName = getEntityName({
    name: flat.section.name,
    internationalName: flat.section.internationalName,
    entityCountry: flatAddressCountryCode,
  })
  if (!flatName) {
    SentryLogger.captureMessage('Не удалось получить имя для секции квартиры', { flat })
  }
  return flatName || ''
}

const isLg = computed(() => useBreakpoints().smallerOrEqual('xl').value)
const getTableRowClass = (flatStatus: FlatStatus) => (flatStatus ? FLAT_STATUS_CLASSES[flatStatus].bg : '')

const getFlatStatusClass = (flatStatus: FlatStatus) => (flatStatus ? FLAT_STATUS_CLASSES[flatStatus].text : '')

const flatBtns = computed(() => {
  switch (props.displayType) {
    case DISPLAY_TYPE.basic:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsBasic.vue'))
    case DISPLAY_TYPE.suggestionManagement:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsSuggestionManagement.vue'))
    case DISPLAY_TYPE.presentation:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsPresentation.vue'))
    case DISPLAY_TYPE.buildingPresentation:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsBuildingPresentation.vue'))
    default:
      return defineAsyncComponent(() => import('~/components/flats/buttons/FlatsBtnsBasic.vue'))
  }
})

</script>

<template>
  <div
    v-if="building"
    class="p-3 flex gap-x-4 bg-white rounded-md"
  >
    <img
      class="h-full w-[110px] rounded-md"
      :src="building.images[0]?.link ?? defaultImage"
    >
    <div class="flex flex-col justify-between">
      <h2 class="text-neutral-900 text-lg font-medium">
        {{
          getEntityName({
            name: building.name,
            internationalName: building.internationalName,
            entityCountry: building.address?.countryIsoCode
          })
        }}
      </h2>
      <span class="text-neutral-500 font-semibold">
        {{ $t('entityActions.search.flat', {count: flatsInfo.flatsCount}) }}
      </span>
      <div class="flex gap-x-2 font-semibold text-neutral-900 text-sm">
        <span v-if="flatsInfo.minArea">
          {{ $t('building.flatsInfo.totalArea.from', {value: flatsInfo.minArea}) }}
        </span>
        <span v-if="flatsInfo.minPrice">
          {{ $t('building.flatsInfo.price.from') }} {{ new Intl.NumberFormat($i18n.locale, { notation: 'compact' }).format(flatsInfo.minPrice) }} {{ $t(`currency.options.${flatsInfo.currency}`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Building } from '~/modules/building/BuildingModule'
import defaultImage from '~/assets/images/building/building-default-image.png'
import { CURRENCY } from '~/common/constants/money/Currency'
import { getEntityName } from '~/common/helpers/getEntityName'

const props = defineProps({
  building: {
    type: Object as PropType<Building | null>,
    required: true,
  },
})

const flatsInfo = computed(() => {
  if (props.building) {
    const existedFlatsInfo = props.building.flatsInfo.filter(info => info.count)
    const prices = existedFlatsInfo.map(info => info?.price?.from).filter(price => price)
    const minPrice = prices.length ? Math.min(...prices) : 0
    const currency = existedFlatsInfo[0]?.price?.currency ?? CURRENCY.usd
    const areas = existedFlatsInfo.map(info => info?.totalArea?.from).filter(area => area)
    const minArea = areas.length ? Math.min(...areas) : 0
    const flatsCount = existedFlatsInfo.map(info => info.count).reduce((sum, count) => sum + count, 0)

    return { minPrice, minArea, flatsCount, currency }
  }
  return { minPrice: 0, minArea: 0, flatsCount: 0, currency: CURRENCY.usd }
})
</script>

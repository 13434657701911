{
  "common": {
    "and": "და",
    "show": "ნახვა",
    "showAll": "ყველაფრის ნახვა",
    "showMore": "მეტის ჩვენება",
    "hide": "დამალვა",
    "more": "მეტი",
    "readMore": "წაიკითხე მეტი",
    "notChosen": "არ არის შერჩეული",
    "noOptions": "არ არის სასურველი ვარიანტი.",
    "unitsOfMeasurement": {
      "label": "ერთეულები",
      "options": {
        "squareMeter": "მ²",
        "squareFoot": "ft²"
      }
    },
    "from": "დან",
    "to": "მდე",
    "of": "გან",
    "in": "ში",
    "or": "ან",
    "yes": "დიახ",
    "no": "არა",
    "go": "გადაკვეთა",
    "send": "გაგზავნა",
    "add": "დამატება",
    "edit": "რედაქტირება",
    "cancel": "გაუქმება",
    "details": "უფრო ვრცლად",
    "inDevelopment": "განვითარებაში",
    "supportRequestMessage": "აპლიკაციის გაშვებისას მოხდა შეცდომა. გთხოვთ დაუკავშირდეთ ტექნიკურ მხარდაჭერას",
    "weekdays": {
      "short": {
        "monday": "ორშ",
        "tuesday": "სამ",
        "wednesday": "ოთხ",
        "thursday": "ხუთ",
        "friday": "პარ",
        "saturday": "შაბ",
        "sunday": "კვი"
      }
    },
    "months": {
      "full": {
        "january": "იანვარი",
        "february": "თებერვალი",
        "march": "მარტი",
        "april": "აპრილი",
        "may": "მაისი",
        "june": "ივნისი",
        "july": "ივლისი",
        "august": "აგვისტო",
        "september": "სექტემბერი",
        "october": "ოქტომბერი",
        "november": "ნოემბერი",
        "december": "დეკემბერი"
      },
      "short": {
        "january": "იან",
        "february": "თებ",
        "march": "მარ",
        "april": "აპრ",
        "may": "მაი",
        "june": "ივნ",
        "july": "ივლ",
        "august": "აგვ",
        "september": "სექ",
        "october": "ოქტ",
        "november": "ნოე",
        "december": "დეკ"
      },
      "genitive": {
        "january": "იანვარი",
        "february": "თებერვალი",
        "march": "მარტი",
        "april": "აპრილი",
        "may": "მაისი",
        "june": "ივნისი",
        "july": "ივლისი",
        "august": "აგვისტო",
        "september": "სექტემბერი",
        "october": "ოქტომბერი",
        "november": "ნოემბერი",
        "december": "დეკემბერი"
      }
    },
    "time": {
      "at": "ში",
      "from": "თან",
      "to": "დო",
      "up": "მდე",
      "label": {
        "from": "დროიდან",
        "to": "დრომდე"
      }
    },
    "terms": {
      "day": "0 days | {count} day | {count} days"
    },
    "today": "დღეს",
    "tomorrow": "ხვალ",
    "upload": "ატვირთვა",
    "available": "ხელმისაწვდომია",
    "language": "ენა",
    "download": "ჩამოტვირთვა",
    "all": "ყველა",
    "contacts": "კონტაქტები",
    "save": "შენახვა",
    "noPhoto": "ფოტო არ არის",
    "countries": {
      "prepositional": {
        "ru": "რუსეთი",
        "ge": "საქართველო",
        "kz": "ყაზახეთი",
        "uz": "უზბეკეთი",
        "ae": "არაბთა გაერთიანებული საამიროები",
        "id": "ინდ ონეზია",
        "cy": "კვიპროსი",
        "th": "ტ აილ  ანდ ი"
      }
    },
    "downloadApp": "ჩამოტვირთეთ ჩვენი აპლიკაცია"
  },
  "pageTitles": {
    "home": "მთავარი",
    "login": "შესვლა",
    "signup": "რეგისტრაცია",
    "fixation": "ფიქსირება",
    "signupInvitation": "რეგისტრაცია - მოწვევა",
    "forgotPassword": "პაროლის აღდგენა",
    "buildingPage": "{value}",
    "presentationBuildingPage": "{value}",
    "presentationBuildingPageFromSuggestion": "{value}",
    "market": "მაღაზია",
    "suggestions": "რჩევები",
    "suggestionPage": "რჩევა N{value}",
    "notFound": "გვერდი ვერ მოიძებნა",
    "forbidden": "წვდომა დაუშველელია",
    "suggestionsManagement": "რჩევების მართვა",
    "clients": "კლიენტები",
    "changeList": "განახლებები",
    "developerPage": "{value}",
    "journal": "ჟურნალი",
    "journalNews": "სიახლეები",
    "profileDelete": "პროფილის წაშლა"
  },
  "feedback": {
    "label": "უკუკავშირი",
    "options": {
      "suggestIdea": "იდეის შეთავაზება",
      "dataError": "შეატყობინეთ შეცდომის მონაცემებს შესაბამისობაში",
      "serviceError": "შეატყობინეთ შეცდომის შესახებ სერვისში"
    }
  },
  "favoritesLinks": {
    "label": "ფავორიტები",
    "reset": "გადაყვანა",
    "add": "გვერდის დამატება",
    "confirm": {
      "title": "ნარჩევულ გვერდებს გადაყვანას გსურთ?",
      "toasts": {
        "success": "არჩეული გვერდები წაშლილია წარმატებით",
        "error": "შეცდომა მიღებულია არჩეულ გვერდებზე გადაყვანისას"
      }
    }
  },
  "cookie": {
    "title": "შეტყობინება cookie-ზე",
    "text": "ჩვენ ვიყენებთ cookie, რათა საიტი უფრო მოსახერხებელი და უსაფრთხო გავხადოთ. ამ საიტის გამოყენებით თქვენ ეთანხმებით cookie-ს გამოყენებას.",
    "readMore": "Უფრო ვრცლად",
    "accept": "კარგი, აღარ აჩვენო"
  },
  "currency": {
    "label": "ვალუტა",
    "options": {
      "rub": "₽",
      "usd": "$",
      "eur": "€",
      "kzt": "₸",
      "thb": "฿",
      "gbp": "£"
    }
  },
  "pagination": {
    "goToPage": "გადადით გვერდზე"
  },
  "auth": {
    "description": {
      "label": "Estatyx",
      "welcome": "მოგესალმებით !",
      "into": "შედით თქვენს ანგარიშზე"
    },
    "login": {
      "label": "შესვლა",
      "iAgreeText": "თანხმობას ვაცხადებ  ჩემი პერსონალური მონაცემების დამუშავებაზე თანახმად",
      "privatePolicy": "კონფიდენციალობის პოლიტიკასთან",
      "licenseAgreement": "სალიცენზიო შეთანხმების პირობებით"
    },
    "resetPassword": {
      "label": "პაროლის გადაყენება",
      "description": "თქვენი ახალი პაროლი უნდა განსხვავებული იყოს წინდავი გამოყენებულ პაროლებსაც.",
      "setPassword": "დააყენეთ ახალი პაროლი",
      "toast": {
        "success": "პაროლი წარმატებით შეიცვალა",
        "error": "პაროლის შეცვლისას შეცდომა მოხდა"
      }
    },
    "signup": {
      "label": "რეგისტრაცია",
      "iAgreeText": "ვეთანხმები პერსონალური მონაცემების დამუშავებას შესაბამისად",
      "privatePolicy": "კონფიდენციალურობის პოლიტიკა",
      "licenseAgreement": "სალიცენზიო ხელშეკრულების პირობები"
    },
    "register": {
      "label": "რეგისტრაცია",
      "toast": {
        "label": {
          "success": "თქვენ წარმატებით დარეგისტრირდით!",
          "error": "რეგისტრაციისას მოხდა შეცდომა"
        }
      }
    },
    "organisation": {
      "description": {
        "selectOrganisation": "თქვენ უნდა აირჩიოთ ორგანიზაცია",
        "someOrganisation": "თქვენ დარეგისტრირებული ხართ რამდენიმე ორგანიზაციაში. აირჩიეთ ორგანიზაცია, სადაც განაგრძობთ მუშაობას აპლიკაციაში ან შექმენით ახალი.",
        "attention": "ყურადღება!",
        "warning": "ღილაკზე \"შესვლა\" დაწკაპუნებით შეიქმნება ახალი ორგანიზაცია."
      },
      "actions": {
        "selectOrganisation": {
          "error": "ორგანიზაციის არჩევისას მოხდა შეცდომა"
        },
        "createOrganisation": {
          "label": "ისევ პაროლი",
          "error": "თქვენი პაროლის თქვენს ელფოსტაზე გაგზავნისას მოხდა შეცდომა"
        }
      }
    },
    "password": {
      "label": "შესვლა",
      "placeholder": "შეიყვანეთ პაროლი"
    },
    "passwordConfirm": {
      "label": "ისევ პაროლი",
      "placeholder": "პაიმეორეთ პაროლი",
      "error": "პაროლის არ ემთხვევა"
    },
    "forgotPassword": {
      "label": "დაგავიწყდათ პაროლი?",
      "description": "შეიყვანეთ თქვენი ელექტრონული ფოსტის მისამართი და ჩვენ გამოგიგზავნით პაროლის აღდგენის ინსტრუქციებს.",
      "send": "გაგზავნა",
      "toast": {
        "label": {
          "success": "პაროლის აღდგენის ბმული გამოგზავნილია ელექტრონული ფოსტით : {value}!",
          "error": "თქვენი პაროლის თქვენს ელფოსტაზე გაგზავნისას მოხდა შეცდომა"
        }
      }
    },
    "haveAccount": {
      "label": "უკვე გაქვთ ანგარიში?"
    },
    "username": {
      "label": "შესვლა",
      "placeholder": "შეიყვანეთ ელ.ფოსტა ან ტელეფონი",
      "error": "ველი უნდა იყოს ტელეფონის ნომერი ან ელექტრონული ფოსტის მისამართი"
    },
    "name": {
      "label": "სახელი",
      "placeholder": "შეიყვანეთ თქვენი სახელი",
      "error": "სახელის ველი აუცილებელია"
    },
    "lastName": {
      "label": "გვარი",
      "placeholder": "შეიყვანეთ გვარი"
    },
    "userEmail": {
      "label": "E-mail",
      "placeholder": "შეიყვანეთ ელფოსტა",
      "error": "ველი უნდა იყოს ელფოსტის მისამართი"
    },
    "userPhone": {
      "label": "ტელეფონის ნომერი",
      "placeholder": "შეიყვანეთ ტელეფონის ნომერი",
      "error": "ველი უნდა იყოს ელფოსტის მისამართი"
    },
    "backendValidation": {
      "error": "მომხმარებლის სახელი და პაროლი არასწორია"
    },
    "invitationSignup": {
      "messages": {
        "success": "თქვენს მიერ შექმნილი ანგარიში დაემატება ორგანიზაციას {value}.",
        "linkError": "მოწვევის ბმული არასწორია. მოითხოვეთ ახალი ბმული ან დაწერეთ ტექნიკური მხარდაჭერა {value}",
        "error": "ვერ მიიღო ინფორმაცია ორგანიზაციის შეცდომის შესახებ. error: {value}"
      },
      "toLogin": "შეუერთდით არსებული ანგარიშიდან",
      "loginInfoMessage": "თქვენი ანგარიში დაემატება ორგანიზაციას {value}"
    },
    "exchangeToken": {
      "toast": {
        "label": {
          "error": "CRM-ში შესვლა ვერ მოხერხდა"
        }
      }
    }
  },
  "organisation": {
    "form": {
      "id": {
        "label": "ორგანიზაციის ID"
      },
      "createAt": {
        "label": "ორგანიზაციის დაკავშირება"
      },
      "name": {
        "label": "დასახელება",
        "placeholder": "შეიყვანეთ ორგანიზაციის დასახელება"
      },
      "email": {
        "label": "E-mail",
        "placeholder": "შეიყვანეთ ორგანიზაციის დასახელება"
      },
      "phone": {
        "label": "მთავარი ტელეფონი",
        "placeholder": "+ 7 901 234 56 78"
      },
      "site": {
        "label": "ორგანიზაციის ვებსაიტი",
        "placeholder": "შეიყვანეთ საიტი ან დატოვეთ ველი ცარიელი"
      },
      "country": {
        "label": "ქვეყანა"
      },
      "type": {
        "label": "ანგარიშის ტიპი"
      },
      "city": {
        "label": "ქალაქი",
        "placeholder": {
          "label": "შეიყვანეთ ქალაქი და აირჩიეთ სიიდან"
        }
      },
      "actions": {
        "save": {
          "label": "შენახვა"
        },
        "fireUser": {
          "label": "ნამდვილად გსურთ გათიშა {firstName} {lastName} ?"
        }
      },
      "toast": {
        "success": {
          "label": "ორგანიზაციის მონაცემები წარმატებით შენახულია!"
        },
        "error": {
          "label": "მონაცემთა შენახვისას მოხდა შეცდომა."
        }
      },
      "toasts": {
        "fireUser": {
          "success": "მომხმარებელი წარმოიდგინა წარმოების!",
          "error": "მომხმარებელის გათიშვის დროს შეცდომა მიღებულია"
        },
        "updateRole": {
          "success": "სტატუსი წარმოიდგინა წარმოების!",
          "error": "სტატუსის შეცვლასას შეცდომა მიღებულია"
        }
      }
    },
    "types": {
      "agency": {
        "label": "უძრავი ქონების სააგენტო"
      },
      "privateRealtor": {
        "label": "კერძო რეალტორი"
      },
      "developer": {
        "label": "დეველოპერი"
      },
      "integrator": {
        "label": "ინტეგრატორი/პარტნიორი Bitrix24"
      },
      "managementCompany": {
        "label": "მართვის კომპანია"
      },
      "other": {
        "label": "სხვა"
      }
    },
    "moderation": {
      "onModeration": {
        "label": "ზომიერება",
        "title": "თქვენი ორგანიზაცია გაიგზავნა მოდერაციისთვის",
        "text": "სერვისზე წვდომა უზრუნველყოფილი იქნება გადამოწმების შემდეგ"
      },
      "denied": {
        "label": "წვდომა შეზღუდულია",
        "title": "წვდომა შეზღუდულია",
        "text": "დამატებითი ინფორმაციისთვის, გთხოვთ, დაუკავშირდეთ თქვენს პარტნიორ მენეჯერს"
      },
      "contacts": {
        "label": "კონტაქტები კომუნიკაციისთვის"
      }
    },
    "fired": {
      "title": "წვდომა შეზღუდულია",
      "error": "წვდომის აღსადგენად დაუკავშირდით თქვენს ორგანიზაციაში პასუხისმგებელ პირს ან შექმენით ახალი ორგანიზაცია",
      "create": "შექმენით ორგანიზაცია"
    },
    "settings": {
      "page": {
        "toggle": {
          "options": {
            "profile": "პროფილი",
            "settings": "პარამეტრები",
            "integration": "ინტეგრაცია",
            "employees": "თანამშრომლები",
            "roles": "როლები"
          }
        },
        "profile": {
          "title": "კომპანიის პროფილი"
        },
        "settings": {
          "title": "პარამეტრები"
        },
        "integration": {
          "title": "ინტეგრაცია"
        },
        "employees": {
          "title": "თანამშრომლები",
          "invitation": {
            "link": "მოწვევის ბმული",
            "tooltip": {
              "copy": "კოპირება",
              "change": "შეცვლა"
            }
          },
          "table": {
            "head": {
              "id": "ID",
              "phone": "ტელეფონის ნომერი",
              "email": "ფოსტა",
              "role": "როლი"
            }
          }
        },
        "roles": {
          "title": "როლები",
          "user": "მომხმარებე",
          "admin": "ადმინისტრატორი"
        }
      },
      "form": {
        "profile": {
          "id": "ID ორგანიზაციები:",
          "connectionTime": "ორგანიზაციის დაკავშირება:",
          "about": {
            "title": "კომპანიაზე",
            "name": {
              "label": "დასახელება",
              "placeholder": "ორგანიზაციის დასახელება"
            },
            "type": {
              "label": "კომპანიის ტიპი"
            }
          },
          "address": {
            "title": "მდებარეობა",
            "country": {
              "label": "აირჩიეთ ქვეყანა",
              "placeholder": "ქვეყანა"
            },
            "city": {
              "label": "აირჩიეთ ქვეყანა",
              "placeholder": "ქალაქი"
            }
          },
          "contacts": {
            "title": "კონტაქტები",
            "phone": {
              "label": "მთავარუი ტელეფონის ნომერი",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            }
          }
        }
      }
    }
  },
  "validation": {
    "messages": {
      "min": "ველი {label} უნდა იყოს მინიმუმ {length} სიმბოლოები",
      "minValue": "ველი {label} არ უნდა იყოს ნაკლები {min}",
      "max": "ველი {label} უნდა შედგებოდეს მაქსიმუმ {length} სიმბოლოები",
      "required": "{label} ეს  ველი აუცილებელია",
      "url": "ველი {label} უნდა იყოს სწორი URL მისამართით",
      "oneFieldRequired": "{firstLabel} ეს აუცილებელი ველია, თუ ველი \"{secondLabel}\" არ არის შევსებული"
    }
  },
  "address": {
    "select": {
      "button": {
        "label": "ქვეყანა"
      },
      "modal": {
        "title": {
          "choose": "არჩევანი",
          "country": "ქვეყნები",
          "region": "რეგიონი",
          "city": "ქალაქები"
        }
      }
    },
    "allCountries": "ყველა ქვეყანა",
    "country": {
      "placeholder": "შეიყვანეთ  ქვეყანა"
    },
    "region": {
      "placeholder": "შეიყვანეთ რეგიონი",
      "empty": "საცხოვრებელი კომპლექსი არ მოიძებნა"
    },
    "city": {
      "placeholder": "შეიყვანეთ ქალაქი"
    },
    "toast": {
      "error": "ჩატვირთვისას მოხდა შეცდომა"
    }
  },
  "building": {
    "entityName": "საცხოვრებელი კომპლექსი",
    "name": {
      "default": "დასახელების გარეშე"
    },
    "promotions": {
      "label": "შენობის პრომოციები",
      "allPromotions": "ყველა შენობის პრომოციები",
      "showAllPromotions": "ყველა შენობის პრომოციების ნახვა",
      "showButton": "ნახვა",
      "promotionsNotFound": "შენობის პრომოციები არ მოიძებნა",
      "priceWithoutPromo": "ფასი ნაჩვენებია აქციებისა და ფასდაკლებების გარეშე",
      "promotionInterval": "აქცია მოქმედებს",
      "unlimited": "უვადოდ"
    },
    "classes": {
      "label": "საცხოვრებელი კომპლექსის კლასი",
      "business": {
        "label": "ბიზნეს"
      },
      "comfort": {
        "label": "კომფორტი"
      },
      "economy": {
        "label": "ეკონომ"
      },
      "elite": {
        "label": "ელიტური"
      }
    },
    "developers": {
      "notFound": "სამწუხაროდ, ჯერ არ არსებობს სტატისტიკა თქვენს მიერ არჩეულ რეგიონში დეველოპერების შესახებ"
    },
    "banks": {
      "notFound": "სამწუხაროდ, ჯერ არ არის საბანკო სტატისტიკა თქვენს მიერ არჩეულ რეგიონში"
    },
    "agencies": {
      "notFound": "სამწუხაროდ, ჯერ არ არსებობს სააგენტოს სტატისტიკა თქვენს მიერ არჩეულ რეგიონში"
    },
    "popular": {
      "label": "პოპულარული საცხოვრებელი კომპლექსი",
      "jkNotFound": "სამწუხაროდ, პოპულარობის სტატისტიკა არ არსებობს საცხოვრებელი კომპლექსი"
    },
    "constructionProgress": {
      "quarter": {
        "label": "{value} კვარტალ"
      },
      "photos": {
        "label": {
          "full": "ფოტოები არ არის | {count} ფოტოები | {count} ფოტოები | {count} ფოტოები"
        }
      },
      "video": {
        "label": {
          "full": "ვიდეო არ არის | {count} ვიდეო | {count} ვიდეო | {count} ვიდეო"
        }
      },
      "options": {
        "all": "ყველა სახლი",
        "building": "გენერალური გეგმა"
      }
    },
    "houses": {
      "label": {
        "short": "არ არის შენობები | {count} ხ. | {count} ხ. | {count} ხ.",
        "full": "არ არის შენობები | {count} შენობა | {count} შენობა | {count} შენობა"
      },
      "allHouses": "ყველა სახლი"
    },
    "contacts": {
      "label": "კონტაქტები",
      "forCommunication": "კონტაქტი დასრულებაში"
    },
    "withoutContacts": {
      "label": "არ არის  კონტაქტები"
    },
    "timeConstraints": {
      "quarter": {
        "label": {
          "short": "ბინა."
        },
        "options": {
          "first": "1",
          "second": "2",
          "third": "3",
          "fourth": "4"
        }
      },
      "year": {
        "label": {
          "short": "წ.",
          "full": "წელი"
        }
      },
      "yearQuarter": "{quarter} ბ. {year} წ.",
      "salesStart": {
        "label": "გაყიდვების დაწყება"
      },
      "keysGetting": {
        "label": "გასაღებების გაცემა",
        "gettingNow": "გასაღებების გადაცემა"
      },
      "deadLine": {
        "label": "დასრულების თარიღი"
      }
    },
    "flatsInfo": {
      "flats": {
        "label": {
          "full": "ბინები",
          "short": "ბინები"
        },
        "count": "რაოდენობა",
        "fullCountLabel": "გასაყიდი ბინების რაოდენობა",
        "flatsTotalCountLabel": "ბინების რაოდენობა"
      },
      "flatRoomsCount": {
        "label": "ოთახების",
        "one": "1",
        "two": "2",
        "three": "3",
        "four": "4",
        "five": "5",
        "six": "6",
        "seven": "7",
        "eight": "8",
        "nine": "9+",
        "studio": "სტუდია",
        "notIndicated": {
          "label": "ოთახების რაოდენობა არ არის მითითებული",
          "full": "არის მითითებული",
          "short": "არის მითითებული"
        },
        "openPlan": "უთითებული გეგმა"
      },
      "flatRoomsCountBuildingCard": {
        "one": "1 ო. ბ.",
        "two": "2 ო. ბ.",
        "three": "3 ო. ბ.",
        "four": "4 ო. ბ.",
        "five": "5 ო. ბ.",
        "six": "6 ო. ბ.",
        "seven": "7 ო. ბ.",
        "eight": "8 ო. ბ.",
        "nine": "9+ ო. ბ.",
        "studio": "სტუდია.",
        "notIndicated": {
          "label": "ოთახების რაოდენობა არ არის მითითებული",
          "full": "არ არის მითითებული",
          "short": "არ არის მითითებული."
        },
        "openPlan": "თავისუფალი მოპირკეთება"
      },
      "totalArea": {
        "label": "ფართი",
        "from": "დან {value} მ²"
      },
      "price": {
        "label": "ფასი",
        "from": "დან {value}"
      }
    },
    "metroInfo": {
      "distance": "{value} მინიმალური"
    },
    "motivation": {
      "label": "მოტივაცია"
    },
    "escrow": {
      "label": "ესქრო",
      "options": {
        "true": "მართალია",
        "false": "ტყუილი"
      }
    },
    "actions": {
      "motivation": {
        "label": "მოტივაცია",
        "commission": "კომისია სანამ {value}%-მდე",
        "linkDescriptionPartOne": "გადახდის პირობები და ზომა",
        "linkDescriptionPartSecond": "კომისიის შესახებ წაიკითხეთ",
        "link": "ბმულზე",
        "commissionSum": "სანამ {amount} {currency}",
        "notFound": "კომისია არ არის მოცული"
      },
      "generalPlan": {
        "label": "მთავარი სამოქმედო გეგმა"
      },
      "videoReview": {
        "label": "ვიდეო მიმოხილვ"
      },
      "video": {
        "label": "ვიდეო მიმოხილვა"
      },
      "map": {
        "label": "რუკა"
      },
      "layout": {
        "label": "დასრულება"
      },
      "panorama": {
        "label": "პანორამა"
      },
      "aerialPanorama": {
        "label": "აეროპანორამა"
      },
      "addToSuggestion": {
        "label": "შერჩევაში დამატება"
      },
      "priceStatistics": {
        "label": "ფასის დინამიკა კვ.მ-ზე"
      },
      "addedToSuggestion": {
        "label": "არჩევანს დაემატა"
      },
      "linkToPresentation": {
        "label": "ბმული პრეზენტაციაზე",
        "toast": {
          "success": "ბმული კოპირებულია"
        }
      },
      "lookAtTheMap": {
        "label": "ნახვა რუკაზე"
      },
      "booking": {
        "label": "დაჯავშნა"
      },
      "fixingTheClient": {
        "label": "კლიენტის დაფიქსირება"
      },
      "fixViewing": {
        "label": "გახედვის დაფიქსირება"
      },
      "constructionProgress": {
        "label": "მშენებლობის პროგრესი",
        "emptyConstructionProgress": "მშენებლობის მიმდინარეობის შესახებ ინფორმაცია არ ვრცელდება."
      },
      "addToFavorites": {
        "label": "რჩეულებში დამატება"
      },
      "addToComparison": {
        "label": "შედარებაში დამატება"
      },
      "lookAtTheCondition": {
        "label": "პირობები / ანაზღაურება / რეგულაციები"
      },
      "chessboard": {
        "label": "შედარებით"
      }
    },
    "page": {
      "media": "ფოტო და ვიდეო",
      "totalArea": "ბინები",
      "about": "სახლის შესახებ { value }",
      "decoration": "დასრულება",
      "advantages": "უპირატესობები",
      "flats": {
        "label": {
          "full": "ბინები",
          "short": "ბინები"
        }
      },
      "constructionProgress": {
        "label": {
          "full": "მშენებლობის პროგრესი",
          "short": "მშენებლობის პროგრესი"
        }
      },
      "files": "ფაილები",
      "mortgage": "იპოთეკა",
      "ambassador": {
        "title": "გაიგეთ ყველაფერი ეკსპერტისგან",
        "subtitle": "ჩვენ ვართ ერთადერთი კომპანინა ბაზარზე, რომელიც უზრუნველყოფს უძრავი ქონების ყველაზე დიდ არჩევანს და აანალიზებს თითოეული ქონების ლიკვიდურობას. Estatyx-ის სპეციალისტებმა იციან, როგორ უსაფრთხოდ იყიდონ თქვენი საოცნებო ბინა და მიიღოთ მისგან მაღალი შემოსავალი.",
        "userInfo": "ამბასსადორი {country}",
        "actions": {
          "contactExpert": {
            "label": "ეკსპერტთან კავშირი",
            "messageForWhatsApp": " გამარჯობა.%0Aმესაცხოვრებელი კომპლექსის შესახებ გიკავშირდებით \"{buildingName}\"%0A{buildingLink}"
          },
          "bookViewing": {
            "label": "დემონსტრაციაზე ჩაწერა",
            "messageForWhatsApp": "გამარჯობა.%0Aმინდა ჩავიწერო საცხოვრებელი კომპლექსის დემონსტრაციაზე \"{buildingName}\"%0A{buildingLink}"
          }
        }
      }
    },
    "image": {
      "alt": "საცხოვრებელი კომპლექსის სურათი"
    },
    "state": {
      "label": "სახლის მშენებლობის ეტაპი",
      "viewLabel": "სახლის მშენებლობის ეტაპი",
      "viewValue": "%{value}",
      "options": {
        "built": "სახლი აშენდა, მაგრამ არ შევიდა ექსპლუატაციაში",
        "handOver": "შევიდა ექსპლუატაციაში",
        "unfinished": "მშენებარე"
      }
    },
    "presentation": {
      "actions": {
        "returnToSuggestion": {
          "label": "შერჩევაში დამატება"
        }
      },
      "bookViewing": {
        "textToSend": "მინდა დავგეგმო შეხვედრა საბინაო კომპლექსის სანახავად. {building}. როდის შეგვიძლია მისი ნახვა?"
      },
      "flats": {
        "houses": {
          "managerChosen": "რეალტორის მიერ შერჩეული ქონება"
        }
      }
    }
  },
  "flat": {
    "entityName": "ბინები",
    "popular": {
      "label": "პოპულარული ბინები",
      "flatsNotFound": "სამწუხაროდ, ჯერ არ არსებობს სტატისტიკა თქვენს მიერ არჩეულ რეგიონში პოპულარული ბინების შესახებ"
    },
    "decorationType": {
      "label": "დასრულება",
      "emptyDecoration": "დასრულებების შესახებ ინფორმაცია არ არის.",
      "default": "დასრულება",
      "options": {
        "label": "დასრულების გარეშე",
        "withoutPreparation": {
          "short": "დასრულების გარეშე",
          "full": "დასრულების გარეშე"
        },
        "preparation": {
          "short": "მომზადება.",
          "full": "მომზადება"
        },
        "preFinishing": {
          "short": "წინასწარი გაწმენდა",
          "full": "წინასწარი გაწმენდა"
        },
        "fine": {
          "short": "დასრულება",
          "full": "დასრულება"
        },
        "furnished": {
          "short": "ავეჯით",
          "full": "ავეჯით"
        },
        "partlyFurnished": {
          "short": "ნაწილობრივ კეთილმოწყობილი",
          "full": "ნაწილობრივ კეთილმოწყობილი"
        }
      }
    },
    "windowViewType": {
      "label": "ხედი ფანჯრებიდან",
      "options": {
        "street": {
          "label": "ქუჩის ხედი"
        },
        "yard": {
          "label": "ეზოს ხედი"
        },
        "mountains": {
          "label": "მთის ხედი"
        },
        "yardAndStreet": {
          "label": "ქუჩისა და ეზოს ხედი"
        },
        "sea": {
          "label": "ზღვის ხედი"
        },
        "river": {
          "label": "ნაპირის"
        },
        "city": {
          "label": "ქალაქის"
        }
      }
    },
    "cardinalDirections": {
      "options": {
        "north": {
          "label": "ჩრდი"
        },
        "south": {
          "label": "სამხრეთ"
        },
        "west": {
          "label": "დასავლეთ"
        },
        "east": {
          "label": "აღმოსავლეთ"
        },
        "northeast": {
          "label": "ჩრდი-აღმოსავლეთ"
        },
        "southeast": {
          "label": "სამხრეთ-აღმოსავლეთ"
        },
        "southwest": {
          "label": "სამხრეთ-დასავლეთ"
        },
        "northwest": {
          "label": "ჩრდი-დასავლეთ"
        }
      }
    },
    "paymentType": {
      "label": "გადახდა",
      "options": {
        "mortgage": "იპოთეკა",
        "cash": "ნაღდი ფული",
        "certificate": "სერტიფიკატი",
        "maternityCapital": "ამშობიარო კაპიტალი",
        "installmentPlan": "განვადების გეგმა"
      }
    },
    "contractType": {
      "label": "ხელშეკრულება",
      "options": {
        "assignment": "დათმობა",
        "purchaseAndSaleAgreement": "ხელშეკრულება"
      }
    },
    "totalArea": {
      "label": "საერთო ფართობი",
      "value": "{value} მ²",
      "from": "დან {value} მ²",
      "to": "მდე {value} მ²"
    },
    "kitchenArea": {
      "label": "სამზარეულო ფართობი",
      "value": "{value} მ²"
    },
    "floorNumber": {
      "label": "სართული",
      "value": "{value} სართული",
      "from": "დან {value} სართული",
      "to": "მდე {value} სართული",
      "action": {
        "label": "აირჩიეთ სართული"
      }
    },
    "lastFloor": {
      "label": "ბოლო სართული"
    },
    "notFirstFloor": {
      "label": "არ მინდა პირველი სართული"
    },
    "price": {
      "from": "დან {amount} {currency}",
      "to": "მდე {amount} {currency}"
    },
    "pricePerSquareMeter": "{amount} {currency}/მ²",
    "status": {
      "label": "ხელმისაწვდომია",
      "options": {
        "free": "იყიდება",
        "booked": "დაჯავშნილი",
        "sold": "გაიყიდა",
        "unavailable": "მიუწვდომელია"
      }
    },
    "flatNumber": "ბინის ნომერი № {value}",
    "section": "განყოფილება {value}",
    "table": {
      "entityName": "ცხრილი",
      "section": "განყოფილება",
      "floor": "სართული",
      "flatNumber": "№ ბინის ნომერი",
      "totalArea": "საერთო ფართი",
      "kitchenArea": "სამზარეულო ფართოი",
      "windowViewType": "ფანჯრის ხელზე",
      "cardinalDirection": "კარდინალური მიმართულება",
      "price": "საერთო ფასი",
      "pricePerSquareMeter": "კვადრატულ მეტრის ფასი (მ² ფასი)",
      "status": "სტატუსი",
      "building": "საცხოვრებელი კომპლექსი"
    },
    "card": {
      "flatNumber": "№ {value}"
    },
    "grid": {
      "entityName": "ბადე"
    },
    "chessboard": {
      "entityName": "ბინები ჭადრაკის დაფაზე"
    },
    "order": {
      "full": "{value}შეკვეთა",
      "short": "{value}შეკვეთა"
    },
    "button": {
      "book": {
        "label": "დაჯავშნი"
      },
      "showAvailableFlats": {
        "show": {
          "label": "აჩვენეთ მიუწვდომელი ბინები"
        },
        "hide": {
          "label": "დამალვა მიუწვდომელი ბინები"
        }
      },
      "bookViewing": {
        "label": "დარეგისტრირდით სანახავად",
        "messageForWhatsApp": "ბინის სანახავად მინდა დავნიშნო№ {flatNumber} სახლში {buildingName} სახლში {houseName}%0Aფასი - {price}%0Aმოედანი - {totalArea}%0Aგანლაგება - {layoutName}%0Aდასრულება - {decorationType}%0A%0Aროდის შეგვიძლია ყურება?",
        "messageInLayoutForWhatsApp": "ბინის სანახავად მინდა დავნიშნო განლაგება - {layoutName} სახლში {buildingName} სახლში {houseName}%0Aფასი - {price}%0Aმოედანი - {totalArea}%0A%0Aროდის შეგვიძლია ყურება?",
        "messageDefaultField": "არ არის მითითებული"
      },
      "bookFlat": {
        "label": "დაჯავშნა",
        "messageForWhatsApp": "ბინის დაჯავშნა მინდა № {flatNumber} საცხოვრებელ კომპლექსში {buildingName} სახლში {houseName}%0Aფასი - {price}%0Aფართი - {totalArea}%0Aგანლაგება - {layoutName}%0Aმოპირკეთება - {decorationType}%0A%0Aროდის შეგვიძლია ყურება?",
        "messageInLayoutForWhatsApp": "ბინის დაჯავშნა მინდა განლაგება - {layoutName} საცხოვრებელ კომპლექსში {buildingName} სახლში {houseName}%0Aფასი - {price}%0Aფართი - {totalArea}%0A%0Aმოპირკეთება - {decorationType}%0A%0Aროდის შეგვიძლია ყურება?",
        "messageDefaultField": "არ არის მითითებული"
      }
    },
    "statistics": {
      "price": {
        "title": "მ²-ზე ფასების დინამიკა",
        "toggle": {
          "all": "ყველა",
          "studio": "სტუდია",
          "one": "1 ოთახიანი ბინა",
          "two": "2 ოთახიანი ბინა",
          "three": "3 ოთახიანი ბინა"
        },
        "forSquareMeter": "ფასი {currency} მ²",
        "table": {
          "date": "თარიღი",
          "newBuildingPerSquareMeter": "ახალი შენობა კვადრატულ მეტრზე",
          "button": "ყველას ნახვა"
        },
        "notFound": "სამწუხაროდ, სტატისტიკა ვერ მოიძებნა"
      }
    }
  },
  "layout": {
    "entityName": "განლაგება",
    "image": {
      "alt": "განლაგება"
    }
  },
  "developer": {
    "entityName": "შენარჩულებელი",
    "houses": {
      "handOver": "სახლები არ არის | გადასახდელია | გადასახდა | გადასახდელია",
      "unfinished": "სახლები არ არის | არის შენანიშვნული | შენანიშვნულია | შენანიშვნულია"
    },
    "foundationYear": "კომპანიის დაარსების წელი",
    "page": {
      "description": {
        "title": "შენარჩულებელის შესახებ",
        "fullShowButton": "მეტის წაკითხვა"
      },
      "contacts": {
        "info": {
          "text": "დეველოპერის კონტაქტების სანახავად,",
          "login": "შებრძანდით",
          "signup": "რეგისტრაცია"
        }
      },
      "advantages": "წარმოები",
      "buildings": {
        "title": "შენიშვნები მშვიდობიანი კომპლექსის შესახებ",
        "showButton": "დამატებით გამოჩენა {count} კომპლექსი"
      },
      "flats": {
        "title": "შენიშვნები მშვიდობიანი კომპლექსის შესახებ",
        "showButton": "დამატებით გამოჩენა {count} ბინა | დამატებით გამოჩენა {count} ბინა | დამატებით გამოჩენა {count} ბინა | დამატებით გამოჩენა {count} ბინა"
      }
    },
    "promotions": {
      "label": "პრომოციები შემმქვნელისათვის",
      "allPromotions": "ყველა შემმქვნელის პრომოციები",
      "showAllPromotions": "ყველა შემმქვნელის პრომოციების ნახვა",
      "showButton": "ნახვა",
      "promotionsNotFound": "პრომოციები არ მოიძებნა"
    }
  },
  "house": {
    "type": {
      "label": "სახლის ტიპი",
      "options": {
        "block": "ბლოკის სახლი",
        "frame": "ჩარჩო სახლი",
        "monolithicFrame": "მონოლითური კარკასი სახლი"
      }
    },
    "facadeType": {
      "label": "ფასადი",
      "options": {
        "plaster": "თაბაშირი",
        "ventilatedFacade": "ვენტილირებადი ფასადი",
        "glass": "Стекляннმინაый"
      }
    },
    "parkingType": {
      "label": "პარკინგი",
      "options": {
        "underground": "მიწისქვეშა პარკინგი",
        "indoor": "შიდა პარკინგი",
        "inYard": "ეზოს პარკინგი"
      }
    },
    "lifts": {
      "label": "ლიფტი",
      "noLifts": "ლიფტი არ არის",
      "liftsExist": "ლიფტი არის",
      "passengerLiftsCount": {
        "label": "{count} სამგზავრო ლიფტები|{count} სამგზავრო ლიფტები|{count} სამგზავრო ლიფტები|{count} სამგზავრო ლიფტები"
      },
      "cargoLiftsCount": {
        "label": "{count} სატვირთო ლიფტები|{count} სატვირთო ლიფტები|{count} სატვირთო ლიფტები|{count} სატვირთო ლიფტები"
      }
    }
  },
  "entityActions": {
    "filter": {
      "promotions": {
        "hasPromotions": "პრომოციებში მონაწილეებს"
      },
      "entitiesFilter": {
        "placeholder": "მიუთითეთ დეველოპერის საბინაო კომპლექსი",
        "noCountryPlaceholder": "თქვენ უნდა აირჩიოთ ქვეყანა"
      },
      "flatFilter": {
        "kitchenArea": {
          "label": "სამზარეულო ფართოი",
          "from": "სამზარეულოს ფართობი  მეტრიდან {value} მ²",
          "to": "სამზარეულოს ფართობი  მეტრამდეა {value} მ²",
          "action": {
            "label": "აირჩიეთ ტერიტორია"
          }
        },
        "totalArea": {
          "label": "საერთო ფართები",
          "from": "ეტრიდან საწყისი {value} მ²",
          "to": "ეტრიდან ადრე {value} მ²"
        },
        "price": {
          "label": "ფასი",
          "from": "ფასი {value} დან",
          "to": "ფასი {value} მდე"
        },
        "flatRoomsCount": {
          "one": "1 ოთახი ბინის",
          "two": "2 ოთახი ბინის",
          "three": "3 ოთახი ბინის",
          "four": "4 ოთახი ბინის",
          "five": "5 ოთახი ბინის",
          "six": "6 ოთახი ბინის",
          "studio": "სტუდია"
        },
        "flatNumber": {
          "placeholder": "შეიყვანეთ ბინის ნომერი"
        }
      },
      "houseFilter": {
        "deadLineYear": {
          "label": "დასრულება",
          "from": "სახლის ბოლო ვადა დან {quarter} დან {year} ",
          "to": "სახლის ბოლო ვადაა მდეა {quarter} დან {year} ",
          "showHandOver": "ექსპლუატაციაში შესული სახლების ჩვენება"
        },
        "keysGetting": {
          "label": "გასაღების გაცემა",
          "from": "გასაღების გაცემა {quarter} დან {year} ",
          "to": "გასაღების გაცემა {quarter} დან {year} ",
          "showHandOver": "ექსპლუატაციაში შესული სახლების ჩვენება",
          "action": {
            "label": "აირჩიეთ გაცემის თარიღი"
          }
        },
        "lifts": {
          "label": "ლიფტები",
          "hasPassenger": {
            "label": "სამგზავრო"
          },
          "hasCargo": {
            "label": "სატვირთო"
          }
        },
        "materialTypes": {
          "label": "მასალა",
          "action": {
            "label": "აირჩიეთ მასალა"
          },
          "aerocreteBlock": {
            "label": "გაზიანი ბეტონის ბლოკი"
          },
          "block": {
            "label": "ბლოკის სახლი"
          },
          "boards": {
            "label": "ფარი"
          },
          "brick": {
            "label": "აგურის"
          },
          "foamConcreteBlock": {
            "label": "ქაფის ბეტონის ბლოკი"
          },
          "gasSilicateBlock": {
            "label": "გაზიანი ბეტონის ბლოკი"
          },
          "monolith": {
            "label": "მონოლითური"
          },
          "monolithBrick": {
            "label": "მონოლითური აგურის"
          },
          "old": {
            "label": "ძველი ფონდი"
          },
          "panel": {
            "label": "პანელის"
          },
          "stalin": {
            "label": "სტალინკა"
          },
          "wireframe": {
            "label": "ჩარჩო"
          },
          "wood": {
            "label": "ხის"
          },
          "log": {
            "label": "მორი"
          },
          "timber": {
            "label": "ხე-ტყე"
          }
        }
      },
      "allFilters": {
        "label": "ყველა ფილტრი",
        "modal": "მეტი ფილტრები"
      },
      "savedFilters": {
        "label": {
          "short": "შენახული ფილტრები",
          "full": "შენახული ფილტრები"
        },
        "modal": "შენახული ფილტრები"
      },
      "selectedFilters": {
        "show": "მეტი {value}"
      },
      "button": "ფილტრი",
      "resetButton": "გადაყარეთ",
      "actions": {
        "resetFilters": "ფილტრების გადატვირთვა"
      },
      "guest": {
        "title": {
          "partOne": "საერთაშორისო",
          "partTwo": "უძრავი ქონების ეკოსისტემა"
        }
      }
    },
    "search": {
      "flat": "არ არის ნაპოვნი ბინები | {count} ბინა | {count} ბინა | {count} ბინა",
      "mobileFlat": "0 ბინა | {count} ბინა | {count} ბინა | {count} ბინა",
      "noFlat": "არ არის ხელმისაწვდომი ბინები",
      "shortSearchButton": "{count} ბინა | {count} ბინა | {count} ბინა | {count} ბინა",
      "errors": {
        "first": "მოხდა შეცდომა. სცადეთ გვერდის განახლება.",
        "second": "მოხდა შეცდომა. გთხოვთ დაუკავშირდეთ მხარდაჭერის ჯგუფს"
      }
    },
    "show": {
      "layouts": "{count} ბინა |  {count} ბინა |  {count} ბინა |  {count} ბინა",
      "flats": "{count} ბინა |  {count} ბინა |  {count} ბინა |  {count} ბინა",
      "buildings": "{count} საცხოვრებელი კომპლექსი"
    },
    "showCount": {
      "layouts": "არ არის ნაპოვნი განლაგება | ნაპოვნია {count} განლაგება | ნაპოვნია {count} განლაგება | ნაპოვნია {count} განლაგება",
      "flats": "არ არის ნაპოვნი ბინები | ნაპოვნია {count} ბინა | ნაპოვნია {count} ბინა | ნაპოვნია {count} ბინა",
      "buildings": "საცხოვრებელი კომპლექსი ვერ მოიძებნა | {count} საცხოვრებელ კომპლექსში  | {count} საცხოვრებელ კომპლექსში  | {count} საცხოვრებელ კომპლექსში "
    },
    "sort": {
      "options": {
        "price": {
          "label": "ფასის მიხედვით",
          "order": {
            "descending": "ჯერ ძვირი",
            "ascending": "ჯერ იაფი"
          }
        },
        "popularity": {
          "label": "პოპულარულობით",
          "order": {
            "descending": "ყველაზე პოპულარული პირველი",
            "ascending": "ყველაზე არაპოპულარული პირველი"
          }
        },
        "deadline": {
          "label": "ვადის მიხედვით",
          "order": {
            "descending": "ჯერ ახალი",
            "ascending": "ჯერ ძველი"
          }
        },
        "area": {
          "label": "ფართობის მიხედვით",
          "order": {
            "descending": "ჯერ დიდი",
            "ascending": "ჯერ საშუალო"
          }
        },
        "floor": {
          "label": "ფართობის მიხედვით",
          "order": {
            "descending": "ჯერ ზედა სართულებზე",
            "ascending": "ჯერ ქვედა სართულებზე"
          }
        },
        "percentage": {
          "label": "კომისიის პროცენტიდან",
          "order": {
            "descending": "თავიდან ნაკლები",
            "ascending": "თავიდან მეტი"
          }
        }
      }
    },
    "showMap": "რუკაზე",
    "mapBuildings": "{count} საცხოვრებელი კომპლექსი | {count} საცხოვრებელი კომპლექსი | {count} საცხოვრებელი კომპლექსი | {count} საცხოვრებელი კომპლექსი"
  },
  "user": {
    "profile": {
      "avatar": {
        "alt": "მომხმარებლის ავატარი {user}"
      },
      "page": {
        "toggle": {
          "options": {
            "profile": "პროფილი",
            "login": "ლოგინი",
            "password": "პაროლი"
          }
        },
        "deleteAccount": {
          "title": "ანგარიშის წაშლა",
          "inputTitle": "მიუთითეთ წაშლის მიზეზი",
          "buttonTitle": "Ანგარიშის წაშლა",
          "sendRequest": "Მოთხოვნის გაგზავნა",
          "toast": {
            "success": "ანგარიშის წაშლის მოთხოვნა გაიგზავნა",
            "error": "წაშლის მოთხოვნის გაგზავნისას მოხდა შეცდომა"
          }
        }
      },
      "personalCabinet": {
        "goTo": {
          "label": "გადადით პროფილზე",
          "confirmation": {
            "title": "დადასტურება",
            "text": "თქვენ შეგიძლიათ მართოთ თქვენი პარამეტრები და თქვენი პროფილი ჩვენს CRM Quick Deal.\nგარიგება შესვლა და პაროლი იგივეა, რაც Estatyx-ში .\nეს არის დროებითი გადაწყვეტა და უახლოეს მომავალში პროფილი და პარამეტრების მართვა გამოჩნდება.\nამავდროულად, შეგიძლიათ შეამოწმოთ ჩვენი CRM უძრავი ქონების სააგენტოებისთვის =)\nმადლობა გაგებისთვის."
          }
        }
      },
      "form": {
        "profile": {
          "title": {
            "edit": "თანამშრომლის რედაქტირება"
          },
          "about": {
            "title": "მომხმარებლის შესახებ",
            "firstName": {
              "label": "სახელი",
              "placeholder": "შეიყვანეთ სახელი"
            },
            "lastName": {
              "label": "გვარი",
              "placeholder": "შეიყვანეთ გვარი"
            }
          },
          "contacts": {
            "title": "კონტაქტები",
            "mobilePhone": {
              "label": "ძირითადი ტელეფონის ნომერი",
              "placeholder": "+7 000 000 00 00"
            },
            "email": {
              "label": "E-mail"
            },
            "telegramPhone": {
              "label": "ლოგინი Telegram",
              "placeholder": "+7 000 000 00 00"
            },
            "telegramLogin": {
              "label": "ნომერი Telegram",
              "placeholder": "ლოგინი"
            },
            "whatsappPhone": {
              "label": "ნომერი Whatsapp",
              "placeholder": "+7 000 000 00 00"
            }
          },
          "toasts": {
            "profile": {
              "success": "პროფილი წარმატებით განახლდა",
              "error": "თქვენი პროფილის განახლებისას მოხდა შეცდომა"
            },
            "login": {
              "success": "შესვლა წარმატებით განახლდა",
              "error": "შესვლის განახლებისას მოხდა შეცდომა"
            },
            "password": {
              "success": "პაროლი წარმატებით განახლდა",
              "error": "პაროლის განახლებისას მოხდა შეცდომა"
            },
            "image": {
              "success": "სურათი წარმატებით განახლდა",
              "error": "სურათის განახლებისას მოხდა შეცდომა"
            }
          }
        },
        "login": {
          "title": "ლოგინის შეცვლა",
          "mobilePhone": {
            "label": "ტელეფონის ნომერი"
          },
          "currentPassword": {
            "label": "მიმდინარე პაროლი"
          },
          "submit": "ლოგინის შეცვლა"
        },
        "password": {
          "title": "პაროლის შეცვლა",
          "password": {
            "label": "პაროლის შეცვლა"
          },
          "repeatPassword": {
            "label": "გაიმეორეთ ახალი პაროლი"
          },
          "submit": "პაროლის შეცვლა"
        }
      }
    }
  },
  "mainMenu": {
    "navLinks": {
      "home": {
        "index": "მთავარი",
        "market": "კატალოგი",
        "popular": "პოპულარული",
        "prices": "ფასები",
        "partners": "პარტნიორები",
        "mortgage": "იპოთეკა",
        "journal": "ჟურნალი",
        "events": "ღონისძიება"
      }
    }
  },
  "navMenu": {
    "title": "მენიუ",
    "clients": {
      "label": "კლიენტთან მუშაობის რეჟიმი",
      "motivation": "მოტივაცია",
      "crm": "CRM"
    },
    "invite": {
      "buttonTitle": "მოიწვიე თანამშრომლები",
      "modalTitle": "მოწვევის ბმული",
      "tooltip": "დააჭირეთ კოპირებას",
      "toast": {
        "success": "ბმული გადაწერილია",
        "changeLink": {
          "success": "ბმული განახლებულია",
          "error": "ბმულის განახლების დროს შეცდომა მოხდა"
        }
      }
    },
    "organisationSettings": {
      "buttonTitle": "პარამეტრები"
    },
    "navigation": {
      "suggestions": "კოლექციები",
      "clients": "მომხმარებლები",
      "home": "მთავარი",
      "market": "მარკეტი",
      "events": "ღონისძიება",
      "settings": "პარამეტრები"
    },
    "exit": "გასვლა",
    "changeList": {
      "label": "განახლებები",
      "version": "ვერსია",
      "changeListItem": "განახლებები",
      "badge": "NEW"
    }
  },
  "suggestions": {
    "sidePanel": {
      "displayAllFlats": {
        "button": "ყველაფრის ჩვენება",
        "title": "ამჟამად ნაჩვენებია {value} შემოთავაზებული საცხოვრებელი კომპლექსის ყველა ბინა",
        "tip": "იმისათვის, რომ ნახოთ ყველა ბინა კონკრეტული საცხოვრებელი კომპლექსიდან, დააწკაპუნეთ საბინაო კომპლექსის ჩანართზე ზედა მარჯვნივ"
      }
    },
    "content": {
      "buildingEmpty": "არჩეულ საცხოვრებელ კომპლექსში არ არის დამატებული კვარტლები.",
      "suggestionEmpty": "არჩეულ არჩევანში არ არის დამატებული ბინები."
    },
    "buttons": {
      "bookViewing": {
        "label": "ჩაწერა სანახავად",
        "messageForWhatsApp": "მინდა ვნახო ბინები არჩევანიდან {link}. როდის შეგვიძლია ნახვა?"
      },
      "chatWithManager": {
        "label": "ესაუბრეთ რეალტორს"
      }
    },
    "bottomMenu": {
      "contacts": "კონტაქტი"
    },
    "empty": "შერჩევა ცარიელია",
    "textToSend": "ობიექტების შერჩევა : {value}"
  },
  "suggestionsActions": {
    "filter": {
      "all": "ყველა",
      "user": "ჩემი"
    },
    "create": {
      "title": {
        "full": "შექმენით შერჩევა",
        "short": "შერჩევა"
      },
      "placeholder": "შეიყვანეთ კოლექციის სახელი",
      "toast": {
        "success": "შერჩევა წარმატებით შეიქმნა"
      }
    },
    "addToSuggestion": {
      "title": "დაამატეთ ობიექტები კოლექციაში",
      "toast": {
        "success": "დაამატეთ ობიექტები კოლექციაში"
      }
    },
    "removeSuggestion": {
      "toast": {
        "success": "შერჩევა წარმატებით წაიშალა",
        "error": "მოხდა შეცდომა"
      },
      "confirm": "დარწმუნებული ხართ, რომ გსურთ კოლექციის წაშლა \"{value}\" ?"
    },
    "client": {
      "search": {
        "title": "იპოვნეთ არსებული",
        "placeholder": "შეიყვანეთ თქვენი სრული სახელი, ტელეფონის ნომერი ან ელექტრონული ფოსტა"
      },
      "add": {
        "toast": {
          "success": "კლიენტი წარმატებით უკავშირდება კოლექციას."
        }
      },
      "remove": {
        "toast": {
          "success": "კლიენტი წარმატებით გათიშულია კოლექციიდან."
        }
      },
      "create": {
        "title": "შექმენით ახალი",
        "fullName": {
          "label": "შეიყვანეთ კლიენტის სრული სახელი",
          "placeholder": "შეიყვანეთ კლიენტის სრული სახელი"
        },
        "mobilePhone": {
          "label": "ტელეფონი",
          "placeholder": "+_ (___) ___-__-__"
        },
        "email": {
          "label": "E-mail",
          "placeholder": "შეიყვანეთ თქვენი ელ. ფოსტა"
        },
        "submit": "კლიენტის დამატება",
        "toast": {
          "success": "კლიენტი წარმატებით შეიქმნა.",
          "error": "მოხდა შეცდომა. გთხოვთ განაახლეთ გვერდი. თუ პრობლემა გამეორდება, გთხოვთ დაგვიკავშირდეთ."
        }
      },
      "noClients": "კლიენტი არ არის შერჩეული"
    },
    "removeBuildingFromSuggestion": {
      "toast": {
        "success": "შერჩევა განახლდა"
      },
      "confirm": " {value} დარწმუნებული ხართ, რომ გსურთ მისი ყველა ბინის კოლექციიდან ამოღება?"
    },
    "removeFlatFromSuggestion": {
      "toast": {
        "success": "შერჩევა განახლდა"
      },
      "confirm": "დარწმუნებული ხართ, რომ გსურთ ამოიღოთ ბინის ნომერი კოლექციიდან {value}?"
    },
    "copyLink": {
      "toast": {
        "success": "კოლექციის ბმული გადაწერილია ბუფერში"
      }
    },
    "table": {
      "suggestionTitle": "ოლექციის სახელი",
      "objects": "ობიექტები",
      "clients": "მომხმარებლები",
      "creatingDate": "შექმნის თარიღი",
      "itemsCount": {
        "buildings": "{value} საცხოვრებელი კომპლექსი",
        "flats": "{value} ბ."
      }
    },
    "view": {
      "empty": "შერჩევა ცარიელია"
    }
  },
  "clients": {
    "page": {
      "title": "მომხმარებლები",
      "create": {
        "buttonTitle": {
          "full": "კლიენტის დამატება",
          "short": "მომხმარებელი"
        }
      }
    },
    "toasts": {
      "booking": {
        "success": "დაჯავშნა წარმატებით გაიარა",
        "error": "დროებით შეცდომა დაჯავშნის პროცესში"
      },
      "fixation": {
        "success": "ფიქსაცია წარმატებით გაიარა",
        "error": "დროებით შეცდომა ფიქსაციის პროცესში"
      }
    },
    "tableView": {
      "fullName": "სრული სახელი და გვარი",
      "email": "Email",
      "mobilePhone": "ტელეფონი",
      "action": "მოქმედება"
    },
    "delete": {
      "toast": {
        "success": "კლიენტი წარმატებით წაიშალა."
      }
    },
    "table": {
      "label": "ყველა კლიენტი",
      "actions": {
        "addToSuggestion": "დამატება რჩევში",
        "bookings": "კლიენტის დაჯავშნები",
        "fixations": "კლიენტის ფიქსაციები",
        "views": "კლენტების ნახვები",
        "edit": "კლიენტის რედაქტირება",
        "delete": "კლიენტის წაშლა"
      }
    },
    "filter": {
      "categories": {
        "clients": "კლიენტი",
        "developers": "აწარმოებელი",
        "buildings": "საცურაო კომპლექსი",
        "assignees": "პასუხისმგებელი(ები)"
      },
      "selected": "არჩეული",
      "excluded": "გამორიცხული"
    },
    "fixation": {
      "table": {
        "label": "ფიქსაცია",
        "createDate": "შექმნის თარიღი",
        "client": "კლიენტი",
        "developer": "აწარმოებელი(ები)",
        "status": "ფიქსაციის სტატუსი",
        "endDate": "ფიქსაცია დღე",
        "assigned": "პასუხისმგებელი(ები)"
      },
      "actions": {
        "add": "დაფიქსირება"
      },
      "form": {
        "progress": "დაჯავშნა",
        "progressStep": "+{value}% პროგრესირება",
        "submit": "დაფიქსირება",
        "developer": {
          "title": "დეველოპერი",
          "label": "დეველოპერის არჩევა",
          "placeholder": "დეველოპერების სია",
          "conditions": {
            "general": "მთლიანი რაოდენობა",
            "custom": "ფიქსაციის პირობები"
          }
        },
        "user": {
          "title": "პასუხისმგებელი",
          "label": "მომხმარებლის არჩევანი",
          "placeholder": "მომხმარებლის სია"
        },
        "client": {
          "title": "მომხმარებელი",
          "label": "აირჩიეთ კლიენტი ან შექმენით ახალი",
          "add": "ახალი მომხმარებლის დამატება",
          "placeholder": "ძებნისთვის შეიყვანეთ სახელი, ტელეფონი ან ელ.ფოსტა.",
          "noClients": "ვერაფერი მოიძებნა, გაასწორეთ ძებნა ან შექმენით ახალი კლიენტი"
        },
        "comment": {
          "label": "კომენტარი",
          "placeholder": "თქვენი კომენტარი"
        }
      },
      "info": {
        "title": "უპირატესობა",
        "text": "თქვენ შეგიძლიათ წაიკითხოთ ჩვენს სტატიაში კლიენტის დადების უპირატესობების შესახებ",
        "link": "წაიკითხეთ აქ"
      },
      "warning": {
        "title": "ზოგადი პირობები",
        "text": "თუ დეველოპერი არ მიუთითებს კლიენტის დაფიქსირების პირობებზე, მაშინ კლიენტის ფიქსაციის პერიოდი",
        "fixPeriod": "14დღე"
      },
      "fixationTerms": {
        "title": "ფიქსირება",
        "durationInDays": "ფიქსირების ვადა",
        "clientsData": "საჭირო მონაცემები ფიქსირებისთვის",
        "additionalInfo": "დამატებითი ინფორმაცია"
      },
      "contacts": {
        "title": "კონგტაქტები კომუნიკაციისთვის",
        "mobileTitle": "კონტაქტები"
      }
    },
    "booking": {
      "table": {
        "label": "დაჯავშნები",
        "createDate": "შექმნის თარიღი",
        "client": "კლიენტი",
        "developer": "აწარმოებელი",
        "building": "საცურაო კომპლექსი",
        "flat": "ბინა",
        "paymentType": "გადახდის ტიპი",
        "status": "დაჯავშნის სტატუსი",
        "endDate": "დაჯავშნა დღე",
        "assigned": "პასუხისმგებელი(ები)"
      },
      "actions": {
        "add": "დაჯავშნა"
      },
      "paymentType": {
        "options": {
          "mortgage": "იპოთეკა",
          "cash": "ნაღდი ფული"
        }
      },
      "form": {
        "progress": {
          "yourCommission": "თქვენი საკომისიო"
        },
        "deal": {
          "title": "გარიგება",
          "label": "ტრანზაქციის გადახდის ფორმა",
          "placeholder": "ტრანზაქციის გადახდის ფორმის არჩევა",
          "uploadButton": "ატვირთეთ სკანირებული დოკუმენტებიუ"
        },
        "about": {
          "flat": "ბინაზე"
        },
        "submit": "დაჯავშნა"
      },
      "warning": {
        "text": "მაქსიმალური დაჯავშნის პერიოდი - 3 დღე"
      }
    },
    "views": {
      "table": {
        "label": "ნახვები",
        "createDate": "შექმნის თარიღი",
        "dateView": "ნახვის თარიღი",
        "client": "კლიენტი",
        "building": "სათავსო კომპლექსი",
        "flat": "ბინა",
        "assigned": "პასუხური",
        "comment": "კომენტარი",
        "view": "ნახვა",
        "status": "ნახვის სტატუსი"
      },
      "statuses": {
        "scheduled": "დაჯგუფებული",
        "done": "შესრულებული",
        "toSchedule": "დაჯგუფება საფრთხე"
      },
      "actions": {
        "edit": "ნახვის რედაქტირება",
        "add": "დამატება",
        "selectStatus": "აირჩიეთ სტატუსი",
        "save": "ნახვის შენახვა",
        "addView": "ნახვის დამატება",
        "selectBuilding": "აირჩიეთ სათავსო კომპლექსი",
        "selectFlat": "აირჩიეთ ბინა"
      },
      "status": "სტატუსი",
      "building": "სათავსო კომპლექსი",
      "chessboard": "ჭადრა",
      "aboutClient": "კლიენტის შესახებ",
      "toasts": {
        "create": {
          "success": "ნახვა წარმატებით შეიქმნა",
          "error": "ნახვაში შეცდომა მოხდა შექმნის დროს"
        },
        "update": {
          "success": "ნახვა წარმატებით განახლდა",
          "error": "ნახვაში შეცდომა მოხდა განახლების დროს"
        }
      }
    }
  },
  "mortgage": {
    "label": "იპოთეკა",
    "calculator": {
      "inputs": {
        "price": {
          "label": {
            "full": "უძრავი ქონების ფასი",
            "short": "ფასი"
          }
        },
        "initialPayment": {
          "label": {
            "full": "საწყისი გადასახადი",
            "short": "სგ"
          }
        },
        "term": {
          "label": "ვადა",
          "suffix": "წელი | წელი | წლები | წლები"
        },
        "stake": {
          "label": "საპროცენტო განაკვეთი",
          "suffix": "წელი"
        }
      },
      "info": {
        "monthlyPayment": "ყოველთვიური გადახდა",
        "recommendedIncome": "რეკომენდებული შემოსავალი"
      }
    },
    "table": {
      "bank": {
        "label": "ბანკი"
      },
      "stake": {
        "label": "შეთავაზება",
        "stakeRange": {
          "inText": "დან {value}%",
          "default": "დან {value}%"
        }
      },
      "maxSum": {
        "label": "თანხა",
        "range": "მდე {sum}"
      },
      "term": {
        "label": "ვადა",
        "termRange": "არ არის არჩეული თარიღი | {count} წელი | {count} წლები | {count} წლები"
      },
      "monthlyPayment": {
        "label": "ყოველთვიური გადახდა"
      },
      "initialPayment": {
        "label": {
          "full": "პირველი შენატანი",
          "short": "I შენატანი"
        },
        "initialPaymentRange": "დან {value}%"
      }
    },
    "schedule": {
      "title": "სესხის გადახდის გრაფიკი",
      "price": "ფასი",
      "creditAmount": "კრედიტის ოდენობა",
      "term": "ვადა",
      "overpayment": "ზედმეტად გადახდა",
      "table": {
        "date": "თარიღი",
        "remainder": "დარჩენილი",
        "stakes": "პროცენტები",
        "redeemed": "გადახდილი",
        "payment": "გადახდა"
      }
    },
    "mortgageActions": {
      "showMortgageList": "პროგრამა ვერ მოიძებნა | იხილეთ {count} პროგრამა | ყველას ნახვა {count} პროგრამების | ყველას ნახვა {count} პროგრამა",
      "showSchedule": "Გადახდის გრაფიკი",
      "applyOnline": "ონლაინ გაფორმება"
    }
  },
  "files": {
    "label": "ფაილები",
    "actions": {
      "link": "კოპირება",
      "go": "გადასვლა",
      "show": "იხილეთ ყველა"
    },
    "toast": {
      "success": "ფაილის ბმული დაკოპირებულია ბუფერში"
    }
  },
  "chessboard": {
    "house": {
      "empty": "არჩეულ სახლს არ აქვს სექციები"
    },
    "section": {
      "label": "განყოფილება",
      "empty": "შერჩეულ საცხოვრებელ კომპლექსში არ არის კორპუსი"
    },
    "floorNumber": {
      "label": "სართული"
    },
    "modal": {
      "title": "ინფორმაცია ბინის შესახებ"
    },
    "empty": "არჩეულ კორპუსში არ არის ბინა, რომელიც აკმაყოფილებს მითითებულ ფილტრებს"
  },
  "toast": {
    "error": "მოხდა შეცდომა ",
    "title": {
      "success": "წარმატება",
      "info": "ნახავ",
      "warning": "გაფრთხილება",
      "danger": "ნახავ"
    }
  },
  "errorPage": {
    "notFound": "ეს გვერდი არ არსებობს!",
    "forbidden": "წვდომა აკრძალულია!",
    "backLink": "დაბრუნდით მთავარ გვერდზე"
  },
  "map": {
    "actions": {
      "showGrid": "კრამიტის ჩვენება",
      "openFilter": "ფილტრი",
      "paintOn": "აირჩიეთ ტერიტორია",
      "painting": "დაიწყეთ ხატვა"
    },
    "building": {
      "notFound": "რეგიონში საცხოვრებელი კომპლექსი არ არის ნაპოვნი"
    },
    "paintedGeometries": "არჩეული სფეროები არ არის | {count} ტერიტორია რუკაზე | {count} ტერიტორია რუკაზე | {count} ტერიტორია რუკაზე"
  },
  "footer": {
    "copyright": {
      "title": "©{year} ООО «МЭН» Ყველა უფლება დაცულია.",
      "subtitle": "მასალების გამოყენებისას საჭიროა ჰიპერბმული."
    },
    "subscribe": {
      "title": "გამოიწერეთ ჩვენი ბიულეტენი",
      "inputPlaceholder": "ფოსტა",
      "button": "გამოწერა"
    }
  },
  "partners": {
    "title": "პარტნიორები",
    "toggle": {
      "developers": "დეველოპერები",
      "agencies": "სააგენტოები",
      "banks": "ბანკები"
    },
    "developers": {
      "houses": {
        "count": "სახლები არ არის| {count} სახლი | {count} სახლები | {count} სახლებს",
        "built": "სახლები არ არის | ჩაბარებულია | ჩაბარებულები | ჩავარებულია",
        "unfinished": "შენდება"
      }
    },
    "agency": {
      "offers": "შეთავაზება არ არის | {count} შეთავაზება | {count} შეთავაზება | {count} შეთავაზება"
    },
    "bank": {
      "mortgage": "იპოთეკა დან {stake}%"
    }
  },
  "journal": {
    "title": "Ჟურნალი",
    "goButton": "გადადით ჟურნალში",
    "type": {
      "options": {
        "news": "ახალი ამბევი | ახალი ამბევი | ახალი ამბები | ახალი ამბები",
        "promotion": "პრომოცია | პრომოცია | პრომოციები | პრომოციები",
        "article": "სტატია | სტატია | სტატიები | სტატიები",
        "review": "მიმოხილვა | მიმოხილვა | მიმოხილვები | მიმოხილვები",
        "training": "სწავლება | სწავლება | სწავლება | სწავლება",
        "webinar": "ვებინარი | ვებინარი | ვებინარები | ვებინარები",
        "excursion": "ექსკურსია | ექსკურსია | ექსკურსიები | ექსკურსიები"
      }
    },
    "form": {
      "title": {
        "add": "დაამატეთ შინაარსი"
      },
      "sectionTitle": {
        "content": "შინაარსი",
        "object": "ობიექტი",
        "author": "ავტორი",
        "date": "გატარების თარიღი",
        "duration": "ხანგრძლივობა",
        "seats": "ადგილების რაოდენობა"
      },
      "fields": {
        "title": {
          "label": "სათაური",
          "placeholder": "სტატიის სათაური"
        },
        "text": {
          "label": "ტექსტი",
          "placeholder": "სტატიის ტექსტი"
        },
        "shortText": {
          "label": "მოკლე აღწერა",
          "placeholder": "სტატიის ტექსტი"
        },
        "promotionType": {
          "label": "პრომოციის ტიპი",
          "placeholder": "აირჩიეთ",
          "options": {
            "discount": "ფასდაკლება",
            "sale": "გასაყიდი",
            "promotion": "პრომოცია",
            "increasedPercentage": "ზრდადი პროცენტი",
            "increasedPercentageShort": "გაზრდილი საპროცენტო განაკვეთი"
          }
        },
        "file": {
          "button": "გადახედვის ჩამოტვირთვა"
        },
        "bannerFile": {
          "button": "ბანერის ჩატვირთვა"
        },
        "video": {
          "label": "დაამატეთ ვიდეო",
          "placeholder": "ვიდეოს ბმული",
          "caption": "ეს ვიდეო დაიმალება სტატიის გადახედვის უკან, მაგრამ მომხმარებელი შეძლებს მის ნახვას",
          "presentationVisible": "არ გამოჩენოთ პრეზენტაციაში"
        },
        "developer": {
          "label": "დეველოპერი",
          "placeholder": "დეველოპერების ჩამონათვალი",
          "isAll": "ყველა დეველოპერი",
          "isManagerRequestNeeded": "შეამოწმეთ მენეჯერთან"
        },
        "building": {
          "label": "საცხოვრებელი კომპლექსი",
          "placeholder": "საცხოვრებელი კომპლექსების ჩამონათვალი",
          "isAll": "ყველა საცხოვრებელი კომპლექსი",
          "isManagerRequestNeeded": "შეამოწმეთ მენეჯერთან"
        },
        "country": {
          "label": "ქვეყანა",
          "placeholder": "ქვეყნების ჩამონათვალი",
          "isAll": "ყველა ქვეყანა",
          "isManagerRequestNeeded": "შეამოწმეთ მენეჯერთან"
        },
        "address": {
          "label": "მხარდაჭერა/ქალაქი",
          "placeholder": "ადგილების/ქალაქების ჩამონათვალი",
          "isAll": "ყველა ადგილები/ქალაქები",
          "isManagerRequestNeeded": "შეამოწმეთ მენეჯერთან"
        },
        "authorship": {
          "label": "ავტორობა",
          "options": {
            "estatyx": "Estatyx",
            "company": "Кკომპანია"
          }
        },
        "company": {
          "label": "აირჩიეთ კომპანია",
          "placeholder": "კომპანიების სია"
        },
        "user": {
          "label": "შეარჩიეთ კომპანიის თანამშრომელი",
          "placeholder": "თანამშრომლების სია"
        },
        "date": {
          "label": "გატარების თარიღი",
          "placeholder": "აირჩიეთ გატარების თარიღი"
        },
        "dateRange": {
          "label": "თარიღის არჩევა"
        },
        "time": {
          "label": "დრო"
        },
        "registrationEndDate": {
          "label": "ჩაწერის დასრულების თარიღი",
          "placeholder": "აირჩიეთ ჩაწერის დასრულების თარიღი",
          "validation": "{endDate} არ შეიძლება იყოს უფრო გვიან ვიდრე {startDate}"
        },
        "duration": {
          "label": "ხანგრძლივობა",
          "unlimited": "უვადოდ"
        },
        "durationInDays": {
          "label": "დღეები"
        },
        "durationInHours": {
          "label": "საათები"
        },
        "seats": {
          "label": "ადგილები რეგისტრაციისთვის",
          "placeholder": "შეიყვანეთ ხელმისაწვდომი ადგილების რაოდენობა",
          "checkbox": "ადგილების შეუზღუდავი რაოდენობა"
        }
      },
      "toast": {
        "create": {
          "success": "სტატია წარმატებით შეიქმნა"
        },
        "edit": {
          "success": "სტატია წარმატებით იქნა რედაქტირებული"
        }
      }
    },
    "search": {
      "notFound": "სიახლე არ მოიძებნა"
    },
    "filter": {
      "withVideo": "მხოლოდ ვიდეოთი",
      "promotions": "პრომოციები",
      "buildings": "სანაგურები",
      "developers": "დეველოპერები"
    },
    "page": {
      "video": "ვიდეო",
      "event": {
        "info": {
          "text": "ღონისძიებაში მონაწილეობის მისაღებად,",
          "login": "შებრძანდით",
          "signup": "რეგისტრაცია"
        }
      }
    }
  },
  "events": {
    "page": {
      "title": "ღონისძიება"
    },
    "seatsCount": "დარჩა {count} ადგილი | დარჩა {count} ადგილი | დარჩენილია {count} ადგილი | დარჩენილია {count} ადგილი",
    "mySignUps": "ჩემი ღონისძიების ჩანაწერები",
    "status": {
      "seatsUnlimited": "არის ადგილები",
      "noSeats": "ადგილები აღარ არის დარჩენილი",
      "registrationCompleted": "ჩაწერა დასრულდა"
    },
    "filter": {
      "name": "ძიება..."
    },
    "search": {
      "notFound": "ღონისძიება არებნა"
    },
    "actions": {
      "signUp": "დარეგისტრირდით",
      "canсelSignUp": "ჩაწერის გაუქმება",
      "noSeats": "ადგილები არ არის",
      "registrationCompleted": "ჩაწერა დასრულდა",
      "eventEnded": "ღონისძიება დასრულდა"
    },
    "toast": {
      "signUp": {
        "success": "თქვენ წარმატებით დარეგისტრირდით ღონისძიებაზე!"
      },
      "canсelSignUp": {
        "success": "რეგისტრაცია გაუქმდა!"
      },
      "error": "მოვლენების ჩატვირთვის დროს შეცდომა მოხდა"
    },
    "tooltipHint": "ჩანაწერი ხელმისაწვდომია მხოლოდ დარეგისტრირებულ მომხმარებლებს"
  },
  "popularity": {
    "suggestionsAdditionsCount": "არჩევანს დაემატა {count} თვის განმავლობაში",
    "totalSuggestionsAdditionsCount": "შერჩევაში დამატებული ყველა დროისთვის {count} ჯერ | შერჩევაში დამატებული ყველა დროისთვის {count} ჯერ | შერჩევაში დამატებული ყველა დროისთვის {count} ჯერ | შერჩევაში დამატებული ყველა დროისთვის {count} ჯერ"
  }
}

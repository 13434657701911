<template>
  <div class="flex gap-x-3">
    <custom-button
      pill
      size="xs"
      variant="outline"
      class=" font-semibold w-fit !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': hasPassenger}"
      @click="toggleHasPassenger"
    >
      {{ t('entityActions.filter.houseFilter.lifts.hasPassenger.label') }}
    </custom-button>
    <custom-button
      pill
      size="xs"
      variant="outline"
      class="font-semibold w-fit !border-neutral-200 !text-black"
      :class="{'!border-primary !text-primary': hasCargo}"
      @click="toggleHasCargo"
    >
      {{ t('entityActions.filter.houseFilter.lifts.hasCargo.label') }}
    </custom-button>
  </div>

</template>
<script setup lang="ts">

import CustomButton from '~/ui/buttons/CustomButton.vue'
import { defaultFilter } from '~/modules/market-entities-actions/constants/filter/Filter.default'

const props = defineProps({
  hasPassenger: {
    type: Object as PropType<boolean | null>,
    default: () => defaultFilter().houseFilter.lifts.hasPassenger,
  },
  hasCargo: {
    type: Object as PropType<boolean | null>,
    default: () => defaultFilter().houseFilter.lifts.hasCargo,
  },
})

const emits = defineEmits(['update:hasPassenger', 'update:hasCargo'])

const { t } = useI18n()

const hasPassenger = ref(props.hasPassenger)
const hasCargo = ref(props.hasCargo)
const toggleHasPassenger = () => {
  hasPassenger.value = !hasPassenger.value
  emits('update:hasPassenger', hasPassenger.value ? true : null)
}

const toggleHasCargo = () => {
  hasCargo.value = !hasCargo.value
  emits('update:hasCargo', hasCargo.value ? true : null)
}

watchEffect(() => {
  hasPassenger.value = props.hasPassenger
  hasCargo.value = props.hasCargo
})
</script>

<template>
  <custom-autocomplete
    v-model="value"
    :options="suggestionOptions"
    :option-label="optionLabel"
    :invalid-feedback="invalidFeedback"
    :clearable="clearable"
    :multiple="multiple"
    :placeholder="placeholder"
    :option-key="optionKey"
    :disabled="disabled"
    :filterable="false"
    @search="query = $event; nextPageUrl = ''; getSuggestions()"
    @search:focus="getSuggestionsOnFocus"
    @close="handleClose"
    @open="handleOpen"
  >
    <template #list-footer>
      <div
        ref="listFooter"
        class="w-full min-h-[15px] flex items-center justify-center"
      >
        <custom-cube-spinner
          v-if="isFetchingData"
          size="sm"
        />
      </div>
    </template>
    <template
      v-if="$slots['selected-option-container']"
      #selected-option-container
    >
      <slot name="selected-option-container" />
    </template>
    <template
      v-if="$slots['no-options']"
      #no-options="{ search, loading }"
    >
      <slot
        :search="search"
        :loading="loading"
        name="no-options"
      />
    </template>
  </custom-autocomplete>
</template>
<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'
import CustomAutocomplete from '~/ui/selects/CustomAutocomplete.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import type { HttpPaginatedResponseData } from '~/services/http/types/Http.paginatedResponseData'

interface OptionType {
  value: string | number | null | undefined
  [key: string]: unknown
}

const props = defineProps({
  modelValue: {
    type: [Array, Object, String, Number] as PropType<string | number | Record<string, unknown> | Array<unknown> | null>,
    default: null,
  },
  fetch: {
    type: Function as PropType<(query: string, url?: string | null) => Promise<OptionType[] | HttpPaginatedResponseData<OptionType>>>,
    required: true,
  },
  optionLabel: {
    type: [String, Function] as PropType<string | ((option: OptionType) => string)>,
    required: true,
  },
  invalidFeedback: {
    type: String,
    default: '',
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  infiniteScroll: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  allowEmptyQuery: {
    type: Boolean,
    default: true,
  },
  optionKey: {
    type: String,
    default: 'id',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits<{(e: 'update:modelValue', value: unknown): void}>()

const isFocused = ref(false)

const query = ref<string | null>(null)
const suggestionOptions = ref<OptionType[]>([])
const nextPageUrl = ref<string | null>(null)
const isFetchingData = ref(false)

const value = computed({
  get: () => {
    if (props.multiple) {
      if (!isFocused.value && !Array.isArray(props.modelValue)) {
        return []
      }
      return Array.isArray(props.modelValue) ? props.modelValue : []
    }
    return props.modelValue
  },
  set: selectedValue => {
    if (props.multiple) {
      emits('update:modelValue', Array.isArray(selectedValue) ? selectedValue : [selectedValue])
    } else {
      emits('update:modelValue', selectedValue)
    }
  },
})

function isPaginated<T>(responseData: unknown): responseData is HttpPaginatedResponseData<T> {
  return !Array.isArray(responseData)
}

const getSuggestions = useDebounceFn((isFetching: (value: boolean) => void = () => {}, isNextPageNeed = false) => {
  if (!props.allowEmptyQuery && !query.value) {
    suggestionOptions.value = []
    query.value = null
    nextPageUrl.value = null
    return
  }
  isFetching(true)
  isFetchingData.value = true
  props.fetch(query.value || '', isNextPageNeed ? nextPageUrl.value : null)
    .then(data => {
      if (isPaginated<OptionType>(data)) {
        if (isNextPageNeed) {
          suggestionOptions.value.push(...data.data)
          const ul = listFooter.value?.offsetParent
          const scrollTop = ul?.scrollTop
          scrollTop && nextTick().then(() => {
            ul.scrollTop = scrollTop
          })
        } else {
          suggestionOptions.value = data.data
        }
        nextPageUrl.value = data.nextPageUrl
      } else {
        suggestionOptions.value = data
      }
    }).finally(() => {
      isFetching(false)
      isFetchingData.value = false
    })
}, 500)

const getSuggestionsOnFocus = (isFetching: (value: boolean) => void = () => {}) => {
  if (!suggestionOptions.value.length) {
    getSuggestions(isFetching)
  }
}

const listFooter = ref<HTMLDivElement | null>(null)
const observer = new IntersectionObserver(useDebounceFn(([{ isIntersecting }]) => {
  if (isIntersecting && nextPageUrl.value) {
    getSuggestions(undefined, true)
  }
}, 500))

const handleOpen = () => {
  if (!props.infiniteScroll) {
    return
  }
  nextTick().then(() => {
    if (listFooter.value) {
      observer.observe(listFooter.value)
    }
  })
}

const handleClose = () => {
  observer.disconnect()
}

const addOption = (option: OptionType) => {
  suggestionOptions.value.push(option)
}

const clearOptions = () => {
  suggestionOptions.value = []
}

onBeforeUnmount(handleClose)
defineExpose({
  addOption,
  clearOptions,
})
</script>

<template>
  <div class="flex flex-col gap-y-3 mt-3 md:mt-0 ">
    <div class="flex flex-col gap-y-3 md:gap-x-6">
      <labeled-wrapper
        :label="`${$t('entityActions.filter.flatFilter.kitchenArea.label')} ${$t('common.unitsOfMeasurement.options.squareMeter')}`"
        class="w-full md:w-1/3"
      >
        <template #content>
          <filter-from-to
            v-model:from="filter.flatFilter.kitchenArea.from"
            v-model:to="filter.flatFilter.kitchenArea.to"
            :placeholder-from="$t('common.from')"
            :placeholder-to="$t('common.to')"
            general-class="!p-0"
            is-general
          />
        </template>
      </labeled-wrapper>
      <labeled-wrapper
        v-if="!isSm"
        :label="$t(`flat.windowViewType.label`)"
        class="w-full"
      >
        <template #content>
          <flat-window-view-filter v-model="filter.flatFilter.windowsViewTypes" />
        </template>
      </labeled-wrapper>
      <labeled-wrapper
        :label="$t('entityActions.filter.houseFilter.keysGetting.label')"
        class="w-full"
      >
        <template #content>
          <filter-deadline-year
            v-model:from="filter.houseFilter.keysGetting.from"
            v-model:to="filter.houseFilter.keysGetting.to"
            :default-values="defaultFilter.houseFilter.keysGetting"
            :trigger-text="$t('entityActions.filter.houseFilter.keysGetting.action.label')"
            :is-full="false"
          />
        </template>
      </labeled-wrapper>
    </div>
    <div class="flex flex-col md:flex-row gap-y-3 md:gap-x-6">
      <labeled-wrapper
        :label="$t(`flat.decorationType.label`)"
        class="w-full"
      >
        <template #content>
          <flat-decorations-filter v-model="filter.flatFilter.decorationTypes" />
        </template>
      </labeled-wrapper>
      <labeled-wrapper
        v-if="isSm"
        :label="$t(`flat.windowViewType.label`)"
        class="w-full md:max-w-[337px]"
      >
        <template #content>
          <flat-window-view-filter v-model="filter.flatFilter.windowsViewTypes" />
        </template>
      </labeled-wrapper>
    </div>
    <div class="flex gap-y-3 md:gap-x-6">
      <labeled-wrapper
        :label="$t(`flat.floorNumber.label`)"
        class="w-full flex  "
      >
        <template #content>
          <div class="flex flex-col md:flex-row items-start md:items-center gap-x-2">
            <div class="w-full md:w-1/3 mb-3 md:mb-0">
              <filter-from-to
                v-model:from="filter.flatFilter.floorNumber.from"
                v-model:to="filter.flatFilter.floorNumber.to"
                :placeholder-from="$t('common.from')"
                :placeholder-to="$t('common.to')"
                general-class="!p-0"
                is-general
              />
            </div>
            <flat-floor-filter
              v-model:notFirstFloor="filter.flatFilter.notFirstFloor"
              v-model:lastFloor="filter.flatFilter.lastFloor"
            />
          </div>
        </template>
      </labeled-wrapper>

    </div>
    <div class="flex flex-col gap-y-3">
      <labeled-wrapper
        :label="$t(`building.classes.label`)"
        class="w-full"
      >
        <template #content>
          <classes-filter v-model="filter.buildingFilter.classes" />
        </template>
      </labeled-wrapper>
      <labeled-wrapper
        :label="$t('entityActions.filter.houseFilter.lifts.label')"
        class="w-full"
      >
        <template #content>
          <lifts-filter
            v-model:hasPassenger="filter.houseFilter.lifts.hasPassenger"
            v-model:hasCargo="filter.houseFilter.lifts.hasCargo"
          />
        </template>
      </labeled-wrapper>
      <labeled-wrapper
        :label="$t('entityActions.filter.houseFilter.materialTypes.label')"
        class="w-full"
      >
        <template #content>
          <material-filter
            v-model="filter.houseFilter.materialTypes"
          />
        </template>
      </labeled-wrapper>
    </div>
    <div class="flex gap-x-2 items-center">
      <custom-switch v-model="filter.buildingFilter.hasPromotions" />
      <span class="font-semibold text-[16px]">
        {{ $t('entityActions.filter.promotions.hasPromotions') }}
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import MaterialFilter from '~/modules/market-entities-actions/components/filter/filters/MaterialFilter.vue'
import FlatWindowViewFilter
  from '~/modules/market-entities-actions/components/filter/filters/FlatWindowViewFilter.vue'
import FilterDeadlineYear from '~/modules/market-entities-actions/components/filter/filters/FilterDeadlineYear.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import ClassesFilter from '~/modules/market-entities-actions/components/filter/filters/ClassesFilter.vue'
import FlatFloorFilter from '~/modules/market-entities-actions/components/filter/filters/FlatFloorFilter.vue'
import LiftsFilter from '~/modules/market-entities-actions/components/filter/filters/LiftsFilter.vue'
import FlatDecorationsFilter
  from '~/modules/market-entities-actions/components/filter/filters/FlatDecorationsFilter.vue'
import { MarketEntitiesFilter } from '~/modules/market-entities-actions/types/filter/Filter'
import { defaultFilter as defaultFilterFactory } from '~/modules/market-entities-actions/constants/filter/Filter.default'
import { useAppStateStore } from '~/store/app'
import FilterFromTo from '~/components/filters/from-to/FilterFromTo.vue'
import CustomSwitch from '~/ui/switch/CustomSwitch.vue'

defineProps({
  filter: {
    type: Object as PropType<MarketEntitiesFilter>,
    required: true,
  },
})

const defaultFilter = defaultFilterFactory()

const isSm = computed(() => !useAppStateStore().breakpoints.isMd)

</script>

<template>
  <div class="flex gap-x-2 mb-2 justify-between">
    <slot name="suggestionsFilter" />
    <slot name="createNew" />
  </div>
  <div
    class="max-h-full overflow-y-auto overscroll-y-contain scrollbar-thin"
  >
    <custom-table
      class="rounded-md overflow-hidden"
      v-bind="$attrs"
    >
      <thead v-if="isMd">
        <tr>
          <td class="font-semibold text-base">
            {{ $t('suggestionsActions.table.suggestionTitle') }}
          </td>
          <td class="font-semibold text-base">
            {{ $t('suggestionsActions.table.objects') }}
          </td>
          <td class="font-semibold text-base">
            {{ $t('suggestionsActions.table.clients') }}
          </td>
          <td class="font-semibold text-base">
            {{ $t('suggestionsActions.table.creatingDate') }}
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="suggestion in suggestions"
          :key="suggestion.id"
        >
          <template v-if="isMd">
            <td>
              <div class="flex items-center gap-x-2">
                <slot
                  name="select"
                  :suggestion="suggestion"
                />
                <span
                  class="cursor-pointer font-semibold"
                  @click="emits('select', suggestion)"
                >
                  {{ suggestion.title }}
                </span>
              </div>
            </td>
            <td>
              <div
                v-if="suggestion.itemsCount.buildings"
                class="flex gap-x-1 items-center"
              >
                <nuxt-icon
                  name="entities/building"
                />
                <span class="font-semibold">
                  {{ $t('suggestionsActions.table.itemsCount.buildings', {value: suggestion.itemsCount.buildings}) }}
                </span>
              </div>
              <div
                v-if="suggestion.itemsCount.flats"
                class="flex gap-x-1 items-center"
              >
                <nuxt-icon
                  name="common/list"
                />
                <span class="font-semibold text-base">
                  {{ $t('suggestionsActions.table.itemsCount.flats', {value: suggestion.itemsCount.flats}) }}
                </span>
              </div>
            </td>
            <td>
              <slot
                name="clients"
                :suggestion="suggestion"
              />
              <slot
                name="addClient"
                :suggestion="suggestion"
              />
            </td>
            <td class="font-semibold text-neutral-500">
              {{ $d(new Date(suggestion.createTime)) }}
            </td>
            <td>
              <div class="flex items-center gap-x-2 flex-nowrap">
                <slot
                  name="actions"
                  :suggestion="suggestion"
                />
              </div>
            </td>
          </template>
          <template v-else>
            <td class="flex flex-col gap-y-3">
              <div class="flex justify-between">
                <span
                  class="cursor-pointer font-semibold"
                  @click="emits('select', suggestion)"
                >
                  {{ suggestion.title }}
                </span>
                <span class="font-semibold text-neutral-500">
                  {{ $d(new Date(suggestion.createTime)) }}
                </span>
              </div>
              <div class="flex justify-between">
                <div>
                  <slot
                    name="clients"
                    :suggestion="suggestion"
                  />
                </div>
                <div class="flex gap-x-3 flex-wrap justify-end">
                  <div
                    v-if="suggestion.itemsCount.buildings"
                    class="flex gap-x-1 items-center"
                  >
                    <nuxt-icon
                      name="entities/building"
                    />
                    <span class="font-semibold">
                      {{ $t('suggestionsActions.table.itemsCount.buildings', {value: suggestion.itemsCount.buildings}) }}
                    </span>
                  </div>
                  <div
                    v-if="suggestion.itemsCount.flats"
                    class="flex gap-x-1 items-center"
                  >
                    <nuxt-icon
                      name="common/list"
                    />
                    <span class="font-semibold text-base">
                      {{ $t('suggestionsActions.table.itemsCount.flats', {value: suggestion.itemsCount.flats}) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex gap-3 flex-wrap">
                <slot
                  name="select"
                  :suggestion="suggestion"
                />
                <slot
                  name="addClient"
                  :suggestion="suggestion"
                />
                <slot
                  name="actions"
                  :suggestion="suggestion"
                />
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </custom-table>
  </div>
</template>
<script setup lang="ts">
import CustomTable from '~/ui/tables/CustomTable.vue'
import type { ManagingSuggestion } from '~/modules/suggestions/SuggestionsModule'
import { useAppStateStore } from '~/store/app'

defineProps({
  suggestions: {
    type: Array as PropType<Array<ManagingSuggestion>>,
    required: true,
  },
})
const emits = defineEmits<{(e: 'select', value: ManagingSuggestion): void}>()

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
</script>

<template>
  <div
    class="flex gap-2 justify-between w-full h-[46px]"
    :class="{'md:hidden': !store.suggestion.manager?.contactsSettings?.messengers?.whatsapp?.phoneNumber}"
  >
    <flat-building
      class="md:hidden"
      :building="flat.building"
    />
    <flats-btn-book
      v-if="store.suggestion.manager?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
      :class="buttonClass"
      :flat="flat"
      :mobile-phone="store.suggestion.manager.contactsSettings?.messengers.whatsapp.phoneNumber"
    />
    <flats-btn-book-viewing
      v-if="store.suggestion.manager?.contactsSettings?.messengers?.whatsapp?.phoneNumber"
      :class="buttonClass"
      :flat="flat"
      :mobile-phone="store.suggestion.manager.contactsSettings.messengers.whatsapp.phoneNumber"
    />
  </div>
  <nuxt-link
    :to="{ name: ROUTES_NAMES.presentationBuildingPageFromSuggestion, params: { ids: [btoa(String(flat.building.id)), store.suggestionId] }}"
    :target="appStateStore.isWeb ? '_blank': ''"
    class="w-full"
  >
    <custom-button
      color="primary"
      pill
      class="block w-full"
    >
      {{ $t('common.details') }}
    </custom-button>
  </nuxt-link>
</template>
<script setup lang="ts">
import FlatBuilding from '~/components/flats/flat-list-card/FlatBuilding.vue'
import FlatsBtnBook from '~/components/flats/buttons/presentation/FlatsBtnBook.vue'
import FlatsBtnBookViewing from '~/components/flats/buttons/presentation/FlatsBtnBookViewing.vue'
import type { Flat } from '~/common/types/flat/Flat'
import { useSuggestionsStore } from '~/modules/suggestions/store'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import { useAppStateStore } from '~/store/app'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import { btoa } from '~/common/helpers/window'

const store = useSuggestionsStore()
const appStateStore = useAppStateStore()

const props = defineProps({
  flat: {
    type: Object as PropType<Flat>,
    required: true,
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

</script>

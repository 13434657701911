import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { Pagination } from '~/common/types/pagination/Pagination'
import { FilterService } from '~/modules/market-entities-actions/services/FilterService'

export class PaginationService {
  store

  filterService: FilterService

  constructor() {
    this.store = useMarketEntitiesActionsStore()
    this.filterService = new FilterService()
  }

  public changeCurrentPage(
    newPage: number,
    filter = this.store.filter,
    sort = this.store.sort,
  ): Promise<any> {
    this.setCurrentPage(newPage)
    return this.filterService.search()
  }

  public setPagination(pagination: Pagination) {
    this.store.setPagination(pagination)
  }

  private setCurrentPage(page: number): void {
    this.store.setPaginationCurrentPage(page)
  }
}

<template>
  <custom-tooltip placement="bottom">
    <template #content>
      {{ $t('entityActions.filter.allFilters.modal') }}
    </template>
    <custom-button-icon
      :class="buttonClass"
      icon="actions/additional-filters"
      icon-class="text-[1.65rem]"
      round
      @click="isModalVisible = true"
    />
  </custom-tooltip>
  <modal-or-bottom-sheet
    v-model="isModalVisible"
    :modal-props="{size: 'w-full max-w-[800px]', centered: false, withOutBodyOverflow: true}"
    :drawer-props="{footerClass: 'px-[22px] pt-[26px] pb-[22px] border-t border-neutral-35'}"
    :title="$t('entityActions.filter.allFilters.modal')"
  >
    <template #default>
      <slot name="filters" />
    </template>
    <template #footer>
      <div class="w-full">
        <slot name="footer" :close="close" />
      </div>
    </template>
  </modal-or-bottom-sheet>
</template>
<script setup lang="ts">
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomTooltip from '~/ui/tooltips/CustomTooltip.vue'

defineProps({
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const isModalVisible = ref(false)
const close = () => {
  isModalVisible.value = false
}

</script>

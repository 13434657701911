import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import type { ManagingSuggestion } from '~/modules/suggestions/types/Suggestion'
import type { Building } from '~/modules/building/BuildingModule'

export class SuggestionsHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market/development/suggestions',
    getAll: '/search',
    getByUniqueId: '/by-unique-id',
    addBuildings: '/add-buildings',
    removeBuildings: '/remove-buildings',
    addFlats: '/add-flats',
    removeFlats: '/remove-flats',
    updateStatus: '/update-status',
    clients: '/clients',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  getSuggestion(uniqueId: string): Promise<HttpHandledResponse<ManagingSuggestion>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.getByUniqueId}/${uniqueId}`)
  }

  getSuggestions(page = 1): Promise<HttpHandledResponse<{data: Array<ManagingSuggestion>, nextPageUrl: string | null}>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.getAll}`, { page })
  }

  createSuggestion(title: ManagingSuggestion['title'], manager: ManagingSuggestion['manager']): Promise<HttpHandledResponse<ManagingSuggestion>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}`, {
      title, manager,
    })
  }

  removeSuggestion(id: ManagingSuggestion['id']): Promise<HttpHandledResponse<ManagingSuggestion>> {
    return this.httpInstance.post(`${this.endPoints.firstPart}${this.endPoints.updateStatus}`, {
      ids: [id],
      status: 'deleted',
    })
  }

  addBuildingsToSuggestion(buildingIds: Array<Building['id']>, suggestion: ManagingSuggestion) {
    return this.httpInstance.post(`${this.endPoints.firstPart}/${suggestion.id}${this.endPoints.addBuildings}`, {
      items: buildingIds,
    })
  }

  removeBuildingsFromSuggestion(buildingIds: Array<Building['id']>, suggestion: ManagingSuggestion) {
    return this.httpInstance.post(`${this.endPoints.firstPart}/${suggestion.id}${this.endPoints.removeBuildings}`, {
      items: buildingIds,
    })
  }

  addFlatsToSuggestion(flatIds: Array<number>, suggestion: ManagingSuggestion) {
    return this.httpInstance.post(`${this.endPoints.firstPart}/${suggestion.id}${this.endPoints.addFlats}`, {
      items: flatIds,
    })
  }

  removeFlatsFromSuggestion(flatIds: Array<number>, suggestion: ManagingSuggestion) {
    return this.httpInstance.post(`${this.endPoints.firstPart}/${suggestion.id}${this.endPoints.removeFlats}`, {
      items: flatIds,
    })
  }

  addClientToSuggestion(suggestionId: number, clientId: number): Promise<HttpHandledResponse<ManagingSuggestion>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}/${suggestionId}${this.endPoints.clients}/${clientId}/add`)
  }

  removeClientFromSuggestion(suggestionId: number, clientId: number): Promise<HttpHandledResponse<ManagingSuggestion>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}/${suggestionId}${this.endPoints.clients}/${clientId}/remove`)
  }
}

export class CalculateArea {
  public defineModule(): void {
    ymaps.modules.define('calculateArea', [], provide => {
      // Equatorial radius of Earth
      const RADIUS = 6378137

      function getGeoJsonGeometry(feature: ymaps.Feature<unknown>): ymaps.AllGeometry {
        if (feature.type === 'Feature') {
          return feature.geometry
        } if (feature.geometry && feature.geometry.getType && feature.geometry.getCoordinates) {
          if (feature.geometry.getType() === 'Circle' && feature.geometry.getRadius) {
            return {
              type: 'Circle',
              coordinates: feature.geometry.getCoordinates() as ymaps.Coordinate,
              radius: feature.geometry.getRadius(),
            }
          }
          return {
            type: feature.geometry.getType(),
            coordinates: feature.geometry.getCoordinates() as number[][],
          } as ymaps.AllGeometry
        }
        throw new Error('calculateArea: Unknown input object.')
      }

      /**
       * Returns:
       * {Number} The approximate signed geodesic area of the polygon in square
       *     meters.
       */

      function rad(_: number) {
        return _ * Math.PI / 180
      }

      function ringArea(coords: number[][]) {
        let p1: number[]
        let p2: number[]
        let p3: number[]
        let lowerIndex: number
        let middleIndex: number
        let upperIndex: number
        let i: number
        let area = 0
        const coordsLength = coords.length
        const longitude = ymaps.meta.coordinatesOrder === 'latlong' ? 1 : 0
        const latitude = ymaps.meta.coordinatesOrder === 'latlong' ? 0 : 1

        if (coordsLength > 2) {
          for (i = 0; i < coordsLength; i += 1) {
            // i = N-2
            if (i === coordsLength - 2) {
              lowerIndex = coordsLength - 2
              middleIndex = coordsLength - 1
              upperIndex = 0
            } else if (i === coordsLength - 1) {
              // i = N-1
              lowerIndex = coordsLength - 1
              middleIndex = 0
              upperIndex = 1
            } else {
              // i = 0 to N-3
              lowerIndex = i
              middleIndex = i + 1
              upperIndex = i + 2
            }
            p1 = coords[lowerIndex]
            p2 = coords[middleIndex]
            p3 = coords[upperIndex]
            area += (rad(p3[longitude]) - rad(p1[longitude])) * Math.sin(rad(p2[latitude]))
          }

          area = area * RADIUS * RADIUS / 2
        }

        return area
      }

      function polygonArea(coords: number[][][]) {
        let area = 0
        if (coords && coords.length > 0) {
          area += Math.abs(ringArea(coords[0]))
          for (let i = 1; i < coords.length; i += 1) {
            area -= Math.abs(ringArea(coords[i]))
          }
        }
        return area
      }

      function calculateJsonGeometryArea(geometry: ymaps.AllGeometry): number | undefined {
        let area = 0
        let i
        switch (geometry.type) {
          case 'Polygon':
            return polygonArea(geometry.coordinates)
          case 'MultiPolygon':
            for (i = 0; i < geometry.coordinates.length; i += 1) {
              area += polygonArea(geometry.coordinates[i])
            }
            return area
          case 'Rectangle':
            return polygonArea([[geometry.coordinates[0],
              [geometry.coordinates[0][0] as number,
                geometry.coordinates[1][1]],
              geometry.coordinates[1],
              [geometry.coordinates[1][0], geometry.coordinates[0][1]],
              geometry.coordinates[0],
            ]] as number[][][])
          case 'Circle':
            return geometry.radius && Math.PI * geometry.radius ** 2
          case 'Point':
          case 'MultiPoint':
          case 'LineString':
          case 'MultiLineString':
            return 0
        }
        return undefined
      }

      function calculateArea(feature: ymaps.Feature<unknown>) {
        const geoJsonGeometry = getGeoJsonGeometry(feature)
        return calculateJsonGeometryArea(geoJsonGeometry)
      }

      provide(calculateArea)
    })
  }
}

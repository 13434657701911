import { HttpApiService } from '~/services/http/HttpApiService'

export default class QuickDealHttpApiService extends HttpApiService {
  constructor() {
    const runtimeConfig = useRuntimeConfig()
    const { quickDealApiUrl } = runtimeConfig.public

    super(quickDealApiUrl)
  }

  public setAuthToken(token: string): void {
    this.headers.Authorization = `Bearer ${token}`
  }

  public resetAuthToken(): void {
    delete this.headers.Authorization
  }
}

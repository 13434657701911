<template>
  <div class="flex flex-col h-full overflow-y-hidden">
    <div
      v-if="isFetchingSuggestions"
      class="max-w-[25%] min-h-full m-auto flex justify-center items-center"
    >
      <custom-cube-spinner />
    </div>
    <suggestions-list
      v-else
      :suggestions="filteredSuggestions"
      @select="getSuggestion($event)"
    >
      <template
        v-if="userSuggestions.length && variant === 'modal'"
        #suggestionsFilter
      >
        <custom-button-group
          v-model="filter"
          class="h-[38px]"
          :is-adaptive="false"
          :options="suggestionFilterOptionsForGroup"
          button-class="flex font-semibold items-center px-4"
          size="none"
        />
      </template>
      <template
        v-if="variant === 'modal'"
        #createNew
      >
        <suggestion-create-btn
          @click="isShowForm = true"
        />
      </template>
      <template v-if="isAddIconsVisible" #select="{suggestion}">
        <custom-button-icon
          v-if="!disabledSuggestions.includes(suggestion)"
          icon="common/circle-plus-regular"
          round
          color="secondary"
          class="bg-white shrink-0"
          @click="addItemsToSuggestion(suggestion)"
        />
        <div
          v-else
          class="w-[38px] h-[38px] bg-primary-dark rounded-full flex items-center justify-center shrink-0"
        >
          <nuxt-icon
            name="common/check"
            class="text-white text-[12px]"
          />
        </div>
      </template>
      <template #clients="{suggestion}">
        <suggestion-clients
          :suggestion="suggestion"
          @change-client-in-all-suggestions="changeClientInAllSuggestions"
          @change-clients="changeClientsInSuggestion"
        />
      </template>
      <template #addClient="{suggestion}">
        <div class="flex items-center">
          <custom-button-icon
            v-if="clientToSuggestion && !disabledClientToSuggestionButton(suggestion)"
            color="secondary"
            class="bg-white"
            round
            icon="actions/person-plus"
            @click="addClientToSuggestion(suggestion)"
          />
          <div
            v-if="disabledClientToSuggestionButton(suggestion)"
            class="w-[38px] h-[38px] bg-primary-dark rounded-full flex items-center justify-center shrink-0"
          >
            <nuxt-icon
              name="common/check"
              class="text-white text-[12px]"
            />
          </div>
          <suggestion-add-client
            v-if="!clientToSuggestion"
            :suggestion="suggestion"
            @add-clients="changeClientsInSuggestion"
          />
        </div>
      </template>
      <template #actions="{suggestion}">
        <suggestion-messenger-btn
          :suggestion="suggestion"
          icon-name="entities/whats-app"
          :messenger-component="WhatsAppBtn"
          link="https://wa.me/"
          :text-to-send="textToSend(suggestion)"
        />
        <suggestion-messenger-btn
          :suggestion="suggestion"
          icon-name="entities/telegram"
          :messenger-component="TelegramBtn"
          icon-filled
          link="https://t.me/"
        />
        <suggestion-copy-link
          button-class="bg-white"
          :suggestion="suggestion"
        />
        <suggestion-open-btn
          @click="getSuggestion(suggestion)"
        />
        <suggestion-btn-delete
          class="bg-white"
          :suggestion="suggestion"
          @remove-suggestion="removeSuggestion"
        />
      </template>
    </suggestions-list>
    <custom-button
      v-if="nextPage"
      variant="withoutBorders"
      class="block ml-auto"
      @click="getSuggestions"
    >
      {{ $t('common.more') }}
    </custom-button>
  </div>
  <modal-or-bottom-sheet
    v-if="variant === 'modal'"
    v-model="isShowForm"
    :title="$t('suggestionsActions.create.title.full')"
    size="md"
    :modal-props="{centered: false, hideFooter: true}"
  >
    <suggestion-form @create="addNewSuggestion" />
  </modal-or-bottom-sheet>
  <modal-or-bottom-sheet
    v-model="isShowingSuggestion"
    :title="currentSuggestion?.title"
    size="lg"
    :modal-props="{centered: false, hideFooter: true}"
  >
    <managing-suggestion-popup
      :suggestion="currentSuggestion"
      :loading="isFetchingForSuggestion"
      @remove:building="buildingRemovingHandler(currentSuggestion as ManagingSuggestion, [$event])"
      @remove:flat="flatRemovingHandler(currentSuggestion as ManagingSuggestion, [$event])"
    >
      <template #actions="{suggestion}">
        <suggestion-copy-link
          :suggestion="suggestion"
        />
        <suggestion-btn-delete
          :suggestion="suggestion"
          @remove-suggestion="removeSuggestion"
        />
      </template>
    </managing-suggestion-popup>
  </modal-or-bottom-sheet>
</template>

<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import SuggestionForm from '~/modules/building/components/actions/suggestions-management/SuggestionForm.vue'
import SuggestionsList from '~/modules/building/components/actions/suggestions-management/SuggestionsList.vue'
import ManagingSuggestionPopup from '~/modules/suggestions/components/managing-suggestion/ManagingSuggestionPopup.vue'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import SuggestionBtnDelete from '~/modules/building/components/actions/suggestions-management/SuggestionDeleteBtn.vue'
import SuggestionCopyLink from '~/modules/building/components/actions/suggestions-management/SuggestionCopyLink.vue'
import { type ManagingSuggestion, SuggestionsService } from '~/modules/suggestions/SuggestionsModule'
import { BuildingActionsService, useBuildingStore } from '~/modules/building/BuildingModule'
import type { ChessboardFlat } from '~/modules/chessboard/types/Chessboard.flat'
import { ArrayHelper } from '~/common/helpers/arrays'
import { SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import SuggestionOpenBtn from '~/modules/building/components/actions/suggestions-management/SuggestionOpenBtn.vue'
import SuggestionClients
  from '~/modules/building/components/actions/suggestions-management/clients/SuggestionClients.vue'
import type { Client } from '~/modules/clients/types/Client'
import { ObjectHelper } from '~/common/helpers/object'
import { useUserStore } from '~/modules/user/store'
import { SuggestionsFilter } from '~/modules/suggestions/types/Suggestions.filter'
import { SUGGESTIONS_FILTER } from '~/modules/suggestions/constants/Suggestions.filter'
import TelegramBtn from '~/components/buttons/TelegramBtn.vue'
import WhatsAppBtn from '~/components/buttons/WhatsAppBtn.vue'
import SuggestionMessengerBtn
  from '~/modules/building/components/actions/suggestions-management/SuggestionMessengerBtn.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomButtonGroup from '~/ui/buttons/CustomButtonGroup.vue'
import SuggestionCreateBtn from '~/modules/building/components/actions/suggestions-management/SuggestionCreateBtn.vue'
import { getEntityName } from '~/common/helpers/getEntityName'
import { COLOR } from '~/ui/constants/color'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'
import SuggestionAddClient from './clients/SuggestionAddClient.vue'

defineProps({
  isAddIconsVisible: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String as PropType<'page' | 'modal'>,
    default: 'modal',
  },
})

const service = new SuggestionsService()
const store = useBuildingStore()
const userStore = useUserStore()

const nuxtApp = useNuxtApp()

const storage: QdStorage = nuxtApp.$appStorage

const { t } = useI18n()
const createConfirmationModal = confirmationModal()

const userId = computed(() => userStore.user.id)
const suggestions = ref<Array<ManagingSuggestion>>([])
const userSuggestions = computed(() => suggestions.value.filter(suggestion => suggestion.manager.id === userId.value))
const filteredSuggestions = computed(() => (filter.value === SUGGESTIONS_FILTER.user ? userSuggestions.value : suggestions.value))
const filter = computed<SuggestionsFilter>({
  get: () => store.suggestionsFilter,
  set: value => {
    store.setSuggestionsFilter(value)
  },
})

const disabledClientToSuggestionButton = (suggestion: ManagingSuggestion) => {
  if (clientToSuggestion.value) {
    return suggestion.clients.some(client => client.id === clientToSuggestion.value.id)
  }
  return false
}

const lastErrorTime = computed(() => store.lastErrorSuggestionsTime)

const clientToSuggestion = computed(() => store.clientToAddToSuggestion)

const GAP_BETWEEN_ERRORS = 300000
const textToSend = (suggestion: ManagingSuggestion) => t('suggestions.textToSend', { value: `${nuxtApp.$config.public.estateMarketAppUrl}/suggestions/${suggestion?.uniqueId}` })

const isFetchingSuggestions = ref(false)
const currentSuggestion = ref<ManagingSuggestion | null>(null)
const isFetchingForSuggestion = ref(false)
const nextPage = ref<string | null>(null)
const isShowForm = ref(false)

const isInitialLoad = ref(true)

const isShowingSuggestion = computed({
  get: () => currentSuggestion.value !== null || isFetchingForSuggestion.value,
  set: value => {
    if (!value) {
      currentSuggestion.value = null
    }
  },
})

const itemsEntityType = computed<'building' | 'flat'>(() => (store.buildingsToAddToSuggestion.length ? 'building' : 'flat'))

const getDisabledSuggestions = () => {
  if (itemsEntityType.value === 'building') {
    const buildings = store.buildingsToAddToSuggestion
    return suggestions.value.filter(suggestion => buildings.every(({ id }) => suggestion.buildings.some(building => building.id === id)))
  }
  if (itemsEntityType.value === 'flat') {
    const flats = store.flatsToAddToSuggestion
    return suggestions.value.filter(suggestion => flats.every(({ id }) => {
      const { buildings } = suggestion
      return buildings.some(building => building.flats?.some(flat => flat.id === id))
    }))
  }
  return []
}
const disabledSuggestions = computed<Array<ManagingSuggestion>>(getDisabledSuggestions)

const extendSuggestion = (suggestion: ManagingSuggestion) => {
  suggestion.buildings.forEach(building => {
    building.flats = BuildingActionsService.getBuildingFlats(building)
  })
}
const getSuggestions = () => {
  if (isInitialLoad.value || nextPage.value) {
    isFetchingSuggestions.value = true
    service.getSuggestions(nextPage.value)
      .then(suggestionsData => {
        suggestions.value.push(...suggestionsData.data)
        nextPage.value = suggestionsData.nextPageUrl
        isInitialLoad.value = false
      })
      .catch(() => {
        const currentTime = new Date().getTime()

        if (!lastErrorTime.value || (currentTime - lastErrorTime.value) > GAP_BETWEEN_ERRORS) {
          storage.setItem('lastErrorSuggestionsTime', currentTime)
          toast(t('entityActions.search.errors.first'), { variant: COLOR.danger })
        } else {
          toast(t('entityActions.search.errors.second'), { variant: COLOR.danger })
        }
      })
      .finally(() => {
        isFetchingSuggestions.value = false
      })
  }
}
const getSuggestion = (suggestion: ManagingSuggestion) => {
  isFetchingForSuggestion.value = true
  service.getSuggestion(suggestion.uniqueId)
    .then(suggestionsData => {
      extendSuggestion(suggestionsData)
      Object.assign(suggestion, suggestionsData)
      currentSuggestion.value = suggestion
      isFetchingForSuggestion.value = false
    })
}

const addNewSuggestion = (suggestion: ManagingSuggestion) => {
  isShowForm.value = false
  suggestions.value.unshift(suggestion)
}

const removeSuggestion = ({ suggestionTitle, suggestionId }) => {
  createConfirmationModal({
    title: t('suggestionsActions.removeSuggestion.confirm', { value: suggestionTitle }),
  }).then(value => {
    if (value) {
      service.removeSuggestion(suggestionId)
        .then(() => {
          suggestions.value = suggestions.value.filter(suggestion => suggestion.id !== suggestionId)
          if (currentSuggestion.value?.id === suggestionId) {
            currentSuggestion.value = null
          }
          toast(t('suggestionsActions.removeSuggestion.toast.success'), { variant: 'success' })
        })
        .catch(error => {
          toast(`${t('suggestionsActions.removeSuggestion.toast.error')} ${error.message}`, { variant: 'danger' })
        })
    }
  })
}

const addBuildingsToSuggestion = (suggestion: ManagingSuggestion) => {
  const buildings = store.buildingsToAddToSuggestion as ManagingSuggestion['buildings']
  service.addBuildingsToSuggestion(buildings, suggestion)
    .then(data => {
      extendSuggestion(data)
      suggestion.buildings = data.buildings
      suggestion.itemsCount = data.itemsCount
      toast(t('suggestionsActions.addToSuggestion.toast.success'), { variant: 'success' })
    })
}
const addClientToSuggestion = (suggestion: ManagingSuggestion) => {
  if (clientToSuggestion.value) {
    service.addClientToSuggestion(suggestion.id, clientToSuggestion.value.id)
      .then(data => {
        changeClientsInSuggestion(suggestion.id, data.clients)
        toast(t('suggestionsActions.client.add.toast.success'), { variant: 'success' })
      })
  }
}
const removeBuildingsFromSuggestion = (suggestion: ManagingSuggestion, buildings: Array<SuggestionBuilding>) => {
  service.removeBuildingsFromSuggestion(buildings, suggestion)
    .then(data => {
      extendSuggestion(data)
      suggestion.buildings = suggestion.buildings.filter(({ id }) => data.buildings.some(building => building.id === id))
      suggestion.itemsCount = data.itemsCount

      toast(t('suggestionsActions.removeBuildingFromSuggestion.toast.success'), { variant: 'success' })
    })
}

const addFlatsToSuggestion = (suggestion: ManagingSuggestion) => {
  const flats = store.flatsToAddToSuggestion
  service.addFlatsToSuggestion(flats, suggestion)
    .then(data => {
      extendSuggestion(data)
      suggestion.buildings = data.buildings
      suggestion.itemsCount = data.itemsCount

      toast(t('suggestionsActions.addToSuggestion.toast.success'), { variant: 'success' })
    })
}

const removeFlatsFromSuggestion = (suggestion: ManagingSuggestion, flats: Array<ChessboardFlat>) => {
  service.removeFlatsFromSuggestion(flats, suggestion)
    .then(data => {
      extendSuggestion(data)
      suggestion.buildings.forEach(building => {
        const updatedBuilding = data.buildings.find(({ id }) => building.id === id)
        if (!updatedBuilding) {
          ArrayHelper.delete(suggestion.buildings, suggestionBuilding => suggestionBuilding === building)
        } else {
          building.flats = building.flats.filter(flat => updatedBuilding.flats.some(({ id }) => id === flat.id))
          building.houses.forEach(house => {
            house.sections.forEach(section => {
              section.flats = section.flats.filter(flat => updatedBuilding.flats.some(({ id }) => id === flat.id))
            })
          })
        }
      })
      suggestion.itemsCount = data.itemsCount
      toast(t('suggestionsActions.removeFlatFromSuggestion.toast.success'), { variant: 'success' })
    })
}

const addItemsToSuggestion = (suggestion: ManagingSuggestion) => {
  if (itemsEntityType.value === 'building') {
    addBuildingsToSuggestion(suggestion)
  }
  if (itemsEntityType.value === 'flat') {
    addFlatsToSuggestion(suggestion)
  }
}

const buildingRemovingHandler = (suggestion: ManagingSuggestion, buildings: Array<SuggestionBuilding>) => {
  createConfirmationModal({
    title: t('suggestionsActions.removeBuildingFromSuggestion.confirm', {
      value: getEntityName({
        name: buildings[0].name,
        internationalName: buildings[0].internationalName,
        entityCountry: buildings[0].address?.countryIsoCode,
      }),
    }),
  }).then(value => {
    if (value) {
      removeBuildingsFromSuggestion(suggestion, buildings)
    }
  })
}

const flatRemovingHandler = (suggestion: ManagingSuggestion, flats: Array<ChessboardFlat>) => {
  createConfirmationModal({
    title: t('suggestionsActions.removeFlatFromSuggestion.confirm', { value: flats[0].flatNumber }),
  }).then(value => {
    if (value) {
      removeFlatsFromSuggestion(suggestion, flats)
    }
  })
}

const changeClientInAllSuggestions = (client: Client) => {
  const copySuggestions = ObjectHelper.copy<ManagingSuggestion[]>(suggestions.value)
  copySuggestions.forEach(suggestion => {
    const clientId = suggestion.clients?.findIndex(clientInSuggestion => client.id === clientInSuggestion.id)

    if (clientId > -1) {
      suggestion.clients[clientId] = client
    }
  })
  suggestions.value = copySuggestions
}

const changeClientsInSuggestion = (suggestionId: number, clients: Client[]) => {
  const copySuggestions = ObjectHelper.copy<ManagingSuggestion[]>(suggestions.value)
  const changingSuggestionIndex = copySuggestions.findIndex(suggestion => suggestionId === suggestion.id)

  if (changingSuggestionIndex > -1) {
    copySuggestions[changingSuggestionIndex].clients = clients
    suggestions.value = copySuggestions
  }
}

const suggestionFilterOptionsForGroup = [
  {
    value: SUGGESTIONS_FILTER.all,
    label: t('suggestionsActions.filter.all'),
    icon: 'common/list',
    iconClass: 'text-[18px]',
  },
  {
    value: SUGGESTIONS_FILTER.user,
    label: t('suggestionsActions.filter.user'),
    icon: 'common/check',
  },
]

watch(filter, () => {
  if (filter.value === SUGGESTIONS_FILTER.all) {
    store.setSuggestionsFilter(SUGGESTIONS_FILTER.all)
  }
  if (filter.value === SUGGESTIONS_FILTER.user) {
    store.setSuggestionsFilter(SUGGESTIONS_FILTER.user)
  }
})
watch(userSuggestions, () => {
  if (!userSuggestions.value.length) {
    filter.value = SUGGESTIONS_FILTER.all
  }
})

onBeforeMount(() => {
  getSuggestions()
})

defineExpose({
  addNewSuggestion,
})

onUnmounted(() => {
  if (clientToSuggestion.value) {
    store.clientToAddToSuggestion = null
  }
})
</script>

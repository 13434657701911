<template>
  <custom-range
    v-model="stake"
    :max="maxStake"
    :step="0.5"
    :label="$t('mortgage.calculator.inputs.stake.label')"
  >
    <template #suffixInner>
      <span class="text-neutral-500 font-medium inter">
        %
      </span>
    </template>
    <template #labelSuffix>
      <div class="flex items-center">
        <template
          v-for="(fastSetButton, inx) in fastSetButtons"
          :key="fastSetButton"
        >
          <span
            v-if="inx > 0"
            class="mx-3 inter font-semibold text-sm text-neutral-500"
          >
            /
          </span>
          <bank-mortgage-input-btn-fast-set
            @click="setStake(fastSetButton)"
          >
            {{ fastSetButton }}%
          </bank-mortgage-input-btn-fast-set>
        </template>
      </div>
    </template>
  </custom-range>
</template>

<script lang="ts" setup>
import CustomRange from '@/ui/ranges/CustomRange.vue'
import BankMortgageInputBtnFastSet from '~/modules/bank/components/mortgage/BankMortgageInputBtnFastSet.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: null,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: number): void }>()

const fastSetButtons = [6, 8, 12, 15]

const maxStake = 20

const stake = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const setStake = (num: number) => {
  stake.value = num
}
</script>

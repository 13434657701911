<template>
  <custom-input
    v-model="currentRangeForInput"
    class="qm-range"
    input-class="border-b-0 rounded-b-none bg-inherit"
    :label="label"
  >
    <template #appendWrapper>
      <input
        ref="rangeRef"
        v-model="currentRange"
        type="range"
        :max="max"
        :min="min"
        :step="step"
        class="appearance-none w-full h-[2px] bg-neutral-150 cursor-pointer bg-size bg-gradient-to-b from-primary to-primary bg-no-repeat"
      >
    </template>
    <template #suffixInner>
      <slot name="suffixInner" />
    </template>
    <template #labelSuffix>
      <slot name="labelSuffix" />
    </template>
  </custom-input>
</template>

<script lang="ts" setup>
import CustomInput from '@/ui/inputs/CustomInput.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: null,
  },
  withFormatingInput: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: number): void }>()

const { n } = useI18n()

const rangeRef = ref<HTMLInputElement | null>(null)
const currentRange = computed({
  get: () => props.modelValue,
  set: value => {
    emits('update:modelValue', value)
  },
})

const currentRangeForInput = computed({
  get: () => (props.withFormatingInput ? n(Number(currentRange.value)) : currentRange.value),
  set: value => {
    if (props.withFormatingInput) {
      currentRange.value = Number((value as string).replace(/[^\d]/g, ''))
    } else {
      currentRange.value = value as number
    }
  },
})

const getProgress = (currentValue: number, minValue: number, maxValue: number) => ((currentValue - minValue) / (maxValue - minValue)) * 100

const setCSSProgress = (progress: number) => {
  if (rangeRef.value) {
    rangeRef.value.style.backgroundSize = `${progress}% 100%`
  }
}

watchEffect(() => {
  if (rangeRef.value) {
    const progress = getProgress(
      currentRange.value,
      props.min,
      props.max,
    )

    setCSSProgress(progress)
  }
})
</script>

<style lang="scss">
.qm-range {
  input[type="range"] {
    background-size: 0% 100%;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none h-[24px] w-[24px] rounded-full bg-white;
    box-shadow: 0px 4px 4px 0px #00000040;

    &:hover {
      @apply bg-primary;
    }
  }

  input[type="range"]::-moz-range-thumb {
    @apply appearance-none h-[24px] w-[24px] rounded-full bg-white;
    box-shadow: 0px 4px 4px 0px #00000040;

    &:hover {
      @apply bg-primary;
    }
  }

  input[type="range"]::-ms-thumb {
    @apply appearance-none h-[24px] w-[24px] rounded-full bg-white;
    box-shadow: 0px 4px 4px 0px #00000040;

    &:hover {
      @apply bg-primary;
    }
  }

  input[type=range]::-webkit-slider-runnable-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type=range]::-moz-range-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type="range"]::-ms-track {
    appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
</style>

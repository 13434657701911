<template>
  <custom-button-icon
    icon="actions/trash"
    color="secondary"
    round
    @click="removeSuggestion({suggestionTitle: suggestion.title, suggestionId: suggestion.id})"
  />
</template>
<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import type { ManagingSuggestion } from '~/modules/suggestions/SuggestionsModule'

defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
})

const emits = defineEmits(['removeSuggestion'])

const removeSuggestion = (suggestion: {suggestionTitle: string, suggestionId: number }) => {
  emits('removeSuggestion', suggestion)
}

</script>

<template>
  <template v-if="isMd || !withMobileTemplate">
    <custom-card :class="mdCardClass">
      <div class="flex gap-x-3 w-full flex-col md:flex-col lg:flex-col xl:flex-row gap-y-3">
        <div class="w-full max-w-full md:max-w-full xl:max-w-[700px]">
          <slot name="market-buildings" />
        </div>
        <div class="flex gap-x-3 w-full">
          <slot name="filters" />
          <slot name="saved-filters" />
          <slot name="additional-filters" />
          <slot name="search-button" />
          <slot name="reset-filter-button" />
        </div>
      </div>
      <div
        v-if="$slots['selected-entities-filters']"
        class="mt-[20px]"
      >
        <slot name="selected-entities-filters" />
      </div>
    </custom-card>
  </template>
  <template v-else>
    <slot
      v-if="!onlyDrawer"
      name="saved-filters"
    />
    <custom-button
      v-if="!onlyDrawer"
      variant="outline"
      pill
      class="grow font-semibold"
      @click="isModelExpanded = true"
    >
      {{ $t('entityActions.filter.button') }}
    </custom-button>
    <custom-drawer
      v-model="isModelExpanded"
      footer-class="w-full bottom-0 sticky py-[22px] px-2 border-t border-neutral-35 bg-white gap-x-0"
    >
      <div>
        <slot name="market-buildings" />
        <slot name="filters" />
      </div>
      <template #footer>
        <div class="grow">
          <slot v-if="!isMd" name="reset-button" />
        </div>
        <div>
          <slot
            name="search-button"
            :close="() => {isModelExpanded = false}"
          />
        </div>
      </template>
    </custom-drawer>
  </template>
</template>
<script setup lang="ts">
import CustomCard from '~/ui/cards/CustomCard.vue'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'
import { useAppStateStore } from '~/store/app'

const props = defineProps({
  onlyDrawer: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: null,
  },
  withMobileTemplate: {
    type: Boolean,
    default: true,
  },
  mdCardClass: {
    type: [String, Object, Array],
    default: 'min-h-[140px]',
  },
})

const emits = defineEmits<{(e: 'update:modelValue', value: boolean): void}>()

const isMd = computed(() => useAppStateStore().breakpoints.isMd)

const isExpanded = ref(false)
const isModelExpanded = computed({
  get: () => (props.modelValue === null ? isExpanded.value : props.modelValue),
  set: (value: boolean) => {
    if (props.modelValue === null) {
      isExpanded.value = value
    } else {
      emits('update:modelValue', value)
    }
  },
})
</script>

<template>
  <custom-button-icon
    color="secondary"
    class="bg-white"
    round
    icon="actions/person-plus"
    @click="isModalOpen = true"
  />
  <suggestion-add-client-modal
    v-if="isModalOpen"
    v-model="isModalOpen"
    :suggestion="suggestion"
    @add-clients="addClients"
  />
</template>

<script setup lang="ts">
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import SuggestionAddClientModal from '~/modules/building/components/actions/suggestions-management/clients/SuggestionAddClientModal.vue'
import type { ManagingSuggestion } from '~/modules/suggestions/SuggestionsModule'
import type { Client } from '~/modules/clients/types/Client'

defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
})

const emits = defineEmits<{(e: 'addClients', suggestionId: number, clients: Client[]): void}>()

const isModalOpen = ref(false)

const addClients = (id: number, clients: Client[]) => {
  emits('addClients', id, clients)
}
</script>

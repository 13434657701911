<template>
  <client-form-field-template
    :title="$t('clients.fixation.form.developer.title')"
    :progress-step-percent="progressStepPercent"
    :progress-step-done="progressStepDone"
  >
    <labeled-wrapper
      label-class="font-medium text-lg inter"
      :label="$t('clients.fixation.form.developer.label')"
    >
      <template #content>
        <select-auto-suggest
          v-model="developer"
          :fetch="searchDevelopersByName"
          option-label="name"
          :invalid-feedback="errorDeveloper"
          infinite-scroll
          :placeholder="$t('clients.fixation.form.developer.placeholder')"
        />
      </template>
    </labeled-wrapper>
    <div
      v-if="developer"
      class="mt-6 flex flex-col gap-y-1"
    >
      <div
        class="flex w-full items-center justify-between gap-x-6"
      >
        <custom-badge
          class="font-semibold"
          pill
        >
          {{ developer.name }}
          <nuxt-icon
            name="actions/close"
            class="ml-2 cursor-pointer"
            @click="deselectDeveloper"
          />
        </custom-badge>
        <span
          v-if="!isEmpty(developer.clientFixationTerms)"
          class="text-sm font-semibold text-primary cursor-pointer shrink-0"
          @click="isClientFixationTermsModalOpen = true"
        >
          {{ $t('clients.fixation.form.developer.conditions.custom') }}
        </span>
        <span
          v-else
          class="text-sm font-semibold shrink-0"
        >
          {{ $t('clients.fixation.form.developer.conditions.general') }}
        </span>
      </div>
    </div>
  </client-form-field-template>
  <client-fixation-terms-modal
    v-if="developer"
    v-model:is-visible="isClientFixationTermsModalOpen"
    :developer="developer"
  />
</template>

<script setup lang="ts">
import ClientFormFieldTemplate from '~/modules/clients/components/forms/ClientFormFieldTemplate.vue'
import LabeledWrapper from '~/components/common/LabeledWrapper/LabeledWrapper.vue'
import SelectAutoSuggest from '~/components/common/AutoSuggest/SelectAutoSuggest.vue'
import { DeveloperService } from '~/modules/developer/services/DeveloperService'
import { Developer } from '~/common/types/developer/Developer'
import CustomBadge from '~/ui/badges/CustomBadge.vue'
import { isEmpty } from '~/common/helpers/checkers'
import ModalOrBottomSheet from '~/components/common/ModalOrBottomSheet.vue'
import ClientFixationTermsModal from '~/modules/clients/components/forms/clients-fixation/ClientFixationTermsModal.vue'

const props = defineProps({
  progressStepPercent: {
    type: Number,
    default: 0,
  },
  progressStepDone: {
    type: Boolean,
    default: false,
  },
  initialDeveloper: {
    type: Object as PropType<Developer>,
    default: null,
  },
})

const developerService = new DeveloperService()
const { t } = useI18n()

const { value: developer, errorMessage: errorDeveloper } = useField<Developer | null>(
  'developer',
  { },
  { initialValue: props.initialDeveloper, label: t('developer.entityName') },
)

const searchDevelopersByName = (name: string, pageUrl?: string | null) => developerService.search({ name }, pageUrl)

const deselectDeveloper = () => {
  developer.value = null
}

const isClientFixationTermsModalOpen = ref(false)
</script>

<template>
  <market-entities-filter-template
    v-model="isDrawerOpen"
    :only-drawer="onlyDrawer"
  >
    <template #market-buildings>
      <market-buildings-filter
        v-model="filter"
      />
    </template>
    <template #filters>
      <div>
        <div
          class="flex gap-x-3"
          :class="{'grid grid-cols-2 gap-3 w-full mt-3' : isSm}"
        >
          <flat-rooms-count-filter
            v-model="filter.flatFilter.flatRoomsCount"
          />
          <filter-total-area
            v-model:from="filter.flatFilter.totalArea.from"
            v-model:to="filter.flatFilter.totalArea.to"
            :trigger-text="$t('entityActions.filter.flatFilter.totalArea.label')"
          />
          <filter-price
            v-model:from="filter.flatFilter.price.from"
            v-model:to="filter.flatFilter.price.to"
            v-model:currency="filter.flatFilter.price.currency"
          />
          <filter-deadline-year
            v-model:from="filter.houseFilter.deadLine.from"
            v-model:to="filter.houseFilter.deadLine.to"
            :default-values="defaultFilter.houseFilter.deadLine"
            :trigger-text="$t('entityActions.filter.houseFilter.deadLineYear.label')"
          />
        </div>
        <additional-filters
          v-if="isSm"
          v-model:filter="filter"
        />
      </div>
    </template>
    <template #selected-entities-filters>
      <selected-entities-filters
        :filter="filter"
        class="grow"
        @deselect="resetFilterValue"
      />
    </template>
    <template #saved-filters>
      <slot name="saved-filters">
        <saved-filters button-class="whitespace-nowrap" />
      </slot>
    </template>
    <template #additional-filters>
      <additional-filters-template
        button-class="whitespace-nowrap"
      >
        <template #filters>
          <additional-filters v-model:filter="filter" />
        </template>
        <template #footer="{close}">
          <div class="flex justify-between items-center">
            <reset-button
              variant="full"
              @reset-filter="store.resetFilter(addressStore.country, addressStore.region, addressStore.city)"
            />
            <div>
              <search-button
                :is-fetching="isFetching"
                :disabled="isFetching"
                @click="emits('search');close()"
              >
                {{ $t(`entityActions.show.${displayingEntity}`, displayEntityCount) }}
              </search-button>
            </div>
          </div>
        </template>
      </additional-filters-template>
    </template>
    <template #reset-button>
      <reset-button
        variant="full"
        @reset-filter="store.resetFilter(addressStore.country, addressStore.region, addressStore.city)"
      />
    </template>
    <template #search-button="{close}">
      <search-button
        :is-fetching="isFetching"
        :disabled="isFetching"
        class="max-w-fit"
        @click="emits('search'); close && close()"
      >
        {{ $t(`entityActions.show.${displayingEntity}`, displayEntityCount) }}
      </search-button>
    </template>
    <template #reset-filter-button>
      <reset-button
        @reset-filter="store.resetFilter(addressStore.country, addressStore.region, addressStore.city)"
      />
    </template>
  </market-entities-filter-template>
</template>

<script setup lang="ts">

import FilterTotalArea from '~/modules/market-entities-actions/components/filter/filters/FilterTotalArea.vue'
import FilterDeadlineYear from '~/modules/market-entities-actions/components/filter/filters/FilterDeadlineYear.vue'
import FlatRoomsCountFilter from '~/modules/market-entities-actions/components/filter/filters/FlatRoomsCountFilter.vue'
import FilterPrice from '~/modules/market-entities-actions/components/filter/filters/FilterPrice.vue'
import SavedFilters from '~/modules/market-entities-actions/components/filter/SavedFilters.vue'
import SearchButton from '~/modules/market-entities-actions/components/filter/SearchButton.vue'
import SelectedEntitiesFilters from '~/modules/market-entities-actions/components/filter/SelectedEntitiesFilters.vue'
import MarketEntitiesFilterTemplate
  from '~/modules/market-entities-actions/components/filter/MarketEntitiesFilterTemplate.vue'
import { useMarketEntitiesActionsStore } from '~/modules/market-entities-actions/store'
import { FilterService } from '~/modules/market-entities-actions/services/FilterService'
import type { Country } from '~/modules/address/AddressModule'
import { useAddressStore } from '~/modules/address/AddressModule'
import { defaultFilter as defaultFilterFactory } from '~/modules/market-entities-actions/constants/filter/Filter.default'
import { useAppStateStore } from '~/store/app'
import AdditionalFiltersTemplate
  from '~/modules/market-entities-actions/components/filter/AdditionalFiltersTemplate.vue'
import AdditionalFilters from '~/modules/market-entities-actions/components/AdditionalFilters.vue'
import MarketBuildingsFilter from '~/modules/market-entities-actions/components/filter/MarketBuildingsFilter.vue'
import ResetButton from '~/modules/market-entities-actions/components/filter/ResetButton.vue'
import { DISPLAYING_ENTITY } from '~/modules/market-entities-actions/constants/displaying/Displaying.entity'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'

const props = defineProps({
  onlyDrawer: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: null,
  },
})

const defaultFilter = defaultFilterFactory()

const emits = defineEmits<{(e: 'search'): void, (e: 'update:modelValue', value: boolean): void}>()

const store = useMarketEntitiesActionsStore()

const addressStore = useAddressStore()

const filterService = new FilterService()

const storage: QdStorage = useNuxtApp().$appStorage

const displayingEntity = computed(() => store.displayingEntity)
const buildingsCount = computed(() => store.entitiesCount.buildingsCount)
const flatsCount = computed(() => store.entitiesCount.flatsCount)
const layoutsCount = computed(() => store.entitiesCount.layoutsCount)

const totalEntities = computed(() => {
  switch (displayingEntity.value) {
    case DISPLAYING_ENTITY.buildings: {
      return buildingsCount.value
    }
    case DISPLAYING_ENTITY.flats: {
      return flatsCount.value
    }
    case DISPLAYING_ENTITY.layouts: {
      return layoutsCount.value
    }
    default: {
      return buildingsCount.value
    }
  }
})

const defaultTotal = computed(() => store.pagination.total)

const displayEntityCount = computed(() => totalEntities.value ?? defaultTotal.value)

const isDrawerOpen = computed({
  get: () => props.modelValue,
  set: (value: boolean) => {
    emits('update:modelValue', value)
  },
})

const filter = computed(() => store.filter)

const isFetching = computed(() => store.isFetchingEntitiesCount)

const resetFilterValue = (path: string, value?: unknown) => {
  store.resetFilterValue(path, value)
}

const isSm = computed(() => !useAppStateStore().breakpoints.isMd)

watch(() => filter.value, () => {
  filterService.getCount().then(count => {
    store.setEntitiesCount(count)
  })
  storage.setItem('marketEntitiesFilter', filter.value)
}, { deep: true })

watch(() => addressStore.country, country => {
  filter.value.buildingFilter.country = country as Country
})
watch(() => ({
  region: addressStore.region,
  city: addressStore.city,
}), ({ region, city }) => {
  if (!city && region) {
    filter.value.buildingFilter.internationalAddress = region?.region
  } else {
    filter.value.buildingFilter.internationalAddress = city
  }
})
</script>

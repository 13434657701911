<template>
  <custom-button
    class="whitespace-nowrap font-semibold flex items-center !rounded-[19px]"
    color="info"
    @click="isModalOpen = true"
  >
    <nuxt-icon
      name="common/map"
    />
    <span
      class="ml-3 hidden sm:inline-block"
    >
      {{ $t('entityActions.showMap') }}
    </span>
  </custom-button>
  <market-entities-map
    v-if="isModalOpen"
    v-model="isModalOpen"
    @search="emits('search')"
  />
</template>

<script setup lang="ts">
import CustomButton from '~/ui/buttons/CustomButton.vue'
import MarketEntitiesMap from '~/modules/market-entities-actions/components/map/MarketEntitiesMap.vue'

const emits = defineEmits<{(e: 'search'): void}>()

const isModalOpen = ref(false)
</script>

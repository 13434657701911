<template>
  <custom-button-icon
    v-if="!isMd && suggestion.clients?.length > 1"
    :icon="iconName"
    color="secondary"
    class="bg-white"
    :filled="iconFilled"
    round
    v-bind="{...$attrs}"
    @click="isDrawerOpen = true"
  />
  <component
    :is="messengerComponent"
    v-if="suggestion.clients?.length === 1"
    :phone-number="suggestion.clients[0].phone"
    :text-to-send="textToSend"
    span-class="hidden"
    color="secondary"
    button-class="bg-white h-[38px] w-[38px] flex items-center justify-center !text-[1.29rem]"
    v-bind="{...$attrs}"
  />
  <component
    :is="wrapperComponent"
    v-if="suggestion.clients?.length > 1"
    v-model="isDrawerOpen"
    parent-id="suggestionModal"
  >
    <template
      v-if="isMd"
      #trigger="{toggle}"
    >
      <custom-button-icon
        color="secondary"
        class="bg-white"
        round
        :icon="iconName"
        v-bind="{...$attrs}"
        :filled="iconFilled"
        @click="toggle"
      />
    </template>
    <div
      class="flex flex-col grow gap-y-4 w-full"
    >
      <custom-button
        v-for="(client, id) in suggestion.clients"
        :key="id"
        class="text-primary font-semibold"
        is-link
        target="_blank"
        :href="`${link}${client.phone}`"
      >
        {{ client.fullName }}
      </custom-button>
    </div>
  </component>
</template>

<script setup lang="ts">
import { ManagingSuggestion } from '~/modules/suggestions/SuggestionsModule'
import { useAppStateStore } from '~/store/app'
import CustomButton from '~/ui/buttons/CustomButton.vue'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import CustomDrawer from '~/ui/drawers/CustomDrawer.vue'
import CustomDropdown from '~/ui/dropdowns/CustomDropdown.vue'

defineProps({
  suggestion: {
    type: Object as PropType<ManagingSuggestion>,
    required: true,
  },
  messengerComponent: {
    type: Object as PropType<Component>,
    required: true,
  },
  iconName: {
    type: String as PropType<string>,
    required: true,
  },
  link: {
    type: String as PropType<string>,
    required: true,
  },
  iconFilled: {
    type: Boolean,
    default: false,
  },
  textToSend: {
    type: String,
    default: '',
  },
})

const isDrawerOpen = ref(false)
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const wrapperComponent = computed(() => (isMd.value ? markRaw(CustomDropdown) : markRaw(CustomDrawer)))
</script>

<template>
  <localized-page-title>
    <page-template class="p-4 container">
      <template #market-entities-filter>
        <market-entities-filter
          v-model="isFilterDrawerOpen"
          :only-drawer="!isMd"
          @search="search"
        />
      </template>
      <template #search-count>
        <search-count
          v-if="!actionsStore.isFetchingEntitiesCount"
          :count="actionsStore.entitiesCount"
          class="grow w-full sm:w-auto"
        />
        <block-skeleton
          v-else
          class="h-[2em] w-1/3"
        />
      </template>
      <template #select-entity>
        <select-entity v-if="!isClientViewVisible" />
        <div
          v-else
        />
      </template>
      <template #show-map-button>
        <show-map-button @search="search" />
      </template>
      <template #market-entities-sort>
        <market-entities-sort @on-change-sort-option="search()" />
      </template>
      <template #entities>
        <building-grid-skeleton
          v-if="isFetching"
          class="mt-7"
        />
        <template v-else-if="actionsStore.displayingEntity === DISPLAYING_ENTITY.buildings">
          <div
            v-if="buildings.length"
            class="mt-7 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6"
          >
            <building-grid-item
              v-for="(building, index) in buildings"
              :key="`${building.id}-${index}`"
              :building="building"
            >
              <template
                v-if="isClientViewVisible"
                #footer
              >
                <slot :building="building" name="building-actions" />
              </template>
            </building-grid-item>
          </div>
        </template>
        <template v-else-if="actionsStore.displayingEntity === DISPLAYING_ENTITY.flats">
          <flats-list
            v-if="flats.length"
            wrapper-class="lg:grid-cols-2 xl:grid-cols-3 xl:grid-cols-4"
            :flats="flats"
          >
            <template #building="{building}">
              <flat-building
                class="flex"
                :display-type="DISPLAY_TYPE.basic"
                :building="building"
              />
            </template>
          </flats-list>
        </template>
        <template v-else-if="actionsStore.displayingEntity === DISPLAYING_ENTITY.layouts">
          <building-grid-layouts
            :layouts="layouts"
          />
        </template>
      </template>
      <template #market-entities-pagination>
        <custom-pagination
          :pagination="actionsStore.pagination"
          class="justify-center mt-6"
          :is-fetching="isFetching"
          @on-page-change="onPageChange"
        />
      </template>
    </page-template>
  </localized-page-title>

  <mobile-filter-btn
    v-if="!isMd"
    @click="isFilterDrawerOpen = true"
  />
  <suggestions-management-modal v-model="isModalOpen" />
</template>

<script setup lang="ts">

import SearchCount from '~/modules/market-entities-actions/components/filter/SearchCount.vue'
import SelectEntity from '~/modules/market-entities-actions/components/filter/SelectEntity.vue'
import ShowMapButton from '~/modules/market-entities-actions/components/filter/ShowMapButton.vue'
import PageTemplate from '~/pages/market/PageTemplate.vue'
import BuildingGridSkeleton from '~/components/common/skeletons/BuildingGridSkeleton.vue'
import {
  FilterService,
  MarketEntitiesFilter,
  MarketEntitiesSort,
  useMarketEntitiesActionsStore,
} from '~/modules/market-entities-actions/MarketEntitiesActionsModule'
import CustomPagination from '~/ui/pagination/CustomPagination.vue'
import { type Building, BuildingGridItem, useBuildingStore } from '~/modules/building/BuildingModule'
import { PaginationService } from '~/modules/market-entities-actions/services/PaginationService'
import { scrollToTop } from '~/common/helpers/scrolls'
import SuggestionsManagementModal
  from '~/modules/building/components/actions/suggestions-management/SuggestionsManagementModal.vue'
import BlockSkeleton from '~/ui/skeletons/BlockSkeleton.vue'
import { Flat } from '~/common/types/flat/Flat'
import { MarketEntitiesSearchResponseData } from '~/modules/market-entities-actions/types/search/Search.responseData'
import { DISPLAYING_ENTITY } from '~/modules/market-entities-actions/constants/displaying/Displaying.entity'
import FlatsList from '~/components/flats/FlatsList.vue'
import FlatBuilding from '~/components/flats/flat-list-card/FlatBuilding.vue'
import { Layout } from '~/common/types/layout/Layout'
import BuildingGridLayouts from '~/modules/building/components/grid-item/BuildingGridLayouts.vue'
import DISPLAY_TYPE from '~/common/constants/flat/Flat.displayType'
import { useAppStateStore } from '~/store/app'
import MobileFilterBtn from '~/modules/market-entities-actions/components/filter/MobileFilterBtn.vue'
import { COLOR } from '~/ui/constants/color'
import LocalizedPageTitle from '~/components/common/LocalizedPageTitle.vue'
import { QdStorage } from '~/common/storage/interfaces/QdStorage'
import DISPLAYING_TYPE from '~/modules/building/constants/Building.displayingType'

defineProps({
  isClientViewVisible: {
    type: Boolean,
    default: false,
  },
})

provide('displayingType', computed(() => DISPLAYING_TYPE.basic))

const isFetching = ref(false)

const filterService = new FilterService()
const paginationService = new PaginationService()

const store = useBuildingStore()
const actionsStore = useMarketEntitiesActionsStore()
const appStore = useAppStateStore()
const storage: QdStorage = useNuxtApp().$appStorage
const { t } = useI18n()

const buildings: Ref<Array<Building>> = ref([])
const flats: Ref<Array<Flat>> = ref([])
const layouts: Ref<Array<Layout>> = ref([])
const lastErrorTime = computed(() => store.lastErrorBuildingsTime)
const GAP_BETWEEN_ERRORS = 300000
const isModalOpen = computed({
  get() {
    return store.isDisplaySuggestionsModal
  },
  set(value) {
    store.isDisplaySuggestionsModal = value
    if (!value) {
      store.clearBuildingsList()
      store.clearFlatsList()
    }
  },
})
const isFilterDrawerOpen = ref(false)
const isMd = computed(() => appStore.breakpoints.isMd)

const setSearchData = (data: MarketEntitiesSearchResponseData): void => {
  switch (data.entityName) {
    case DISPLAYING_ENTITY.flats:
    {
      flats.value = data.data
      break
    }
    case DISPLAYING_ENTITY.layouts:
    {
      layouts.value = data.data
      break
    }
    case DISPLAYING_ENTITY.buildings:
    {
      buildings.value = data.data
      break
    }
    default: {
      throw new Error('this entityName is not defined')
    }
  }
  paginationService.setPagination({
    currentPage: data.currentPage,
    lastPage: data.lastPage,
    perPage: data.perPage,
    total: data.total,
    nextPageUrl: data.nextPageUrl,
  })
}
const onPageChange = (page: number): void => {
  isFetching.value = true
  scrollToTop()
  paginationService.changeCurrentPage(page)
    .then(data => {
      setSearchData(data)
    })
    .finally(() => {
      isFetching.value = false
    })
}
const search = (page = 1) => {
  isFetching.value = true
  filterService.search(actionsStore.displayingEntity, page)
    .then(data => {
      setSearchData(data)
      if (lastErrorTime.value) {
        storage.setItem('lastErrorBuildingsTime', null)
      }
    })
    .catch(() => {
      const currentTime = new Date().getTime()

      if (!lastErrorTime.value || (currentTime - lastErrorTime.value) > GAP_BETWEEN_ERRORS) {
        storage.setItem('lastErrorBuildingsTime', currentTime)
        toast(t('entityActions.search.errors.first'), { variant: COLOR.danger })
      } else {
        toast(t('entityActions.search.errors.second'), { variant: COLOR.danger })
      }
    })
    .finally(() => {
      isFetching.value = false
    })
}

onMounted(() => {
  search()
  filterService.getCount().then(count => {
    actionsStore.entitiesCount = count
  })

  if (!isMd.value) {
    appStore.setScrollToTopBottom('bottom-[18%]')
  }
})

onUnmounted(() => {
  appStore.setScrollToTopBottom('bottom-[12%]')
})

watch(isMd, value => {
  if (!value) {
    appStore.setScrollToTopBottom('bottom-[18%]')
  } else {
    appStore.setScrollToTopBottom('bottom-[12%]')
  }
})
watchEffect(() => {
  if (actionsStore.toSearch) {
    search()
    actionsStore.toSearch = false
  }
})
watch(() => actionsStore.displayingEntity, () => {
  search()
})
</script>

<template>
  <div
    class="relative rounded-[6px] overflow-hidden flex"
    :style="{
      'background-image': `url(${imageSource})`,
      'background-size': 'cover'
    }"
  >
    <div
      class="mt-auto mb-1 w-full flex justify-center flex-wrap"
    >
      <div
        class="w-[80%] p-1 rounded-md text-center font-semibold text-ellipsis overflow-hidden whitespace-nowrap"
        :class="isSelected ? 'bg-primary-light text-primary' : 'bg-black/30 backdrop-blur-lg text-white'"
      >
        {{
          getEntityName({
            name: building.name,
            internationalName: building.internationalName,
            entityCountry: building.address?.countryIsoCode
          })
        }}
      </div>
      <slot name="under-title" />
    </div>
    <slot name="actions" />
  </div>
</template>

<script setup lang="ts">
import buildingDefaultImage from '~/assets/images/building/building-default-image.png'
import { SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import { getEntityName } from '~/common/helpers/getEntityName'

const props = defineProps({
  building: {
    type: Object as PropType<SuggestionBuilding>,
    required: true,
  },
  titleClass: {
    type: [String, Object, Array],
    default: '',
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
})

const imageSource = computed(() => {
  const building = props.building as SuggestionBuilding
  if (!building.images.length) {
    return buildingDefaultImage
  }
  const image = building.images.find(img => img.default) ?? building.images[0]
  return image.link
})
</script>

<template>
  <custom-button
    class="font-semibold whitespace-nowrap px-4 h-[38px] flex items-center"
    size="none"
    pill
  >
    <template #prefix>
      <nuxt-icon
        name="common/circle-plus-regular"
      />
    </template>
    {{ $t(isMd ? 'suggestionsActions.create.title.full' : 'suggestionsActions.create.title.short') }}
  </custom-button>
</template>

<script setup lang="ts">
import { useAppStateStore } from '~/store/app';
import CustomButton from '~/ui/buttons/CustomButton.vue'

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
</script>

<template>
  <custom-table>
    <thead
      v-if="isMd"
      class="sticky z-[5] top-0 font-semibold"
    >
      <tr>
        <td v-if="$slots.leftActions" />
        <td>{{ $t('clients.tableView.fullName') }}</td>
        <td>{{ $t('clients.tableView.mobilePhone') }}</td>
        <td>{{ $t('clients.tableView.email') }}</td>
        <td>{{ $t('clients.fixation.form.user.title') }}</td>
        <td
          v-if="$slots.rightActions"
          class="text-end"
        >{{ $t('clients.tableView.action') }}</td>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(client, id) in clients"
        :key="id"
      >
        <template v-if="isMd">
          <td v-if="$slots.leftActions">
            <slot
              name="leftActions"
              :client="client"
            />
          </td>
          <td>
            <h3 class="font-semibold text-neutral-900 text-base">
              {{ client.fullName }}
            </h3>
          </td>
          <td>
            <span>{{ client.phone }}</span>
          </td>
          <td>
            <div
              v-if="client.email"
            >
              <span>{{ client.email }}</span>
            </div>
          </td>
          <td>
            <span>{{ getFullName(client.assigned) }}</span>
          </td>
          <td
            v-if="$slots.rightActions"
          >
            <slot
              name="rightActions"
              :client="client"
            />
          </td>
        </template>
        <template v-else>
          <td>
            <div class="flex flex-col gap-y-3">
              <span>{{ client.fullName }}</span>
              <div class="flex text-secondary gap-x-2 justify-between">
                <span>{{ client.phone }}{{ client.email ? `, ${client.email}` : '' }}</span>
                <span>{{ getFullName(client.assigned) }}</span>
              </div>
              <slot
                name="rightActions"
                :client="client"
              />
            </div>
          </td>
        </template>
      </tr>
    </tbody>
  </custom-table>
</template>

<script setup lang="ts">
import CustomTable from '~/ui/tables/CustomTable.vue'
import { Client } from '~/modules/clients/types/Client'
import { User } from '~/modules/user/types/user'
import { useAppStateStore } from '~/store/app'

defineProps({
  clients: {
    type: Array as PropType<Client[]>,
    required: true,
  },
})
const getFullName = (assigned: User) => {
  let fullName = assigned.firstName
  if (assigned.lastName) {
    fullName += ` ${assigned.lastName}`
  }
  return fullName
}
const isMd = computed(() => useAppStateStore().breakpoints.isMd)
</script>

export const NAVIGATION_LINKS = {
  media: 'media',
  flats: 'flats',
  about: 'about',
  decoration: 'decoration',
  constructionProgress: 'constructionProgress',
  files: 'files',
  mortgage: 'mortgage',
  panorama: 'panorama',
} as const

<template>
  <div class="flex flex-col">
    <h2
      v-if="title"
      class="font-semibold text-neutral-900 text-[1.72rem] mb-6"
      :class="titleClass"
    >
      {{ title }}
    </h2>
    <div class="flex sticky top-0 gap-x-2">
      <custom-input
        v-model="searchQuery"
        input-class="bg-white h-[38px] border-neutral-35"
        :placeholder="$t('suggestionsActions.client.search.placeholder')"
      >
        <template #suffixInner>
          <nuxt-icon
            class="text-neutral-500 text-[20px]"
            name="common/search"
          />
        </template>
      </custom-input>
      <slot name="input-append" />
    </div>
    <div class="h-full overflow-y-auto scrollbar-thin flex flex-col mt-3">
      <div
        v-if="isFetching"
        class="min-h-[350px] flex justify-center items-center"
      >
        <custom-cube-spinner />
      </div>
      <component
        :is="displayingComponent"
        v-else
        :clients="clients"
      >
        <template
          v-if="$slots.leftActions"
          #leftActions="{client}"
        >
          <slot
            name="leftActions"
            :client="client"
          />
        </template>
        <template
          v-if="$slots.rightActions"
          #rightActions="{client}"
        >
          <slot
            name="rightActions"
            :client="client"
          />
        </template>
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomInput from '~/ui/inputs/CustomInput.vue'
import type { Client } from '~/modules/clients/types/Client'
import ClientsListView from '~/modules/clients/components/tables/clients/ClientsListView.vue'
import ClientsTableView from '~/modules/clients/components/tables/clients/ClientsTableView.vue'
import CustomCubeSpinner from '~/ui/spinners/CustomCubeSpinner.vue'
import Time from '~/common/helpers/time/Time'

const props = defineProps({
  clients: {
    type: Array as PropType<Client[]>,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  titleClass: {
    type: [String, Object, Array],
    default: '',
  },
  view: {
    type: String as PropType<'list' | 'table'>,
    default: 'list',
  },
  isFetching: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{(e: 'getClients', value: string): void }>()

const searchQuery = ref('')

const displayingComponent = computed(() => {
  switch (props.view) {
    case 'list':
      return markRaw(ClientsListView)
    case 'table':
      return markRaw(ClientsTableView)
    default:
      return markRaw(ClientsListView)
  }
})

const emitDebounced = Time.debounce((value: string) => {
  emits('getClients', value)
})

watch(searchQuery, value => {
  emitDebounced(value)
})
</script>

<template>
  <custom-card
    v-if="building === null"
    class="h-full max-md:rounded-b-none md:!rounded-[12px]"
  >
    <div class="h-full flex flex-col gap-y-4">
      <h3 class="text-neutral-900 max-md:text-lg font-semibold">
        {{ $t('suggestions.sidePanel.displayAllFlats.title', {value: buildingsCount}) }}
      </h3>
      <p class="text-neutral-500 font-semibold">
        {{ $t('suggestions.sidePanel.displayAllFlats.tip') }}
      </p>
      <div
        v-if="isMd"
        class="grow"
      >
        <building-list-map
          wrapper-class="rounded-lg overflow-hidden"
          :buildings="allBuildings"
        />
      </div>
    </div>
  </custom-card>
  <building-grid-item
    v-else
    class="h-full max-md:rounded-b-none overflow-hidden"
    :building="building"
    link-class=""
    :link-to="buildingLinkTo"
    :check-authorization-for-link-opening="false"
  >
    <template #housesText="{housesLength}">
      {{ $t('building.houses.label.short', housesLength) }}
    </template>
    <template
      #footer
    >
      <nuxt-link
        :to="{ name: ROUTES_NAMES.presentationBuildingPageFromSuggestion, params: { ids: [btoa(String(building.id)), suggestionData.suggestionId] }}"
        :target="appStateStore.isWeb ? '_blank': ''"
        class="w-full"
      >
        <custom-button
          color="primary"
          pill
          class="block w-full"
        >
          {{ $t('common.details') }}
        </custom-button>
      </nuxt-link>
      <div
        v-if="isMd && withMap"
        class="grow mt-4 relative"
      >
        <building-list-map
          wrapper-class="rounded-lg overflow-hidden"
          :buildings="buildingForMap"
          is-card-recreate-on-change
        />
        <custom-button-icon
          round
          class="absolute right-3 top-3"
          color="transparent"
          icon="actions/fullscreen"
          size="none"
          @click="isMapModalOpen=true"
        />
        <building-list-map-modal
          v-if="isMapModalOpen"
          v-model="isMapModalOpen"
          :buildings="buildingForMap"
          with-close-on-history-back
        />
      </div>
      <span v-else />
    </template>
  </building-grid-item>
</template>

<script setup lang="ts">
import CustomCard from '~/ui/cards/CustomCard.vue'
import { SuggestionBuilding } from '~/modules/suggestions/types/Suggestion.building'
import { useSuggestionsStore } from '~/modules/suggestions/store'
import { useAppStateStore } from '~/store/app'
import BuildingListMap from '~/modules/building/components/map/BuildingListMap.vue'
import { Building, BuildingGridItem } from '~/modules/building/BuildingModule'
import { ROUTES_NAMES } from '~/router/constants/Router.routesNames'
import CustomButtonIcon from '~/ui/buttons/CustomButtonIcon.vue'
import BuildingListMapModal from '~/modules/building/components/map/BuildingListMapModal.vue'
import { btoa } from '~/common/helpers/window'
import CustomButton from '~/ui/buttons/CustomButton.vue'

const props = defineProps({
  building: {
    type: Object as PropType<Building | SuggestionBuilding | null>,
    default: null,
  },
  allBuildings: {
    type: Array as PropType<Array<SuggestionBuilding>>,
    default: () => [] as Array<SuggestionBuilding>,
  },
  withMap: {
    type: Boolean,
    default: true,
  },
})

const appStateStore = useAppStateStore()

const isMapModalOpen = ref(false)
const suggestionData = useSuggestionsStore()
const buildingsCount = computed(() => suggestionData.suggestion.buildings.length)
const buildingForMap = computed(() => (props.building ? [props.building] : []))

const isMd = computed(() => useAppStateStore().breakpoints.isMd)
const buildingLinkTo = computed(() => (props.building ? { name: ROUTES_NAMES.presentationBuildingPageFromSuggestion, params: { ids: [btoa(String(props.building.id)), suggestionData.suggestionId] } } : {}))
</script>

<template>
  <div class="flex items-center gap-[12px]">
    <custom-avatar
      :alt="manager.firstName"
      :src="managerImageLink"
      :size="avatarSize"
      rounded
    />
    <div>
      <div class="flex text-neutral-900 font-semibold leading-5">
        {{ manager.firstName }} <template v-if="manager.lastName">
          {{ manager.lastName }}
        </template>
      </div>
      <div class="flex items-center">
        <span class="text-neutral-500 font-medium text-lg leading-6"> {{ manager.mobilePhone }} </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomAvatar from '~/ui/avatars/CustomAvatar.vue'
import { Manager } from '~/common/types/manager/Manager'
import { Size } from '~/ui/types/types'
import { SIZE } from '~/ui/constants/size'

const props = defineProps({
  manager: {
    type: Object as PropType<Manager>,
    required: true,
  },
  avatarSize: {
    type: String as PropType<Size>,
    default: SIZE.sm,
  },
})

const runtimeConfig = useRuntimeConfig()

const { quickDealAppUrl } = runtimeConfig.public

const managerImageLink = computed(() => (props.manager.photo ? `${quickDealAppUrl}/img/user/logo/${props.manager.id}/${props.manager.photo}` : ''))

</script>
